import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  CardGroup,
  Spinner,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import requestGeneral from "../../../../utils/RequestGeneral";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import FormikInput from "../../../../utils/FormikInput";
import langUtils from "../../../../utils/SettingLanguage/index";
import { translate } from "react-switch-lang";
import ReCAPTCHA from "react-google-recaptcha";
import dotenv from "dotenv";
import OtpInput from "react-otp-input";
import Axios from "axios";
import { isMobile } from "react-device-detect";
toast.configure();
dotenv.config();
const CLIENT_ID = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export default translate(function Register(props) {
  const { t } = props;
  const recaptchaRef = useRef();
  const [success, setSuccess] = useState(false);
  const [verifSuccess, setVerifSuccess] = useState(false);
  const [isWrongOTP, setIsWrongOTP] = useState(false);
  const [sessionId, setSessionId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [attempt, setAttempt] = useState(null);
  const [countWrongOtp, setCountWrongOtp] = useState(null);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [email, setEmail] = useState(null);
  const [captchaState, setCaptchaState] = useState(recaptchaRef);

  useEffect(() => {
    // exit early when we reach 0
    if (success) {
      if (!timeLeft) return;

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }
  }, [success, timeLeft]);

  const formValues = {
    userFullName: "",
    userNickName: "",
    userEmail: "",
    userPhone: "",
    userPassword: "",
    userRePassword: "",
    captchaValue: null,
  };

  const formValidate = (values) => {
    let errors = {};
    let pattern = new RegExp(/^[0-9]+$/);
    let patternText = new RegExp(/^[a-zA-Z'\-.\s]+$/);
    let passCheck = (pattern) => values.userPassword.match(pattern);

    if (!values.userFullName) {
      errors.userFullName = t("General.cannotEmpty");
    }
    if (!values.userNickName) {
      errors.userNickName = t("General.cannotEmpty");
    }
    if (!patternText.test(values.userFullName)) {
      errors.userFullName = t("Register.fullNameLetter");
    }
    if (!patternText.test(values.userNickName)) {
      errors.userNickName = t("Register.nickNameLetter");
    }
    if (!values.userEmail) {
      errors.userEmail = t("General.cannotEmpty");
    }
    if (!pattern.test(values.userPhone)) {
      errors.userPhone = t("Register.phoneNotValid");
    }
    if (values.userPhone.length < 10) {
      errors.userPhone = t("Register.phoneNotValid");
    }
    if (values.userPhone.length > 15) {
      errors.userPhone = t("Register.phoneNotValid");
    }
    if (values.userPhone === null) {
      errors.userPhone = t("General.cannotEmpty");
    }
    if (
      !passCheck(/[A-Z]/) ||
      !passCheck(/[a-z]/) ||
      !passCheck(/[0-9]/) ||
      !passCheck(/^.{8,}$/)
    ) {
      errors.userPassword = t("Register.passwordMinimum");
    }
    if (values.userPassword !== values.userRePassword) {
      errors.userRePassword = t("Register.notMatchPassword");
    }
    return errors;
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let err = formValidate(values);
    let errCount = 0;

    for (let i in err) {
      toast.error(err[i]);
      ++errCount;
    }

    if (errCount) return setSubmitting(false);

    if (!values.captchaValue) {
      toast.error(t("Register.checkCaptcha"));
      setSubmitting(false);
      return;
    }

    const dataObject = {
      fullName: values.userFullName.trim(),
      nickName: values.userNickName.trim(),
      phone: values.userPhone.trim(),
      email: values.userEmail.trim(),
      password: values.userPassword,
      rePassword: values.userRePassword,
      captchaValue: values.captchaValue,
      lang: langUtils.getLanguage(),
    };
    await requestGeneral
      .post(`v2/users/request`, dataObject)
      .then((res) => {
        setCaptchaState(null);
        setEmail(values.userEmail);
        setSuccess(res?.data?.success);
        setAttempt(res?.data?.attempt);
        setSessionId(res?.data?.session_id);
        toast.success(t("Register.successRegis"), {
          autoClose: 3000,
        });
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = err?.response?.data?.errors;

          if (errorMessage) {
            // eslint-disable-next-line array-callback-return
            Object.keys(errorMessage).map((key, index) => {
              if (key === "email") {
                return toast.error(t("Register.emailRegistered"), {
                  autoClose: 3000,
                });
              } else if (key === "phone") {
                return toast.error(t("Register.phoneRegistered"), {
                  autoClose: 3000,
                });
              }
            });
          } else {
            toast.error(err?.response?.data?.message, {
              autoClose: 3000,
            });
          }
          // toast.error(
          //   err.response.data.errors.email ||
          //   err.response.data.errors.phone ||
          //   err.response.data.errors.userFullName ||
          //   err.response.data.errors.userNickName ||
          //   err.response.data.errors.password ||
          //   err.response.data.message ||
          //   "SERVER ERROR",
          //   { autoClose: 3000 }
          // );
        } else {
          toast.error("SERVER ERROR", { autoClose: 3000 });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function handleChangeOTP(otpValue) {
    setOtp(otpValue);
  }

  async function resend() {
    const dataObject = {
      email: email,
      lang: langUtils.getLanguage(),
    };
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${sessionId}` },
    };
    await Axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/v2/users/otp`,
      dataObject,
      config
    )
      .then((res) => {
        setTimeLeft(60);
        setAttempt(res?.data?.attempt);
        setLoading(false);
        toast.success(t("Register.successOtp"), {
          autoClose: 3000,
        });
        setCountWrongOtp(null);
        setOtp("");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("Register.failedOtp"), { autoClose: 3000 });
      });
  }

  async function verification() {
    const dataObject = {
      email: email,
      otp: otp,
      lang: langUtils.getLanguage(),
    };
    setLoadingButton(true);
    const config = {
      headers: { Authorization: `Bearer ${sessionId}` },
    };
    await Axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/v2/users/create`,
      dataObject,
      config
    )
      .then((res) => {
        setLoadingButton(false);
        // toast.success("Success Verif", { autoClose: 3000 });
        // setRedirect(true);
        setVerifSuccess(true);
        setIsWrongOTP(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingButton(false);
        toast.error(t("Register.wrongOtp"), { autoClose: 3000 });
        setCountWrongOtp(() => countWrongOtp + 1);
        setIsWrongOTP(true);
      });
  }
  const currLang = window.localStorage.language;
  const toggle = () => setVerifSuccess(!verifSuccess);

  return (
    <div
      className="app flex-row align-items-center"
      style={{ height: "auto", padding: "10px", overflowX: "hidden" }}
    >
      <Container>
        <Row className="navtext justify-content-center">
          <Col xs={12} sm={12} md={4}>
            <CardGroup style={{ height: "auto" }}>
              <Card className="card-login-form">
                <CardBody style={{ padding: 0 }}>
                  {success ? (
                    <div className={isMobile ? "container" : null}>
                      <Row>
                        <Col sm="12" className="mb-5">
                          <div className="logo text-center">
                            <img
                              src={require("../../../../assets/img/logo.png")}
                              alt="logo"
                              style={{ width: 100 }}
                            />
                          </div>
                        </Col>
                        <Col sm="12" className="mt-3">
                          <div className="logo text-center">
                            <img
                              src={require("../../../../assets/img/phonemail.png")}
                              alt="logo"
                              style={{ width: 220 }}
                            />
                          </div>
                        </Col>
                        <Col sm="12" className="mt-3 text-center">
                          <h4>{t("Register.inputCode")}</h4>
                          <p className="text-muted">
                            {t("Register.codeSend")} <br />
                            <b>{email}</b> {t("Register.codeSend2")}
                          </p>
                          <br />
                          <OtpInput
                            isInputNum={true}
                            value={otp}
                            onChange={handleChangeOTP}
                            numInputs={6}
                            inputStyle="inputStyle"
                            containerStyle="containerInput"
                            shouldAutoFocus
                            isDisabled={
                              (attempt > 2 &&
                                timeLeft <= 0 &&
                                countWrongOtp > 2) ||
                              (countWrongOtp > 2 && timeLeft <= 0) ||
                              countWrongOtp > 2
                            }
                          />
                          <br />
                          {loadingButton ? null : loading ? (
                            <p>
                              {" "}
                              <Spinner size="sm" /> Loading{" "}
                            </p>
                          ) : attempt <= 2 ? (
                            <>
                              <p className="text-muted mt-3 mb-1">
                                {t("Register.youNotCode")}
                              </p>
                              {timeLeft > 0 && countWrongOtp !== 3 ? (
                                <p className="text-muted mt-1">
                                  <b>
                                    {t("Register.sendAgain") +
                                      " (" +
                                      timeLeft +
                                      " s)"}
                                  </b>
                                </p>
                              ) : (
                                <p className="text-link mt-1" onClick={resend}>
                                  <b>{t("Register.sendAgain")}</b>
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {timeLeft > 0 && countWrongOtp !== 3 ? (
                                <p className="text-muted mt-1">
                                  <b>
                                    {t("Register.sendAgain") +
                                      " (" +
                                      timeLeft +
                                      " s)"}
                                  </b>
                                </p>
                              ) : (
                                <>
                                  <p className="text-muted">
                                    {t("Register.infoAdmin")}
                                  </p>
                                  <Link
                                    to={{
                                      pathname: isMobile
                                        ? `https://wa.me/${process.env.REACT_APP_WA_ADMIN}?text=Halo,+admin.+Aku+kesulitan+untuk+mendapatkan+OTP+pada+saat+registrasi+menggunakan+email+${email}.+Mohon+bantuan+admin`
                                        : `https://web.whatsapp.com/send?text=Halo,+admin.+Aku+kesulitan+untuk+mendapatkan+OTP+pada+saat+registrasi+menggunakan+email+${email}.+Mohon+bantuan+admin&phone=${process.env.REACT_APP_WA_ADMIN}`,
                                    }}
                                    target="_blank"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      style={{
                                        backgroundColor: "#075e54",
                                        borderColor: "#075e54",
                                      }}
                                    >
                                      <b>
                                        <i class="fa fa-whatsapp mr-2"></i>
                                        {t("General.contact")} Admin
                                      </b>
                                    </button>
                                  </Link>
                                  <br />
                                  <br />
                                </>
                              )}
                            </>
                          )}
                          <br />
                          <button
                            className="btn btn-info btn-skillana"
                            style={{ width: "100%" }}
                            onClick={verification}
                            disabled={
                              loading ||
                              loadingButton ||
                              (attempt > 2 &&
                                timeLeft <= 0 &&
                                countWrongOtp > 2) ||
                              (countWrongOtp > 2 && timeLeft <= 0) ||
                              countWrongOtp > 2
                            }
                          >
                            {loadingButton ? <Spinner size="sm" /> : null}
                            <b className="ml-2">{t("Register.Verifikasi")}</b>
                          </button>
                        </Col>
                      </Row>
                      <Modal
                        isOpen={verifSuccess}
                        toggle={toggle}
                        centered
                        backdrop="static"
                        keyboard={false}
                      >
                        <ModalBody>
                          <div className="text-center">
                            <img
                              width="100%"
                              src={
                                currLang === "EN"
                                  ? require("../../../../assets/img/vrifikasi_en.png")
                                  : require("../../../../assets/img/verifikasi.png")
                              }
                              alt="verif success"
                            />
                          </div>
                          <br />
                          <p className="text-center">
                            {t("Register.verifySuccess")}
                          </p>
                          <Link
                            style={{
                              textDecoration: "none",
                              width: "100%",
                            }}
                            className="btn btn-info btn-skillana"
                            to="/login"
                            onClick={() => setVerifSuccess(false)}
                          >
                            {t("Register.loginNow")}
                          </Link>
                        </ModalBody>
                      </Modal>
                      <Modal
                        isOpen={isWrongOTP}
                        toggle={() => setIsWrongOTP(!isWrongOTP)}
                        centered
                        backdrop="static"
                        keyboard={false}
                        size="md"
                      >
                        <ModalBody>
                          <div className="text-center">
                            <h5 style={{ fontWeight: "600" }}>
                              {t("Register.failed")}
                            </h5>{" "}
                            <br />
                            <p>
                              {countWrongOtp <= 2
                                ? t("Register.invalidOTP")
                                : attempt === 3 && countWrongOtp === 3
                                ? t("Register.lastFailedInputOTP")
                                : t("Register.failedInputOTP")}
                            </p>
                            <Button
                              className="btn btn-info btn-skillana w-100"
                              onClick={
                                countWrongOtp <= 2
                                  ? () => {
                                      setIsWrongOTP(!isWrongOTP);
                                      setOtp("");
                                    }
                                  : () => setIsWrongOTP(!isWrongOTP)
                              }
                            >
                              {countWrongOtp <= 2
                                ? t("Register.inputAgain")
                                : t("Register.ok")}{" "}
                              {countWrongOtp <= 2 ? `(${countWrongOtp}/3)` : ""}
                            </Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  ) : (
                    <Formik
                      initialValues={formValues}
                      validate={formValidate}
                      onSubmit={formSubmit}
                      render={({ isSubmitting, values }) => (
                        <Form>
                          <div className="logo text-center">
                            <img
                              src={require("../../../../assets/img/logo.png")}
                              alt="logo"
                              style={{ width: 150 }}
                            />
                          </div>
                          <br />
                          <Fragment>
                            <h5 style={{ marginTop: 10, marginBottom: 20 }}>
                              <b>{t("Register.title")}</b>
                            </h5>
                            <Row>
                              <Col sm="12">
                                <Field
                                  type="text"
                                  label={t("General.fullname")}
                                  name="userFullName"
                                  id="userFullName"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12">
                                <Field
                                  type="text"
                                  label={t("General.nickname")}
                                  name="userNickName"
                                  id="userNickName"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12">
                                <Field
                                  type="email"
                                  label="Email"
                                  name="userEmail"
                                  id="userEmail"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12">
                                <Field
                                  type="tel"
                                  label={t("General.phone")}
                                  name="userPhone"
                                  id="userPhone"
                                  placeholder="08xx"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12" className="password-user">
                                <Field
                                  type="password"
                                  label="Password"
                                  name="userPassword"
                                  id="userPassword"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12">
                                <Field
                                  type="password"
                                  label={t("General.repassword")}
                                  name="userRePassword"
                                  id="userRePassword"
                                  component={FormikInput}
                                />
                              </Col>
                              <Col sm="12">
                                <ReCAPTCHA
                                  ref={captchaState}
                                  sitekey={CLIENT_ID}
                                  style={{
                                    paddingTop: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onChange={(value) => {
                                    values.captchaValue = value;
                                    values.setFieldTouched(
                                      "captchaValue",
                                      true
                                    );
                                    values.setFieldError("captchaValue", "");
                                  }}
                                  onExpired={() => {
                                    values.setFieldValue("captchaValue", "");
                                    values.setFieldError(
                                      "captchaValue",
                                      "Recaptcha is expired, please check again."
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                            <Button
                              type="submit"
                              className="login-submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <span>
                                  <Spinner size="sm" className="mr-2" /> Loading
                                </span>
                              ) : (
                                t("Register.button")
                              )}
                            </Button>
                          </Fragment>
                          <div className="mt-3">
                            <Link to="/login">
                              <i className="mr-2 fa fa-chevron-left"></i>{" "}
                              {t("General.goToBack")} Login
                            </Link>
                          </div>
                        </Form>
                      )}
                    />
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

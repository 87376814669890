import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Progress,
  Alert,
  Button,
  Container,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Color from "../../../../utils/Colors";
import request from "../../../../utils/Request";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { translate, t } from "react-switch-lang";
import { useSelector } from "react-redux";
export default translate(function ResultAssesmentMbti(props) {
  const goToLink = () => {
    window.scrollTo(0, 0);
  };
  const [token, setToken] = useState(null);
  useEffect(() => {
    request
      .get("/token/" + props.result.id)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const [name, setName] = useState('Copy Link')
  const diKlik = () => {
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const prevPath = useSelector((state) => state?.prevPath);

  return (
    <Container>
      <div className="justify-content-center" style={{ paddingTop: "2rem" }}>
        <Row>
          <Col
            xs="6"
            sm="6"
            md="3"
            lg="2"
            xl="2"
            className="order-0 order-sm-0 order-md-0 order-lg-0 order-xl-0"
          >
            {props.back ? (
              <Link to={prevPath ? prevPath : "/assessment"}>
                <Button className="navtext btn btn-lg btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </Button>
              </Link>
            ) : null}
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="8"
            xl="8"
            className="order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1"
          >
            <Alert color="success" className="text-center mb-3">
              <h5 className="content-title mb-2">
                {t("Assessment.completeTest")} <br />{" "}
                {t("Assessment.aptitudeTitle")}.
              </h5>
            </Alert>
          </Col>
          <Col
            xs="6"
            sm="6"
            md="3"
            lg="2"
            xl="2"
            className="mb-3 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2"
          >
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="float-right"
            >
              <DropdownToggle
                caret
                className="navtext btn btn-lg btn-netis-color"
              >
                <i className="fa fa-share-alt mr-2"></i>
                {t("Assessment.share")}
              </DropdownToggle>
              <DropdownMenu
                className="mr-4 mt-3"
                style={{ marginLeft: "-120px" }}
              >
                <DropdownItem style={{ border: "none" }}>
                  <FacebookShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-mbti/" +
                      token
                    }
                    quote={`Halo, aku baru saja menyelesaikan Tes Minat dan Bakat di Skillana ${
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-mbti/" +
                      token
                    } Ayo kamu bisa ikutan juga loh! ${
                      process.env.REACT_APP_DOMAIN
                    }`}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Facebook</b>
                  </FacebookShareButton>{" "}
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <TwitterShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={process.env.REACT_APP_DOMAIN}
                    title={`Halo, aku baru saja menyelesaikan Tes Minat dan Bakat di Skillana ${
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-mbti/" +
                      token
                    } Ayo kamu bisa ikutan juga loh!`}
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Twitter</b>
                  </TwitterShareButton>
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <WhatsappShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={process.env.REACT_APP_DOMAIN}
                    title={`Halo, aku baru saja menyelesaikan Tes Minat dan Bakat di Skillana ${
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-mbti/" +
                      token
                    } Ayo kamu bisa ikutan juga loh!`}
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>WhatsApp</b>
                  </WhatsappShareButton>
                </DropdownItem>
                <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                  <CopyToClipboard
                    className="text-left ml-2"
                    text={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-mbti/" +
                      token
                    }
                  >
                    <div>
                      <img
                        src={require("../../../../assets/img/copy.png")}
                        alt=""
                        style={{ width: "25px" }}
                      />
                      <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                        Copy Link
                      </b>
                    </div>
                  </CopyToClipboard>
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>

        <Row>
          <Col sm="2" />
          <Col sm="8">
            <div className="text-center">
              <h1
                className="mb-2 mt-5 title-menu-company text-center"
                style={{ color: Color.primaryColor }}
              >
                <b>
                  <ReactMarkdown source={props.result.result.predicate} />
                </b>
              </h1>
              <br />
              <img
                src={require("../../../../assets/img/16personalities/" +
                  props.result.type +
                  ".png")}
                alt="logo"
                style={{ width: 180 }}
                className="mb-4"
              />
              <h2 className="mb-2 title-menu-company">
                <b>
                  <ReactMarkdown source={props.result.type.toUpperCase()} />
                </b>
              </h2>
              <h4 style={{ color: Color.yellowColor }}>
                {" "}
                {props.result.description}
              </h4>
              <br />
              <div className="text-center">
                <div className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
                  <div className="progress-group">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Extrovert</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={props.result.dimensi.extrovert}
                        >
                          {props.result.dimensi.extrovert >=
                          props.result.dimensi.introvert ? (
                            <b>{props.result.dimensi.extrovert} %</b>
                          ) : (
                            props.result.dimensi.extrovert + "%"
                          )}{" "}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={props.result.dimensi.introvert}
                        >
                          {props.result.dimensi.extrovert <=
                          props.result.dimensi.introvert ? (
                            <b>{props.result.dimensi.introvert} %</b>
                          ) : (
                            props.result.dimensi.introvert + "%"
                          )}{" "}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Introvert</span>
                    </div>
                  </div>
                </div>
                {/* MOBILE INTROVERT EXTROVERT */}
                <div className="d-block d-sm-none || d-none d-sm-block d-md-none">
                  <Row>
                    <Col>
                      <div className="progress-group-prepend float-left">
                        <span className="progress-group-text">Extrovert</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="progress-group-prepend float-right">
                        <span className="progress-group-text">Introvert</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="progress-group-bars">
                    <Progress multi>
                      <Progress
                        bar
                        className="barOrange"
                        value={props.result.dimensi.extrovert}
                      >
                        {props.result.dimensi.extrovert >=
                        props.result.dimensi.introvert ? (
                          <b>{props.result.dimensi.extrovert} %</b>
                        ) : (
                          props.result.dimensi.extrovert + "%"
                        )}{" "}
                      </Progress>
                      <Progress
                        bar
                        className="barYellow"
                        value={props.result.dimensi.introvert}
                      >
                        {props.result.dimensi.extrovert <=
                        props.result.dimensi.introvert ? (
                          <b>{props.result.dimensi.introvert} %</b>
                        ) : (
                          props.result.dimensi.introvert + "%"
                        )}{" "}
                      </Progress>
                    </Progress>
                  </div>
                </div>
                <br />
                <div className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Sensing</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={props.result.dimensi.sensing}
                        >
                          {props.result.dimensi.intuition <=
                          props.result.dimensi.sensing ? (
                            <b>{props.result.dimensi.sensing} %</b>
                          ) : (
                            props.result.dimensi.sensing + "%"
                          )}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={props.result.dimensi.intuition}
                        >
                          {props.result.dimensi.intuition >=
                          props.result.dimensi.sensing ? (
                            <b>{props.result.dimensi.intuition} %</b>
                          ) : (
                            props.result.dimensi.intuition + "%"
                          )}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Intuition</span>
                    </div>
                  </div>
                </div>
                {/* MOBILE SENSING INTUTION */}
                <div className="d-block d-sm-none || d-none d-sm-block d-md-none">
                  <Row>
                    <Col>
                      <div className="progress-group-prepend float-left">
                        <span className="progress-group-text">Sensing</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="progress-group-prepend float-right">
                        <span className="progress-group-text">Intuition</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="progress-group-bars">
                    <Progress multi>
                      <Progress
                        bar
                        className="barOrange"
                        value={props.result.dimensi.sensing}
                      >
                        {props.result.dimensi.intuition <=
                        props.result.dimensi.sensing ? (
                          <b>{props.result.dimensi.sensing} %</b>
                        ) : (
                          props.result.dimensi.sensing + "%"
                        )}
                      </Progress>
                      <Progress
                        bar
                        className="barYellow"
                        value={props.result.dimensi.intuition}
                      >
                        {props.result.dimensi.intuition >=
                        props.result.dimensi.sensing ? (
                          <b>{props.result.dimensi.intuition} %</b>
                        ) : (
                          props.result.dimensi.intuition + "%"
                        )}
                      </Progress>
                    </Progress>
                  </div>
                </div>
                <br />
                <div className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Thinking</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={props.result.dimensi.thinking}
                        >
                          {props.result.dimensi.feeling <=
                          props.result.dimensi.thinking ? (
                            <b>{props.result.dimensi.thinking} %</b>
                          ) : (
                            props.result.dimensi.thinking + "%"
                          )}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={props.result.dimensi.feeling}
                        >
                          {props.result.dimensi.feeling >=
                          props.result.dimensi.thinking ? (
                            <b>{props.result.dimensi.feeling} %</b>
                          ) : (
                            props.result.dimensi.feeling + "%"
                          )}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Feeling</span>
                    </div>
                  </div>
                </div>
                {/* MOBILE THINGKING FEELING */}
                <div className="d-block d-sm-none || d-none d-sm-block d-md-none">
                  <Row>
                    <Col>
                      <div className="progress-group-prepend float-left">
                        <span className="progress-group-text">Thinking</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="progress-group-prepend float-right">
                        <span className="progress-group-text">Feeling</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="progress-group-bars">
                    <Progress multi>
                      <Progress
                        bar
                        className="barOrange"
                        value={props.result.dimensi.thinking}
                      >
                        {props.result.dimensi.feeling <=
                        props.result.dimensi.thinking ? (
                          <b>{props.result.dimensi.thinking} %</b>
                        ) : (
                          props.result.dimensi.thinking + "%"
                        )}
                      </Progress>
                      <Progress
                        bar
                        className="barYellow"
                        value={props.result.dimensi.feeling}
                      >
                        {props.result.dimensi.feeling >=
                        props.result.dimensi.thinking ? (
                          <b>{props.result.dimensi.feeling} %</b>
                        ) : (
                          props.result.dimensi.feeling + "%"
                        )}
                      </Progress>
                    </Progress>
                  </div>
                </div>
                <br />
                <div className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
                  <div className="progress-group">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Judging</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={props.result.dimensi.judging}
                        >
                          {props.result.dimensi.perceiving <=
                          props.result.dimensi.judging ? (
                            <b>{props.result.dimensi.judging} %</b>
                          ) : (
                            props.result.dimensi.judging + "%"
                          )}{" "}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={props.result.dimensi.perceiving}
                        >
                          {props.result.dimensi.perceiving >=
                          props.result.dimensi.judging ? (
                            <b>{props.result.dimensi.perceiving} %</b>
                          ) : (
                            props.result.dimensi.perceiving + "%"
                          )}{" "}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Perceiving</span>
                    </div>
                  </div>
                </div>
                {/* MOBILE PERCEIVING */}
                <div className="d-block d-sm-none || d-none d-sm-block d-md-none">
                  <Row>
                    <Col>
                      <div className="progress-group-prepend float-left">
                        <span className="progress-group-text">Judging</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="progress-group-prepend float-right">
                        <span className="progress-group-text">Perceiving</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="progress-group-bars">
                    <Progress multi>
                      <Progress
                        bar
                        className="barOrange"
                        value={props.result.dimensi.judging}
                      >
                        {props.result.dimensi.perceiving <=
                        props.result.dimensi.judging ? (
                          <b>{props.result.dimensi.judging} %</b>
                        ) : (
                          props.result.dimensi.judging + "%"
                        )}{" "}
                      </Progress>
                      <Progress
                        bar
                        className="barYellow"
                        value={props.result.dimensi.perceiving}
                      >
                        {props.result.dimensi.perceiving >=
                        props.result.dimensi.judging ? (
                          <b>{props.result.dimensi.perceiving} %</b>
                        ) : (
                          props.result.dimensi.perceiving + "%"
                        )}{" "}
                      </Progress>
                    </Progress>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <blockquote className="blockquote-quote">
                <h2 style={{ lineHeight: "1.5em" }}>
                  <i style={{ fontSize: "0.8em" }}>
                    <ReactMarkdown
                      source={'"' + props.result.result.quotes + '"'}
                    />
                  </i>
                </h2>
              </blockquote>
            </div>
            <br />
            <br />
            <h3 style={{ color: Color.yellowColor }}>
              <b>{t("Assessment.characteristics")}</b>
            </h3>
            <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
              <ReactMarkdown
                className="navtext"
                source={props.result.result.characteristics}
              />
            </span>
            <br />
            <h3 style={{ color: Color.yellowColor }}>
              <b>{t("Assessment.cognitif")}</b>
            </h3>
            <h5 style={{ color: Color.primaryColor }}>
              <i>{t("Assessment.thinking")}</i>
            </h5>
            <Row>
              <Col sm="6">
                <div
                  className="card menu-item"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="card-body">
                    <div className="menu-title mb-2">
                      <h4
                        className="mb-2 title-menu-company"
                        style={{ color: Color.primaryColor }}
                      >
                        <b>{t("Assessment.dominan")}</b>
                      </h4>
                      <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                        <ReactMarkdown
                          className="navtext"
                          source={props.result.result.dominan}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div
                  className="card menu-item"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="card-body">
                    <div className="menu-title mb-2">
                      <h4
                        className="mb-2 title-menu-company"
                        style={{ color: Color.primaryColor }}
                      >
                        <b>{t("Assessment.secondary")}</b>
                      </h4>
                      <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                        <ReactMarkdown
                          className="navtext"
                          source={props.result.result.sekunder}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <h3 style={{ color: Color.yellowColor }}>
              <b>{t("Assessment.profession")}</b>
            </h3>
            <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
              <ReactMarkdown source={props.result.result.profession} />
            </span>
            <br />
            <h3 style={{ color: Color.yellowColor }}>
              <b>{t("Assessment.partner")}</b>
            </h3>

            <Row>
              <Col sm="6" className="text-center">
                <Link
                  target="_blank"
                  to={"/type-personality/" + props.result.result.partner1}
                  onClick={() => goToLink()}
                >
                  <img
                    src={require("../../../../assets/img/16personalities/" +
                      props.result.result.partner1 +
                      ".png")}
                    alt="logo"
                    style={{ width: 180 }}
                    className="mb-4"
                  />
                </Link>
                <br />
                <h5 style={{ color: Color.primaryColor }}>
                  <b>
                    <ReactMarkdown
                      source={props.result.result.partner1.toUpperCase()}
                    />
                  </b>
                </h5>
              </Col>
              <Col sm="6" className="text-center">
                <Link
                  target="_blank"
                  to={"/type-personality/" + props.result.result.partner2}
                  onClick={() => goToLink()}
                >
                  <img
                    src={require("../../../../assets/img/16personalities/" +
                      props.result.result.partner2 +
                      ".png")}
                    alt="logo"
                    style={{ width: 180 }}
                    className="mb-4"
                  />
                </Link>
                <br />
                <h5 style={{ color: Color.primaryColor }}>
                  <b>
                    <ReactMarkdown
                      source={props.result.result.partner2.toUpperCase()}
                    />
                  </b>
                </h5>
              </Col>
            </Row>
            <br />
            <h3 style={{ color: Color.yellowColor }}>
              <b>{t("Assessment.suggestion")}</b>
            </h3>
            <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
              <ReactMarkdown
                className="navtext"
                source={props.result.result.suggestion}
              />
            </span>

            <br />
            <br />
          </Col>
          <Col sm="2" />
        </Row>
        {/* </Row> */}
      </div>
    </Container>
  );
});

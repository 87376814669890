import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { t, translate } from "react-switch-lang";
import { Formik, Field, Form } from "formik";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import NumberFormat from "react-number-format";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import FormikInput from "../../../../../../utils/FormikInput";
import request from "../../../../../../utils/Request";
import requestGeneral from "../../../../../../utils/RequestGeneral";
import langUtils from "../../../../../../utils/SettingLanguage/index";
import { toast } from "react-toastify";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";

let formValues;
export const JobPreference = (props) => {
  const [editProfile, setEditProfile] = useState(false);
  const [tags, setTags] = useState([]);
  const [interestList, setInterestList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    lastEducation: {
      value: null,
      label: null,
    },
    interest: "",
  });

  const toggleEditProfile = () => setEditProfile(!editProfile);

  useEffect(() => {
    async function fetchData() {
      try {
        const res2 = await requestGeneral.get("/v2/users/profile/professional");
        const res4 = await request.get("/master/interest");
        const interestData = res4.data.data;
        setInterestList(interestData);
        const userData = res2.data.data;
        if (userData.interest !== null) {
          let interestsList = [];
          let interested = {};
          userData.interest.map((value) => {
            interested = {
              value: value,
              label: value,
            };
            return interestsList.push(interested);
          });

          setTags(interestsList);
        }
        let lastEducation = "";
        if (
          userData.lastEducation === "SD" ||
          userData.lastEducation === "sd"
        ) {
          lastEducation = t("Lists.SD/MI (sederajat)");
        } else if (
          userData.lastEducation === "SMP" ||
          userData.lastEducation === "smp"
        ) {
          lastEducation = t("Lists.SMP/MTs (sederajat)");
        } else if (
          userData.lastEducation === "SMA" ||
          userData.lastEducation === "sma"
        ) {
          lastEducation = t("Lists.SMA/SMK/MA (sederajat)");
        } else {
          lastEducation = t("Lists." + userData.lastEducation);
        }
        formValues = {
          description: userData.description,
          major: userData.major,
          postalCodeIdentity: userData.identityAddress.postalCode,
          addressIdentity: userData.identityAddress.address,
        };
        setState({
          lastEducation: {
            value: userData.lastEducation,
            label: lastEducation,
          },
          expectedSalary: userData.expectedSalary,
        });
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
    fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langUtils.getLanguage()]);

  const formValidate = (values) => {
    let errors = {};
    // if (values.postalCodeIdentity !== null) {
    //   if (values.postalCodeIdentity.toString().length !== 5) {
    //     errors.postalCodeIdentity = t("Profile.postalCodeNotValid");
    //   }
    // }
    return errors;
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let tagList = [];
    if (tags) {
      tags.map((value) => {
        return tagList.push(value.value);
      });
    }
    const dataObject = {
      lastEducation: state.lastEducation.value,
      major: values.major,
      expectedSalary: state.expectedSalary,
      interest: tagList,
    };
    await requestGeneral
      .put("/v2/users/profile/professional", dataObject)
      .then((res) => {
        props.setUser({
          ...props.user,
          expectedSalary: state.expectedSalary,
        });
        setEditProfile(false);
        toast.success(t("General.success"), { autoClose: 3000 });
      })
      .catch((err) => {
        if (err.response.status === 422) {
          toast.error(
            JSON.stringify(err.response.data.errors) || "Error Input Data",
            { autoClose: 3000 }
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChangeLastEducation = (value) => {
    let lastEducation = "";
    if (value.value === "SD" || value.value === "sd") {
      lastEducation = t("Lists.SD/MI (sederajat)");
    } else if (value.value === "SMP" || value.value === "smp") {
      lastEducation = t("Lists.SMP/MTs (sederajat)");
    } else if (value.value === "SMA" || value.value === "sma") {
      lastEducation = t("Lists.SMA/SMK/MA (sederajat)");
    } else {
      lastEducation = t(value.value);
    }
    setState({
      ...state,
      major: "",
      lastEducation: {
        label: lastEducation,
        value: value.value,
      },
      expectedSalary: null,
    });
  };

  const levelFormal = [
    { value: "SD", label: t("Lists.SD/MI (sederajat)") },
    { value: "SMP", label: t("Lists.SMP/MTs (sederajat)") },
    { value: "SMA", label: t("Lists.SMA/SMK/MA (sederajat)") },
    { value: "D1", label: t("Lists.D1") },
    { value: "D2", label: t("Lists.D2") },
    { value: "D3", label: t("Lists.D3") },
    { value: "D4", label: t("Lists.D4") },
    { value: "S1", label: t("Lists.S1") },
    { value: "S2", label: t("Lists.S2") },
    { value: "S3", label: t("Lists.S3") },
  ];

  const changeState = (e) => {
    setState({
      ...state,
      interest: e,
    });
  };

  const onChangeSelect = (value, { action, removedValue }) => {
    switch (action) {
      case "select-option":
        setTags(value);
        value = "";
        setState({
          ...state,
          interest: "",
        });
        break;
      case "create-option":
        setTags(value);
        value = "";
        setState({
          ...state,
          interest: "",
        });
        break;
      case "pop-value":
        setTags(value);
        value = "";
        setState({
          ...state,
          interest: "",
        });
        break;
      case "remove-value":
        setTags(value);
        value = "";
        setState({
          ...state,
          interest: "",
        });
        break;
      case "clear":
        setTags([]);
        break;
      default:
        break;
    }
  };

  const components = {
    DropdownIndicator: null,
  };

  const cancel = () => {
    window.location.reload();
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <Formik
        initialValues={formValues}
        validate={formValidate}
        onSubmit={formSubmit}
        render={({ isSubmitting }) => (
          <Form style={{ display: "inherit" }}>
            {/* PREFERENSI PEKERJAAN  */}
            <Row>
              <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                <h5 className="content-sub-title mb-0">
                  <b>{t("Profile.preferensiJob")}</b>
                </h5>
                {editProfile === false ? (
                  <Button
                    style={{ backgroundColor: "#5AADF7" }}
                    className="navtext btn-netis-color"
                    onClick={toggleEditProfile}
                  >
                    <i className="fa fa-pencil" style={{ marginRight: 5 }}></i>
                    Edit
                  </Button>
                ) : null}
              </div>
            </Row>
            <Row>
              <Col
                md={
                  state.lastEducation.value === "SD"
                    ? "6"
                    : state.lastEducation.value === "SD/MI (sederajat)"
                    ? "6"
                    : state.lastEducation.value === "SMP/MTs (sederajat)"
                    ? "6"
                    : state.lastEducation.value === "SMP"
                    ? "6"
                    : "4"
                }
                className="mb-3"
              >
                <FormGroup>
                  <Label htmlFor="Latest Education" className="input-label">
                    {t("Profile.lastEducation")}{" "}
                  </Label>
                  <Select
                    value={state.lastEducation}
                    onChange={(value) => onChangeLastEducation(value)}
                    options={levelFormal}
                    isDisabled={editProfile === false ? true : false}
                  />
                </FormGroup>
              </Col>
              {state.lastEducation.value === "SD" ? null : state.lastEducation
                  .value === "SD/MI (sederajat)" ? null : state.lastEducation
                  .value === "SMP/MTs (sederajat)" ? null : state.lastEducation
                  .value === "SMP" ? null : (
                <>
                  <Col md="4" className="mb-3">
                    <Field
                      label={<span>{t("Profile.major")}</span>}
                      type="text"
                      name="major"
                      id="major"
                      disabled={editProfile === false ? true : false}
                      value={formValues?.major ?? ""}
                      component={FormikInput}
                    />
                  </Col>
                </>
              )}
              <Col
                md={
                  state.lastEducation.value === "SD"
                    ? "6"
                    : state.lastEducation.value === "SD/MI (sederajat)"
                    ? "6"
                    : state.lastEducation.value === "SMP/MTs (sederajat)"
                    ? "6"
                    : state.lastEducation.value === "SMP"
                    ? "6"
                    : "4"
                }
                className="mb-3"
              >
                <FormGroup>
                  <Label htmlFor="salary" className="input-label">
                    {t("Profile.salary")} (IDR){" "}
                  </Label>
                  <NumberFormat
                    thousandSeparator={true}
                    disabled={editProfile === false ? true : false}
                    value={state.expectedSalary}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({
                        ...state,
                        expectedSalary: value,
                      });
                    }}
                    className="form-control text-left"
                    name="expectedSalary"
                    id="expectedSalary"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="col-12" style={{ marginTop: "-15px" }}>
                <Label htmlFor="Bidang Minat" className="input-label">
                  {t("Profile.interest")}{" "}
                </Label>

                <CreatableSelect
                  components={components}
                  isClearable
                  isDisabled={editProfile === false ? true : false}
                  onInputChange={(value) => changeState(value)}
                  onChange={onChangeSelect}
                  // menuIsOpen={state.interest === "" ? false : true}
                  options={interestList}
                  isMulti
                  defaultValue={tags}
                  formatCreateLabel={() => ``}
                  // onKeyDown={handleKeyDown}
                  // onKeyDown={(e) => (e.key === "," ? addTags(e) : null)}
                  placeholder={t("Profile.interestEnter")}
                />
              </div>
            </Row>
            <br />
            <Row>
              <div className="col-12 d-flex justify-content-end">
                {editProfile === true ? (
                  <>
                    <Button className="btn-white mr-3" onClick={cancel}>
                      {t("General.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#5AADF7", color: "white" }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          <Spinner size="sm" className="mr-2" /> Loading
                        </span>
                      ) : (
                        t("General.save")
                      )}
                    </Button>
                  </>
                ) : null}
              </div>
            </Row>
          </Form>
        )}
      />
    </>
  );
};

const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};

export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(JobPreference));

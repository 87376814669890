import React, { useState, Fragment, useEffect, useRef } from "react";
import { Row, Button, FormGroup, Input, Spinner, Col, Label } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import request from "../../../../../../utils/Request";
import requestGeneral from "../../../../../../utils/RequestGeneral";
import { translate } from "react-switch-lang";
import { Formik, Field, Form } from "formik";
import FormikInput from "../../../../../../utils/FormikInput";
import DatePicker from "react-date-picker";
import langUtils from "../../../../../../utils/SettingLanguage/index";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
toast.configure();
let formValues;
const ProfileStandart = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [editProfileStandart, setEditProfileStandart] = useState(false);
  const [gendersList, setGendersList] = useState(false);
  const [state, setState] = useState({
    image: {
      preview: null,
      raw: "",
    },
    avatar: null,
    dateOfBirth: null,
    gender: {
      value: null,
      label: null,
    },
  });
  const inputAvatar = useRef(null);

  const onInputAvatarClick = (e) => {
    inputAvatar.current.click();
  };

  const toggleEditProfileStandart = () => {
    setEditProfileStandart(!editProfileStandart);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res1 = await request.get("/master/genders?all=true");
        const res2 = await requestGeneral.get("/v2/users/profile/standart");
        let gender = {};
        let genderList = [];
        res1.data.data.map((data) => {
          gender = {
            value: data.id,
            label: t("Lists." + data.name),
          };
          return genderList.push(gender);
        });
        setGendersList(genderList);
        const userData = res2.data.data;
        formValues = {
          fullName: userData.fullName,
          nickName: userData.nickName,
          email: userData.email,
          phone: userData.phone,
          placeOfBirth: userData.placeOfBirth,
          facebook: userData.facebook,
          twitter: userData.twitter,
          instagram: userData.instagram,
          linkedin: userData.linkedin,
        };
        setState({
          image: {
            preview: null,
            raw: "",
          },
          avatar: userData.avatar,
          gender: {
            value: userData.gender.id,
            label: t("Lists." + userData.gender.name),
          },
          dateOfBirth: userData.dateOfBirth,
        });
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
    fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langUtils.getLanguage()]);
  // useEffect(() => {

  const cancel = () => {
    window.location.reload();
  };

  // }, [langUtils.getLanguage()]);
  const onChangeGender = (value) => {
    setState({
      ...state,
      gender: value,
    });
  };

  const onChangeAvatar = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      var preview = { ...state.image };
      preview["preview"] = URL.createObjectURL(e.target.files[0]);
      setState({
        ...state,
        avatar: e.target.files[0],
        image: preview,
      });
    }
  };
  const onChangeDateBirth = (date) => {
    let dateOfBirth = formatDate(date);
    setState({
      ...state,
      dateOfBirth: dateOfBirth,
    });
  };
  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const formValidate = (values) => {
    let errors = {};
    var patternText = new RegExp(/^[a-zA-Z'-.\s]+$/);
    var patternNumber = new RegExp(/^[0-9\b]+$/);
    if (values.fullName !== "") {
      if (!patternText.test(values.fullName)) {
        errors.fullName = t("Register.fullNameLetter");
      }
    }
    if (values.phone !== null) {
      if (!patternNumber.test(values.phone)) {
        errors.phone = t("Register.phoneNotValid");
      } else if (values.phone.length < 10) {
        errors.phone = t("Register.phoneNotValid");
      } else if (values.phone.length > 13) {
        errors.phone = t("Register.phoneNotValid");
      }
    }

    return errors;
  };
  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    if (values.fullName === null || values.fullName === "") {
      setSubmitting(false);
      return toast.error(t("General.cannotEmpty"), { autoClose: 3000 });
    } else if (values.email === null || values.email === "") {
      setSubmitting(false);
      return toast.error(t("General.cannotEmpty"), { autoClose: 3000 });
    } else if (
      state.dateOfBirth === null ||
      state.dateOfBirth === "" ||
      state.dateOfBirth === new Date()
    ) {
      setSubmitting(false);
      return toast.error(t("General.cannotEmpty"), { autoClose: 3000 });
    }
    /* } else if (
      (values.facebook === null || values.facebook === "") &&
      (values.twitter === null || values.twitter === "") &&
      (values.instagram === null || values.instagram === "") &&
      (values.linkedin === null || values.linkedin === "")
    ) {
      setSubmitting(false);
      return toast.error(
        "Kamu harus mengisi akun sosial media yang kamu miliki",
        {
          autoClose: 3000,
        }
      ); */
    const dataObject = {
      fullName: values.fullName,
      nickName: values.nickName,
      email: values.email,
      phone: values.phone,
      placeOfBirth: values.placeOfBirth,
      facebook: values.facebook,
      twitter: values.twitter,
      instagram: values.instagram,
      linkedin: values.linkedin,
      gender: state.gender.value,
      dateOfBirth: state.dateOfBirth,
    };
    await requestGeneral
      .put("/v2/users/profile/standart", dataObject)
      .then(async (res) => {
        if (state.image.preview !== null) {
          var formData = new FormData();
          formData.append("ava", state.avatar, state.avatar.name);
          try {
            await request.post("/users/avatar", formData);
            props.setUser({
              ...props.user,
              avatar: state.avatar,
              fullName: values.fullName,
              nickName: values.nickName,
              email: props.user.email,
              phone: props.user.phone,
            });
            toast.success(t("General.success"), { autoClose: 3000 });
            setEditProfileStandart(false);
            window.location.reload();
          } catch (err) {
            if (
              err.response.data.errors ===
              "<p>The filetype you are attempting to upload is not allowed.</p>"
            ) {
              toast.error("Tipe file yang kamu upload tidak sesuai", {
                autoClose: 2000,
              });
            } else if (
              err.response.data.errors ===
              "<p>The file you are attempting to upload is larger than the permitted size.</p>"
            ) {
              toast.error(
                "Ukuran file yang kamu upload terlalu besar. Maksimal 5 Mb",
                { autoClose: 2000 }
              );
            } else {
              toast.error("Upload Error", { autoClose: 2000 });
            }
          }
        } else {
          props.setUser({
            ...props.user,
            avatar: state.avatar,
            fullName: values.fullName,
            nickName: values.nickName,
            email: props.user.email,
            phone: props.user.phone,
          });
          setEditProfileStandart(false);
          toast.success(t("General.success"), { autoClose: 3000 });
        }
      })
      .catch((err) => {
        toast.error(
          JSON.stringify(err.response.data.errors) || "Error Input Data",
          { autoClose: 3000 }
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="step-profile-2 px-2">
      <Fragment>
        <Formik
          initialValues={formValues}
          validate={formValidate}
          onSubmit={formSubmit}
          render={({ isSubmitting }) => (
            <Form style={{ display: "inherit" }}>
              <Row>
                <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                  <h5 className="content-sub-title mb-0">
                    <b>{t("Profile.standard")}</b>
                  </h5>
                  {editProfileStandart === false ? (
                    <Button
                      style={{ backgroundColor: "#5AADF7" }}
                      className="btn-netis-color"
                      onClick={toggleEditProfileStandart}
                    >
                      <i
                        className="fa fa-pencil"
                        style={{ marginRight: 5 }}
                      ></i>
                      Edit
                    </Button>
                  ) : null}
                </div>
              </Row>
              <Row>
                <div className="col-md-12 mt-3">
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col xs="12" sm="12" md="4" lg="3" xl="3" className="mb-2">
                      {state.avatar === null ? (
                        state.image.preview ? (
                          <div className="frame-profile-picture">
                            <img
                              src={state.image.preview}
                              alt="Profil Pict"
                              width="125px"
                              height="auto"
                            />
                          </div>
                        ) : (
                          <div className="frame-profile-picture-empty">
                            {t("Profile.noPhoto")}
                          </div>
                        )
                      ) : state.image.preview ? (
                        <div className="frame-profile-picture">
                          <img
                            src={state.image.preview}
                            alt="Profil Pict"
                            width="125px"
                            height="auto"
                          />
                        </div>
                      ) : (
                        <div className="frame-profile-picture">
                          <img
                            src={
                              process.env.REACT_APP_DOMAIN + "" + state.avatar
                            }
                            alt="avatar"
                            className="img-fluid"
                          />
                        </div>
                      )}
                      <br />
                    </Col>
                    <Col xs="12" sm="12" md="8" lg="9" xl="9">
                      {editProfileStandart === true ? (
                        <FormGroup>
                          <Button
                            className="btn-main mb-2"
                            onClick={onInputAvatarClick}
                          >
                            Unggah Foto
                          </Button>
                          <p>
                            {" "}
                            {t("Profile.uploadFileFormat")}{" "}
                            <span style={{ fontWeight: "bold" }}>
                              (.png, .jpg, .jpeg)
                            </span>
                            <br />{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {t("General.maximum")} 5 Mb
                            </span>
                          </p>
                          <Input
                            type="file"
                            id="avatar"
                            name="avatar"
                            onChange={onChangeAvatar}
                            innerRef={inputAvatar}
                            style={{ display: "none" }}
                          />
                        </FormGroup>
                      ) : null}
                    </Col>
                  </Row>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="text"
                      label={
                        <span>
                          {t("General.fullname")}
                          <b
                            style={{
                              color: "red",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            *
                          </b>
                        </span>
                      }
                      name="fullName"
                      id="fullName"
                      placeholder={t("General.fullname")}
                      disabled={editProfileStandart === false ? true : false}
                      value={formValues.fullName}
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="text"
                      label={<span>{t("General.nickname")}</span>}
                      name="nickName"
                      id="nickName"
                      placeholder={t("General.nickname")}
                      disabled={editProfileStandart === false ? true : false}
                      value={formValues.nickName}
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="text"
                      label={
                        <span>
                          Email{" "}
                          <b
                            style={{
                              color: "red",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            *
                          </b>
                        </span>
                      }
                      name="email"
                      id="email"
                      placeholder="Email"
                      disabled
                      value={formValues.email}
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="text"
                      label={
                        <span>
                          {t("General.phone")}
                          <b
                            style={{
                              color: "red",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            *
                          </b>
                        </span>
                      }
                      name="phone"
                      id="phone"
                      placeholder="08xxx"
                      disabled={editProfileStandart === false ? true : false}
                      value={formValues.phone}
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <FormGroup>
                      <Label htmlFor="gender" className="input-label">
                        {t("Profile.gender")}{" "}
                      </Label>
                      <Select
                        value={state.gender}
                        onChange={(value) => onChangeGender(value)}
                        options={gendersList}
                        isDisabled={
                          editProfileStandart === false ? true : false
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Col sm="6" className="mb-3">
                      <FormGroup>
                        <Label htmlFor="dateOfBirth" className="input-label">
                          {t("Profile.dateBirth")}{" "}
                          <b
                            style={{
                              color: "red",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            *
                          </b>
                        </Label>
                        <DatePicker
                          onChange={onChangeDateBirth}
                          disabled={
                            editProfileStandart === false ? true : false
                          }
                          format={"dd-MM-y"}
                          className="form-control"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          dayPlaceholder="dd"
                          maxDate={new Date()}
                          value={
                            state.dateOfBirth === null
                              ? new Date()
                              : new Date(
                                  new Date(state.dateOfBirth).getFullYear(),
                                  new Date(state.dateOfBirth).getMonth(),
                                  new Date(state.dateOfBirth).getDate()
                                )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" className="mb-3">
                      <Field
                        type="text"
                        label={<span>{t("Profile.placeBirth")} </span>}
                        name="placeOfBirth"
                        id="placeOfBirth"
                        placeholder={t("Profile.placeBirth")}
                        disabled={editProfileStandart === false ? true : false}
                        value={formValues.placeOfBirth}
                        component={FormikInput}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6" className="mb-3" style={{ marginTop: "-15px" }}>
                      <Field
                        type="text"
                        label={<span>Facebook </span>}
                        name="facebook"
                        id="facebook"
                        disabled={editProfileStandart === false ? true : false}
                        value={formValues.facebook}
                        component={FormikInput}
                      />
                    </Col>
                    <Col sm="6" className="mb-3" style={{ marginTop: "-15px" }}>
                      <Field
                        type="text"
                        label={<span>Twitter </span>}
                        name="twitter"
                        id="twitter"
                        disabled={editProfileStandart === false ? true : false}
                        value={formValues.twitter}
                        component={FormikInput}
                      />
                    </Col>
                    <Col sm="6" className="mb-3" style={{ marginTop: "-15px" }}>
                      <Field
                        type="text"
                        label={<span>Instagram </span>}
                        name="instagram"
                        id="instagram"
                        disabled={editProfileStandart === false ? true : false}
                        value={formValues.instagram}
                        component={FormikInput}
                      />
                    </Col>
                    <Col sm="6" className="mb-3" style={{ marginTop: "-15px" }}>
                      <Field
                        type="text"
                        label={<span>Linkedin </span>}
                        name="linkedin"
                        id="linkedin"
                        disabled={editProfileStandart === false ? true : false}
                        value={formValues.linkedin}
                        component={FormikInput}
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <div className="col-12 d-flex justify-content-end">
                  {editProfileStandart === true ? (
                    <Fragment>
                      <Button className="btn-white mr-3" onClick={cancel}>
                        {t("General.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        style={{ backgroundColor: "#5AADF7", color: "white" }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner size="sm" className="mr-2" /> Loading
                          </span>
                        ) : (
                          t("General.save")
                        )}
                      </Button>
                    </Fragment>
                  ) : null}
                </div>
              </Row>
            </Form>
          )}
        />
      </Fragment>
    </div>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};

export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(ProfileStandart));

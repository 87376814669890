import React, { useState, Fragment } from "react";
import {
  Row,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  ListGroup,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { translate, t } from "react-switch-lang";
import FooterLandingPage from "../../../../Templates/LandingPage/FooterLandingPage";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import requestGeneral from "../../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import ConfirmationModal from "../../../../../components/atoms/ConfirmationModal";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import ReactMarkdown from "react-markdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CaptureLoading from "../../../../../components/atoms/CaptureLoading";
import NotFound from "../../../NotFound";
import langUtils from "../../../../../utils/SettingLanguage/index";
import Fisiognomi from "../../Assessment/Fisiognomi";
import Palmistry from "../../Assessment/Palmistry";
import DefaultBreadcumb from "../../../../../containers/Pages/Menu/DefaultLayout/DefaultBreadcumb";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import noLogoCompany from "../../../../../assets/img/company_icon.png";

import { toast } from "react-toastify";
import { Redirect } from "react-router";
import { setPrevPath } from "../../../../../config/Services/Actions/ui";
const JobDetail = (props) => {
  const [vacancy, setVacancy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [status, setStatus] = useState(null);

  const [existFisiognomi, setExistFisiognomi] = useState(true);
  const [existPalmistry, setExistPalmistry] = useState(true);
  const [clickButton, setClickButton] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showBookmark, setShowBookmark] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const { id, slug } = useParams();

  const [name, setName] = useState("Copy Link");
  const diKlik = () => {
    setName(t("JobVacancy.alreadyCopy"));
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const dispatch = useDispatch();

  function kapital(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  function bookmarkStatus() {
    setLoadingButton(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/bookmark/" + id)
      .then((res) => {
        setLoadingButton(false);
        if (res.data.bookmark === true) {
          toast.success(t("JobVacancy.successAddBookmark"));
        } else {
          toast.success(t("JobVacancy.successDeleteBookmark"));
        }
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          toast.error("Error !");
          return;
        }
        setLoadingButton(false);
      })
      .finally(() => setShowBookmark(!showBookmark));
  }

  useEffect(() => {
    if (!props.user?.id) {
      getVacanciesNotAuth();
    } else {
      getVacanciesAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langUtils.getLanguage()]);

  const getVacanciesNotAuth = async () => {
    requestGeneral
      .get(`internal/recruitment/vacancies/status/share/${id}/${slug}`)
      .then((res) => {
        const vacancies = res.data.data;
        setVacancy(vacancies);
        setLoading(false);
      })
      .catch((error) => {
        setVacancy(undefined);
        setLoading(false);
      });
  };

  const getVacanciesAuth = async () => {
    requestGeneral
      .get(`internal/recruitment/vacancies/status/${id}`)
      .then((res) => {
        setVacancy(res.data.data);
        setStatus(res.data.data.status);
        setShowBookmark(res.data.data.bookmark);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function popUpRegister() {
    if (modal) {
      return (
        <ConfirmationModal
          isOpen={modal}
          title={t("JobVacancy.jobVacancy")}
          desc={t("JobVacancy.sureApply")}
          titleLeft={t("General.no")}
          titleRight={t("General.sure")}
          center={true}
          onPressRight={() => {
            register();
            setModal(false);
            setModalLoading(true);
          }}
          onPressLeft={() => setModal(false)}
        />
      );
    }
  }
  function successRegister() {
    if (success) {
      return (
        <AffirmationModal
          isOpen={success}
          title={t("General.congratulations") + " !"}
          desc={t("JobVacancy.congratsApply")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setSuccess(true);
          }}
          confirmation={() => {
            setSuccess(false);
            setRedirect("/aplikasi-lamaran");
          }}
        />
      );
    }
  }
  function errorRegister() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={t("General.sorry") + " !"}
          desc={t("JobVacancy.failedApply")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            setError(false);
          }}
        />
      );
    }
  }
  function popUpLoading() {
    if (modalLoading) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  }
  const register = async () => {
    try {
      requestGeneral.post("/internal/recruitment/vacancy/" + id).then((res) => {
        setSuccess(true);
        setError(false);
        setModalLoading(false);
      });
    } catch {
      setSuccess(false);
      setModalLoading(false);
      setError(true);
    }
  };

  // function validate_palmistry(){
  //   requestGeneral
  //     .get("v1/assessment_video/validate_palmistry")
  //     .then((res) => {
  //       setExistPalmistry(false)
  //       setIsNoAssessment(true)
  //     })
  //     .catch((err) => {
  //       setExistPalmistry(true);
  //       setLoading(false);
  //     });
  // }

  useEffect(() => {
    if (existFisiognomi && existPalmistry && clickButton) {
      setModal(true);
      setClickButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existFisiognomi, existPalmistry, clickButton]);

  function redirectButton() {
    if (!props.user?.id) {
      setRedirect("/login");
    } else if (localStorage.getItem("referal")) {
      setRedirect("/home");
    } else if (
      status === "rejected" ||
      status === "accepted" ||
      status === "pending"
    ) {
      return "#";
    } else {
      if (vacancy?.requirements) {
        for (let i = 0; i < vacancy?.requirements.length; i++) {
          if (
            vacancy?.requirements[i]?.assessment === "palmistry" &&
            vacancy?.requirements[i]?.status === false
          ) {
            setExistPalmistry(false);
          }
          if (
            vacancy?.requirements[i]?.assessment === "fisiognomi" &&
            vacancy?.requirements[i]?.status === false
          ) {
            setExistFisiognomi(false);
          }
        }
      }
      setClickButton(true);
    }
  }
  const [redirect, setRedirect] = useState(false);
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  const assessment_not_complete = {
    profile: {
      text: t("JobVacancy.profileNotComplete"),
      url: "/profile",
      title: t("Assessment.profile"),
    },
    mbti: {
      text: t("JobVacancy.mbtiNoComplete"),
      url: "/assessment-mbti",
      title: t("Assessment.aptitudeTitle"),
    },
    disc: {
      text: t("JobVacancy.discNotComplete"),
      url: "/assessment-disc",
      title: t("Assessment.personalityTitle1"),
    },
    spm: {
      text: t("JobVacancy.discNotComplete2"),
      url: "/assessment-disc-2",
      title: t("Assessment.personalityTitle2"),
    },
    msdt: {
      text: t("JobVacancy.msdtNotComplete"),
      url: "/assessment-msdt",
      title: t("Assessment.leadershipTitle"),
    },
    papikostick: {
      text: t("JobVacancy.papiNotComplete"),
      url: "/assessment-papikostik",
      title: t("Assessment.workStyleTitle"),
    },
    businessInsight: {
      text: t("JobVacancy.businessInsightNotComplete"),
      url: "/assessment-business-insight",
      title: t("Assessment.businessInsightTitle"),
    },
    agility: {
      text: t("JobVacancy.agilityNotComplete"),
      url: "/assessment-agility",
      title: t("Assessment.agilityTitle"),
    },
    fingerprint: {
      text: t("JobVacancy.fingerprintNotComplete"),
      url: "/assessment-fingerprint",
      title: t("Assessment.biometricTitle"),
    },
    fisiognomi: {
      text: t("JobVacancy.fisiognomiNotComplete"),
      url: "#",
      title: t("JobVacancy.fisiognomiTitle"),
    },
    palmistry: {
      text: t("JobVacancy.palmistryNotComplete"),
      url: "#",
      title: t("JobVacancy.palmistryTitle"),
    },
    video: {
      text: t("JobVacancy.videoNotComplete"),
      url: "/assessment-video",
      title: t("Interview.CVVideo"),
    },
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {!existFisiognomi ? (
            <Fisiognomi onComplete={() => setExistFisiognomi(true)} />
          ) : !existPalmistry ? (
            <Palmistry onComplete={() => setExistPalmistry(true)} />
          ) : (
            <DefaultBreadcumb>
              <div className="job-detail-page">
                {popUpRegister()}
                {popUpLoading()}
                {successRegister()}
                {errorRegister()}
                {vacancy !== undefined ? (
                  <Fragment>
                    <div style={{ padding: "0, 141px", marginBottom: "-6px" }}>
                      <Breadcrumb className="navtext breadcrumb-header">
                        <BreadcrumbItem>
                          {!props.user?.id ? (
                            <Link to="/jobplacement">
                              {t("Dashboard.jobVacancy")}
                            </Link>
                          ) : (
                            <Link to="/home">Home</Link>
                          )}
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{vacancy.name}</BreadcrumbItem>
                      </Breadcrumb>
                    </div>
                    <Container
                      className="job-detail-list pt-5"
                      key={vacancy.id}
                    >
                      <Row className="pt-3">
                        <Col lg="9">
                          <Row>
                            <Col lg="12">
                              <ListGroup className="job-placement">
                                <Row>
                                  <Col lg="2" sm="12">
                                    {vacancy.company.logo ? (
                                      <img
                                        src={
                                          vacancy.company.logo ===
                                          "https://hris-dev.widyaskilloka.com"
                                            ? noLogoCompany
                                            : vacancy.company.logo
                                        }
                                        alt="imageJob"
                                        className="image-job-placement rounded"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          vacancy.company.logo ===
                                            "https://hris-dev.widyaskilloka.com" ||
                                          "unknown"
                                            ? noLogoCompany
                                            : vacancy.company.logo
                                        }
                                        alt="imageJob"
                                        className="image-job-placement rounded"
                                      />
                                    )}
                                  </Col>
                                  <Col
                                    lg="10"
                                    sm="12"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    <div className="job-placement-title">
                                      <div>
                                        <h1>{vacancy.name}</h1>
                                      </div>
                                      <h3>
                                        <Link
                                          to={`/company-profile/${
                                            vacancy.id
                                          }/${vacancy.company.name
                                            .toString()
                                            .toLowerCase()
                                            .normalize("NFD")
                                            .trim()
                                            .replace(/\s+/g, "-") // eslint-disable-next-line
                                            .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                            .replace(/\-\-+/g, "-")}`}
                                          className="link-job"
                                        >
                                          <u>{vacancy.company.name}</u>
                                        </Link>
                                      </h3>
                                      <h2>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        {vacancy.province.name
                                          ? vacancy.city.name
                                            ? kapital(vacancy.province.name) +
                                              ", " +
                                              kapital(
                                                vacancy.city.name
                                                  .replace("KABUPATEN ", "")
                                                  .replace("KOTA ", "")
                                              )
                                            : kapital(vacancy.province.name)
                                          : t("JobVacancy.locationNoMention")}
                                      </h2>
                                    </div>
                                    <div
                                      className="job-placement-type"
                                      style={{ marginTop: "2rem" }}
                                    >
                                      <Row>
                                        <Col lg="6">
                                          <p>
                                            {vacancy.showSalary ? (
                                              <span>
                                                IDR{" "}
                                                {`${new Intl.NumberFormat([
                                                  "ban",
                                                  "id",
                                                ]).format(
                                                  vacancy.minSalary
                                                )} - ${new Intl.NumberFormat([
                                                  "ban",
                                                  "id",
                                                ]).format(vacancy.maxSalary)}`}
                                              </span>
                                            ) : (
                                              <span>
                                                {props.user?.expectedSalary ? (
                                                  props.user?.expectedSalary <
                                                  vacancy.minSalary ? (
                                                    <>
                                                      {t(
                                                        "JobVacancy.salaryAbove"
                                                      )}
                                                    </>
                                                  ) : props.user
                                                      ?.expectedSalary >
                                                    vacancy.maxSalary ? (
                                                    <>
                                                      {t(
                                                        "JobVacancy.salaryBelow"
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {t(
                                                        "JobVacancy.salaryExpected"
                                                      )}
                                                    </>
                                                  )
                                                ) : (
                                                  <>
                                                    {t(
                                                      "JobVacancy.salaryNoMention"
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            )}
                                          </p>
                                        </Col>
                                        <Col lg="6">
                                          <p className="text-left">
                                            {t("Lists." + vacancy.type)}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="6">
                                          <p>{`${t("General.experience")} : ${
                                            vacancy.minYearExperience.toString() ===
                                            "Fresh Graduate"
                                              ? vacancy.minYearExperience.toString()
                                              : vacancy.minYearExperience.toString() ===
                                                "Lebih dari 5 Tahun"
                                              ? t("JobVacancy.more5year")
                                              : vacancy.minYearExperience
                                                  .toString()
                                                  .replace("Tahun", "") +
                                                t("General.year")
                                          }`}</p>
                                        </Col>
                                        <Col lg="6">
                                          <p>{`${t("General.education")} : ${t(
                                            "Lists." +
                                              vacancy.minEducation.toUpperCase()
                                          )} ${vacancy.major}`}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup>
                            </Col>
                            <Col lg="12">
                              <div
                                className="job-description"
                                style={{ marginTop: "2rem" }}
                              >
                                <div className="job-description-title">
                                  <h1>{t("JobVacancy.description")}</h1>
                                </div>

                                <div
                                  className="job-desc-list"
                                  style={{
                                    borderRadius: "0 0 10px 10px",
                                    paddingLeft: "2.5rem",
                                  }}
                                >
                                  <ReactMarkdown source={vacancy.description} />
                                </div>
                              </div>
                            </Col>
                            {vacancy?.skills?.length > 0 ? (
                              <Col lg="12">
                                <div
                                  className="job-description"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <div className="job-description-title">
                                    <h1>{t("JobVacancy.skill")}</h1>
                                  </div>
                                  <div
                                    className="job-desc-list"
                                    style={{ borderRadius: "0 0 10px 10px" }}
                                  >
                                    <ul>
                                      {vacancy.skills.map((skill) => (
                                        <li
                                          className="tags-button ml-1"
                                          key={skill}
                                        >
                                          {skill}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}

                            {props.user?.id &&
                            vacancy?.requirements?.length > 0 ? (
                              <Col lg="12">
                                <div
                                  className="job-description"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <div className="job-description-title">
                                    <h1>{t("JobVacancy.asesmenReq")}</h1>
                                  </div>
                                  <div
                                    className="job-desc-list"
                                    style={{ borderRadius: "0 0 10px 10px" }}
                                  >
                                    <ul>
                                      {vacancy.requirements
                                        .filter(
                                          (item) =>
                                            item.assessment !== "palmistry" &&
                                            item.assessment !== "fisiognomi"
                                        )
                                        .map((requirement, idx) => (
                                          <li
                                            className={
                                              requirement?.status
                                                ? "tags-button bg-green ml-1"
                                                : "tags-button bg-red ml-1"
                                            }
                                            key={idx}
                                          >
                                            <Link
                                              to={
                                                assessment_not_complete[
                                                  requirement?.assessment
                                                ]?.url
                                              }
                                              onClick={() =>
                                                dispatch(
                                                  setPrevPath({
                                                    url: props.location
                                                      .pathname,
                                                  })
                                                )
                                              }
                                              style={{ color: "#fff" }}
                                            >
                                              <i
                                                className={
                                                  requirement?.status
                                                    ? "fa fa-check mr-2"
                                                    : "fa fa-times mr-2"
                                                }
                                              />
                                              {
                                                assessment_not_complete[
                                                  requirement?.assessment
                                                ]?.title
                                              }
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </Col>
                        <Col lg="3" className="button-side-group">
                          <Row>
                            <Col sm="12" xs="12">
                              <div className="button-group">
                                <Button
                                  className="button-detail-daftar"
                                  onClick={redirectButton}
                                  disabled={
                                    (props.user?.id &&
                                      localStorage.getItem("referal")) ||
                                    status === "rejected" ||
                                    status === "accepted" ||
                                    status === "pending" ||
                                    vacancy?.requirement_no_done !== null
                                  }
                                >
                                  {props.user?.id &&
                                  localStorage.getItem("referal")
                                    ? "Internal Assessment"
                                    : status === "rejected" ||
                                      status === "accepted" ||
                                      status === "pending"
                                    ? t("JobVacancy.appliedAlready")
                                    : t("JobVacancy.applyNow")}
                                </Button>
                                <label
                                  style={{
                                    fontStyle: "italic",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {(props.user?.id && status === "rejected") ||
                                  status === "accepted" ||
                                  status === "pending"
                                    ? t("JobVacancy.appliedAlreadyDesc")
                                    : vacancy?.requirement_no_done !== null
                                    ? assessment_not_complete[
                                        vacancy?.requirement_no_done
                                      ]?.text
                                    : null}
                                  <br />
                                  {props.user?.id &&
                                  vacancy?.requirement_no_done !== null ? (
                                    <Link
                                      to={
                                        vacancy?.requirement_no_done !== null
                                          ? assessment_not_complete[
                                              vacancy?.requirement_no_done
                                            ]?.url
                                          : "#"
                                      }
                                      onClick={() =>
                                        dispatch(
                                          setPrevPath({
                                            url: props.location.pathname,
                                          })
                                        )
                                      }
                                    >
                                      {vacancy?.requirement_no_done ===
                                      "profile" ? (
                                        <b style={{ color: "red" }}>
                                          <u>{t("JobVacancy.completeNow")}</u>
                                        </b>
                                      ) : (
                                        <b style={{ color: "red" }}>
                                          <u>
                                            {t("JobVacancy.startNow") + " !"}
                                          </u>
                                        </b>
                                      )}
                                    </Link>
                                  ) : null}
                                </label>
                              </div>

                              {props.user?.id &&
                              !localStorage.getItem("referal") ? (
                                <Fragment>
                                  <hr />
                                  <Row>
                                    <Col xs="6">
                                      <div className="button-group">
                                        <Button
                                          className="button-detail-share"
                                          onClick={bookmarkStatus}
                                          disabled={loadingButton}
                                        >
                                          {loadingButton ? (
                                            <Spinner
                                              size="sm"
                                              className="mr-2"
                                            />
                                          ) : showBookmark ? (
                                            <span>
                                              <i className="fa fa-bookmark"></i>
                                            </span>
                                          ) : (
                                            <span>
                                              <i className="fa fa-bookmark-o"></i>
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col xs="6">
                                      <div className="button-group">
                                        <ButtonDropdown
                                          isOpen={dropdownOpen}
                                          toggle={toggle}
                                        >
                                          <DropdownToggle
                                            caret
                                            className="mb-1 button-detail-share"
                                          >
                                            <i className="fa fa-share-alt mr-2"></i>
                                          </DropdownToggle>
                                          <DropdownMenu className="mt-3">
                                            <DropdownItem
                                              style={{ border: "none" }}
                                            >
                                              <FacebookShareButton
                                                className="text-left"
                                                url={
                                                  process.env.REACT_APP_DOMAIN +
                                                  "/jobs/" +
                                                  id +
                                                  "/" +
                                                  vacancy.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .normalize("NFD")
                                                    .trim()
                                                    .replace(/\s+/g, "-") // eslint-disable-next-line
                                                    .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                                    .replace(/\-\-+/g, "-")
                                                }
                                                quote={
                                                  "Ada lowongan " +
                                                  vacancy.name +
                                                  " di " +
                                                  vacancy.company.name +
                                                  ". Ayo daftar sekarang !"
                                                }
                                              >
                                                <FacebookIcon
                                                  size={32}
                                                  round={true}
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                />
                                                <b>Facebook</b>
                                              </FacebookShareButton>{" "}
                                              <br />
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{ border: "none" }}
                                            >
                                              <TwitterShareButton
                                                className="text-left"
                                                url={
                                                  process.env.REACT_APP_DOMAIN +
                                                  "/jobs/" +
                                                  id +
                                                  "/" +
                                                  vacancy.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .normalize("NFD")
                                                    .trim()
                                                    .replace(/\s+/g, "-") // eslint-disable-next-line
                                                    .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                                    .replace(/\-\-+/g, "-")
                                                }
                                                title={
                                                  "Ada lowongan " +
                                                  vacancy.name +
                                                  " di " +
                                                  vacancy.company.name +
                                                  ". Ayo daftar sekarang !"
                                                }
                                              >
                                                <TwitterIcon
                                                  size={32}
                                                  round={true}
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                />
                                                <b>Twitter</b>
                                              </TwitterShareButton>{" "}
                                              <br />
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{ border: "none" }}
                                            >
                                              <WhatsappShareButton
                                                className="text-left"
                                                url={
                                                  process.env.REACT_APP_DOMAIN +
                                                  "/jobs/" +
                                                  id +
                                                  "/" +
                                                  vacancy.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .normalize("NFD")
                                                    .trim()
                                                    .replace(/\s+/g, "-") // eslint-disable-next-line
                                                    .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                                    .replace(/\-\-+/g, "-")
                                                }
                                                separator={
                                                  "Ada lowongan " +
                                                  vacancy.name +
                                                  " di " +
                                                  vacancy.company.name +
                                                  ". Ayo daftar sekarang !"
                                                }
                                              >
                                                <WhatsappIcon
                                                  size={32}
                                                  round={true}
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                />
                                                <b>WhatsApp</b>
                                              </WhatsappShareButton>{" "}
                                              <br />
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{ border: "none" }}
                                              onClick={diKlik}
                                            >
                                              <CopyToClipboard
                                                className="text-left"
                                                text={window.location.href}
                                              >
                                                <div>
                                                  <img
                                                    className="ml-1"
                                                    src={require("../../../../../assets/img/copy.png")}
                                                    alt=""
                                                    style={{ width: "25px" }}
                                                  />
                                                  <b
                                                    style={{
                                                      cursor: "pointer",
                                                      marginLeft: "20px",
                                                    }}
                                                  >
                                                    {name}
                                                  </b>
                                                </div>
                                              </CopyToClipboard>
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </ButtonDropdown>
                                      </div>
                                    </Col>
                                  </Row>
                                </Fragment>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                    <FooterLandingPage />
                  </Fragment>
                ) : (
                  <Fragment>
                    <NotFound />
                  </Fragment>
                )}
              </div>
            </DefaultBreadcumb>
          )}
        </>
      )}
    </Fragment>
  );
};

export default connect(({ user }) => ({ user }))(translate(JobDetail));

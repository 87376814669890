import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import FormikInput from "../../../../utils/FormikInput";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { login } from "../../../../config/Services/Actions/auth";
import { translate } from "react-switch-lang";
import "react-flags-select/css/react-flags-select.css";
import langUtils from "../../../../utils/SettingLanguage/index";
import "react-flags-select/scss/react-flags-select.scss";

toast.configure();
const Login = (props) => {
  const history = props.history;
  const pathPrev = props.location?.state?.prevPath;
  const [shownPass, setShownPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submits, setSubmits] = useState(false);
  const [state, setState] = useState({
    password: "",
  });
  const formValues = {
    email: "",
  };

  const handleChange = (e) => {
    e.preventDefault();
    setState({
      ...state,
      password: e.target.value,
    });
  };
  useEffect(() => {
    if (props.isLoading === false) {
      setSubmits(false);
      setDisabled(false);
    }
  }, [props.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
  const formValidate = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = t("General.cannotEmpty");
    }
    return errors;
  };
  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmits(true);
    setSubmitting(true);
    setDisabled(true);
    var email = values.email;
    var password = state.password;
    props.login({ email, password, pathPrev, history });
  };
  const showPass = () => {
    setShownPass(!shownPass);
  };
  const goToLink = () => {
    window.scrollTo(0, 0);
  };
  const { t } = props;
  const chooseLanguages = {
    ID: "Bahasa Indonesia",
    EN: "English (US)",
  };

  const onSelectFlag = (countryCode) => {
    handleSetLanguage(countryCode);
  };
  const handleSetLanguage = (key) => {
    langUtils.setLanguage(key);
  };
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="navtext justify-content-center">
          <Col md="4"></Col>
          <Col md="4">
            <Formik
              initialValues={formValues}
              validate={formValidate}
              onSubmit={formSubmit}
              render={({ isSubmitting }) => (
                <Form>
                  <div className="logo text-center mb-3">
                    <img
                      src={require("../../../../assets/img/logo.png")}
                      alt="logo"
                      style={{ width: 180 }}
                    />
                  </div>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="text"
                      label={"Email / " + t("General.phone")}
                      name="email"
                      // value={isSubmitting === true ? formValues.email : null}
                      id="email"
                      placeholder={"Email / " + t("General.phone")}
                      component={FormikInput}
                    />
                  </Col>

                  <Col sm="12" className="mb-0 p-0">
                    <label htmlFor="password" className="input-label">
                      Password
                    </label>
                    <div className="input-group">
                      <Input
                        type={shownPass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                      />
                      <div className="input-group-prepend" onClick={showPass}>
                        <span className="input-group-text show-pass">
                          {shownPass ? (
                            <i className="fa fa-eye "></i>
                          ) : (
                            <i className="fa fa-eye-slash "></i>
                          )}{" "}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <div className="mt-2">
                    <Link to="/forgot" style={{ color: "#dc3f46" }}>
                      <u>{t("Login.forgotPassword")}</u>
                    </Link>
                  </div>
                  <Button
                    type="submit"
                    className="btn btn-warning login-submit"
                    disabled={submits}
                    style={{ border: "none", fontWeight: "bold" }}
                  >
                    {submits ? <Spinner color="light" size="sm" /> : "Login"}
                  </Button>
                </Form>
              )}
            />
            <br />
            <Link
              className="d-flex justify-content-center"
              to="/register"
              style={{ textDecoration: "none" }}
            >
              <Button
                outline
                color="primary"
                style={{ width: "100%" }}
                disabled={disabled}
              >
                {t("Login.registerButton")}
              </Button>
            </Link>
            <br />
            <Link
              className="d-flex justify-content-center"
              to="/"
              onClick={goToLink}
            >
              <i
                className="fa fa-arrow-left mr-2"
                style={{ top: 2, position: "relative" }}
              ></i>
              {t("General.goToBack")} Home Page
            </Link>
            <br />
            <div className="mt-auto text-center">
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="light" caret>
                  {chooseLanguages[langUtils.getLanguage()]}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => onSelectFlag("ID")}>
                    Bahasa Indonesia
                  </DropdownItem>
                  <DropdownItem onClick={() => onSelectFlag("EN")}>
                    English (US)
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
          <Col md="4"></Col>
        </Row>
      </Container>
    </div>
  );
};
export default connect(({ isLoading }) => ({ isLoading }), {
  login,
})(translate(Login));

import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { translate, t } from "react-switch-lang";
import { isMobile } from "react-device-detect";
import ResultPremium from "../ResultPremium";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   TwitterIcon,
//   TwitterShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
// } from "react-share";
// import CopyToClipboard from "react-copy-to-clipboard";
// import request from "../../../../utils/Request";
// import { toast } from "react-toastify";
import { useSelector } from "react-redux";
export default translate(function ResultAssesmentBusinessInsight({
  result,
  paid,
  back,
  message,
}) {
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openKey, setOpenKey] = useState(false);
  const prevPath = useSelector((state) => state?.prevPath);

  const dataList = [
    {
      title: "Autonomy",
      text: result?.autonomy ?? null,
      rating: result?.star?.autonomy ?? 0,
      desc: t("Assessment.autonomy"),
      name: "autonomy",
    },
    {
      title: "Innovativeness",
      text: result?.innovativeness ?? null,
      rating: result?.star?.innovativeness ?? 0,
      desc: t("Assessment.innovativness"),
      name: "innovativeness",
    },
    {
      title: "Risk Taking",
      text: result?.risk_taking ?? null,
      rating: result?.star?.risk_taking ?? 0,
      desc: t("Assessment.risk"),
      name: "risk",
    },
    {
      title: "Proactiveness",
      text: result?.proactiveness ?? null,
      rating: result?.star?.proactiveness ?? 0,
      desc: t("Assessment.proactiveness"),
      name: "proactiveness",
    },
    {
      title: "Competitive Aggressiveness",
      text: result?.competitive_agresiveness ?? null,
      rating: result?.star?.competitive_agresiveness ?? 0,
      desc: t("Assessment.competitive"),
      name: "competitive",
    },
    {
      title: t("General.conclusionSuggestionTitle"),
      text1: result?.conclusion?.high + result?.conclusion?.low ?? null,
      text2: result?.suggestion?.high + result?.suggestion?.low ?? null,
      rating: 0,
      desc: t("General.conclusionSuggestion"),
      name: "conclusion",
    },
  ];

  // const [token, setToken] = useState(null);
  // useEffect(() => {
  //   request
  //     .get("/token/" + result?.id)
  //     .then((res) => {
  //       setToken(res.data);
  //     })
  //     .catch((err) => {});
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = () => setModal(!modal);

  function openDetail(key) {
    if (isMobile) {
      setOpenKey(key);
      setModal(true);
    } else {
      setOpen(true);
      setOpenKey(key);
    }
  }
  function closeDetail() {
    setOpenKey(false);
    setOpen(false);
  }

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // const diKlik = () => {
  //   toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  // };

  // const link = result?.conclusion
  //   ? process.env.REACT_APP_DOMAIN +
  //     "/share-assessment-business-insight/" +
  //     token
  //   : process.env.REACT_APP_DOMAIN;

  return (
    <Container>
      <div
        className="justify-content-center pb-4"
        style={{ paddingTop: "2rem" }}
      >
        <Row className="mb-4">
          <Col
            xs="6"
            sm="6"
            md="2"
            lg="2"
            xl="2"
            className="order-0 order-sm-0 order-md-0 order-lg-0 order-xl-0"
          >
            {back ? (
              <Link to={prevPath ? prevPath : "/assessment"}>
                <Button className="navtext btn btn-lg btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </Button>
              </Link>
            ) : null}
          </Col>
          <Col
            xs="12"
            sm="12"
            md="8"
            lg="8"
            xl="8"
            className="order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1"
          >
            <Row>
              <Col sm="2" />
              <Col sm="8">
                <Alert
                  color="success"
                  className="text-center mb-3"
                  style={{
                    width: "100%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <h5 className="content-title mb-2">
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.businessInsightTitle")}.
                  </h5>
                </Alert>
                <Col sm="2" />
              </Col>
            </Row>
          </Col>
          {/* <Col
            xs="6"
            sm="6"
            md="2"
            lg="2"
            xl="2"
            className="mb-3 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2"
          >
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              className="float-right"
            >
              <DropdownToggle
                caret
                className="navtext btn btn-lg btn-netis-color"
              >
                <i className="fa fa-share-alt mr-2"></i>
                {t("Assessment.share")}
              </DropdownToggle>
              <DropdownMenu
                className="mr-4 mt-3"
                style={{ marginLeft: "-120px" }}
              >
                <DropdownItem style={{ border: "none" }}>
                  <FacebookShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={link}
                    quote={`Halo, aku baru saja menyelesaikan Tes Kepemimpinan di Skillana ${
                      result?.conclusion ? link : ""
                    } Ayo kamu bisa ikutan juga loh!`}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Facebook</b>
                  </FacebookShareButton>{" "}
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <TwitterShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      result?.conclusion ? process.env.REACT_APP_DOMAIN : link
                    }
                    title={`Halo, aku baru saja menyelesaikan Tes Kepemimpinan di Skillana ${
                      result?.conclusion ? link : ""
                    } Ayo kamu bisa ikutan juga loh!`}
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Twitter</b>
                  </TwitterShareButton>
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <WhatsappShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      result?.conclusion ? process.env.REACT_APP_DOMAIN : link
                    }
                    title={`Halo, aku baru saja menyelesaikan Tes Kepemimpinan di Skillana ${
                      result?.conclusion ? link : ""
                    } Ayo kamu bisa ikutan juga loh!`}
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>WhatsApp</b>
                  </WhatsappShareButton>
                </DropdownItem>
                <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                  <CopyToClipboard
                    className="text-left ml-2"
                    text={
                      result?.conclusion ? link : process.env.REACT_APP_DOMAIN
                    }
                  >
                    <div>
                      <img
                        src={require("../../../../assets/img/copy.png")}
                        alt=""
                        style={{ width: "25px" }}
                      />
                      <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                        Copy Link
                      </b>
                    </div>
                  </CopyToClipboard>
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col> */}
        </Row>

        {paid ? (
          <Row>
            <Col xs="1" md="0" lg="1" />
            <Col xs="10" md="12" lg="10">
              <div className="text-center">
                <Container>
                  <h3 className="mb-5" style={{ fontWeight: "bold" }}>
                    {t("Assessment.businessInsightTestResult")}
                  </h3>
                  <div className="mb-5">
                    <img
                      src={require("../../../../assets/img/business_insight_done.png")}
                      width="70%"
                      alt="business insight done"
                    />
                  </div>
                  <Row>
                    {dataList.map((item, key) => {
                      return (
                        <>
                          <Col md="4" className="text-center pt-2 pb-2">
                            <div
                              className={
                                openKey === key
                                  ? "bg-open p-3"
                                  : "bg-no-open p-3"
                              }
                            >
                              <div
                                style={{
                                  border: "1px solid #F2C94C",
                                  boxSizing: "border-box",
                                  borderRadius: "12px",
                                  padding: "10px 5px",
                                  minHeight: "300px",
                                  backgroundColor: "#fff",
                                }}
                                className={
                                  openKey === key && !isMobile
                                    ? "cursor-pointer"
                                    : null
                                }
                                onClick={
                                  openKey === key && !isMobile
                                    ? closeDetail
                                    : null
                                }
                              >
                                <p className="text-right">
                                  <i
                                    id={"UncontrolledTooltip" + key}
                                    className="fa fa-question-circle mr-2"
                                    style={{
                                      position: "relative",
                                      top: "1px",
                                      color: "#1472BA",
                                      fontSize: "15px",
                                    }}
                                  />
                                </p>
                                <UncontrolledTooltip
                                  target={"UncontrolledTooltip" + key}
                                >
                                  {item?.desc}
                                </UncontrolledTooltip>
                                {openKey === key && !isMobile ? (
                                  <>
                                    <img
                                      src={require("../../../../assets/img/business/" +
                                        item?.name +
                                        ".png")}
                                      className="img-icon-agility"
                                      alt={item?.name}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={require("../../../../assets/img/business/" +
                                        item?.name +
                                        ".png")}
                                      height="90px"
                                      alt={item?.name}
                                    />

                                    <h6 className="mt-3 mb-2">{item?.title}</h6>
                                    {item.name === "conclusion" ? (
                                      <>
                                        <br />
                                        <br />
                                        <div className="p-1"></div>
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          fontSize: "15px",
                                        }}
                                        className="mb-2"
                                      >
                                        <StarRatingComponent
                                          name="star"
                                          starCount={5}
                                          value={item?.rating}
                                        />
                                      </div>
                                    )}
                                    <button
                                      className="btn btn-primary btn-skillana mb-3"
                                      onClick={() => openDetail(key)}
                                    >
                                      <b>{t("General.seeResult")}</b>
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                          {key === 2 ? (
                            <Col
                              sm="12"
                              className={
                                open && openKey < 3 && openKey >= 0
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <div
                                className="p-3 text-left mb-3"
                                style={{
                                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "12px",
                                  backgroundColor: "#F2F8FE",
                                }}
                              >
                                <h5
                                  className="mt-3 mr-2"
                                  style={{ float: "left" }}
                                >
                                  <b>{dataList[openKey]?.title}</b>
                                </h5>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <StarRatingComponent
                                    name="star"
                                    starCount={5}
                                    value={dataList[openKey]?.rating}
                                  />
                                </div>
                                <p>{dataList[openKey]?.text}</p>
                              </div>
                            </Col>
                          ) : null}
                          {key === 5 ? (
                            <Col
                              sm="12"
                              className={
                                open && openKey < 6 && openKey > 2
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <div
                                className="p-3 text-left mb-3"
                                style={{
                                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "12px",
                                  backgroundColor: "#F2F8FE",
                                }}
                              >
                                <h5
                                  className="mt-3 mr-2"
                                  style={{ float: "left" }}
                                >
                                  <b>{dataList[openKey]?.title}</b>
                                </h5>
                                {openKey !== 5 ? (
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <StarRatingComponent
                                      name="star"
                                      starCount={5}
                                      value={dataList[openKey]?.rating}
                                    />
                                  </div>
                                ) : (
                                  <br />
                                )}

                                {openKey !== 5 ? (
                                  <p> {dataList[openKey]?.text} </p>
                                ) : (
                                  <>
                                    <br />
                                    <br />
                                    <p>{dataList[openKey]?.text1}</p>
                                    <br />
                                    <p>{dataList[openKey]?.text2}</p>
                                  </>
                                )}
                              </div>
                            </Col>
                          ) : null}
                        </>
                      );
                    })}
                  </Row>
                </Container>
              </div>
              <br />
            </Col>
            <Col xs="1" md="0" lg="1" />
          </Row>
        ) : (
          <ResultPremium test="businessInsight" />
        )}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <h5 className="mt-3 mr-2" style={{ float: "left" }}>
              <b>{dataList[openKey]?.title}</b>
            </h5>

            {openKey !== 5 ? (
              <div
                style={{
                  fontSize: "15px",
                  marginTop: "15px",
                }}
              >
                <StarRatingComponent
                  name="star"
                  starCount={5}
                  value={dataList[openKey]?.rating}
                />
              </div>
            ) : null}
          </ModalHeader>
          <ModalBody>
            {openKey !== 5 ? (
              <p> {dataList[openKey]?.text} </p>
            ) : (
              <>
                <p>{dataList[openKey]?.text1}</p>

                <p>{dataList[openKey]?.text2}</p>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
});

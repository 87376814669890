import React, { useState } from 'react'
import { t, translate } from 'react-switch-lang'
import { Button, Col, Row } from 'reactstrap'
import ModalPayment from '../../Pages/Menu/Assessment/Premium/ModalPayment'
import ModalPremium from '../../Pages/Menu/Assessment/Premium/ModalPremium'

function ResultPremium({ test, noAsset }) {
  const [openPremium, setOpenPremium] = useState(false)
  const [payment, setPayment] = useState(false)
  const togglePayment = () => setPayment(!payment)

  return (
    <>
      <Row>
        <Col xs='12' className='text-center'>
          {!noAsset && <div className='success-premium text-center my-3'>
            <img src={require('../../../assets/assets_ari/premium_done.png')} alt='done' />
          </div>}
          <div className='mt-3 p-4 desc-card'>
            {t(test ?? 'Tes ini')}&nbsp;
            {t('adalah Tes Premium')},&nbsp;
            {t('premiumdesc')}<br />
            <Button color='yellow' className='mt-4 mb-1' onClick={() => setOpenPremium(true)}>{t('Lihat Detail')}</Button>
          </div>
        </Col>
      </Row>
      <ModalPremium isOpen={openPremium} toggle={() => setOpenPremium(!openPremium)} onHome={false} togglePayment={togglePayment} />
      <ModalPayment payment={payment} togglePayment={togglePayment} />
    </>
  )
}

export default translate(ResultPremium)
export const SET_LOADER = "SET_LOADER";
export const SET_USER = "SET_USER";
export const PREV_PATH = "PREV_PATH";

export const setLoader = ({ state }) => ({
  type: SET_LOADER,
  payload: state,
});

export const setUser = ({ user }) => ({
  type: SET_USER,
  payload: user,
});

export const setPrevPath = ({ url }) => ({
  type: PREV_PATH,
  payload: url,
});

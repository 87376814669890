import classnames from "classnames";
import React, { Fragment, useMemo, useState } from "react";
import { t, translate } from "react-switch-lang";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import useSWR from "swr";
import { ReactComponent as IconCrown } from "../../../../assets/img/icon-crown.svg";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import { convertToRupiah } from "../../../../utils/ConvertToRupiah";
import moment from "../../../../utils/Moment";
import langUtils from "../../../../utils/SettingLanguage/index";
import NoInvoice from "./NoInvoice";

function HistoryInvoices() {
  moment.locale(langUtils.getLanguage());
  const [activeTab, setActiveTab] = useState("1");
  const { data: responsePaid, error: errorPaid } = useSWR("invoice/paid");
  const { data: responseUnpaid, error: errorUnpaid } = useSWR("invoice/unpaid");
  const loading =
    !responsePaid && !responseUnpaid && !errorPaid && !errorUnpaid;
  const paidData = useMemo(() => {
    return responsePaid?.data?.data ?? [];
  }, [responsePaid]);
  const unpaidData = useMemo(() => {
    return responseUnpaid?.data?.data ?? [];
  }, [responseUnpaid]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <Fragment>
      <Container style={{ minHeight: "60vh" }}>
        <Nav className="navtext" tabs>
          <div style={{ display: "inherit" }}>
            <NavItem>
              <div className="step-aplikasi-1" style={{ display: "inherit" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {t("Invoices.notYetPaid")}
                  {unpaidData?.length ? (
                    <Badge color="danger" pill className="ml-2 px-2 py-1">
                      {unpaidData.length}
                    </Badge>
                  ) : null}
                </NavLink>
              </div>
            </NavItem>
            <div className="step-aplikasi-2" style={{ display: "inherit" }}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {t("Invoices.paidOff")}
                </NavLink>
              </NavItem>
            </div>
          </div>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {unpaidData?.length > 0 ? (
                  <InvoicesList
                    unPaid={true}
                    expired={false}
                    data={unpaidData}
                  />
                ) : (
                  <NoInvoice />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                {paidData?.length > 0 ? (
                  <InvoicesList
                    unPaid={false}
                    expired={false}
                    data={paidData}
                  />
                ) : (
                  <NoInvoice />
                )}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  );
}

const InvoicesList = ({ data, unPaid, expired }) => {
  const handleClick = (unPaid, url) => {
    if (unPaid) {
      window.open(url);
    }
  };

  return (
    <div className="app-list">
      {data.map((item, index) => (
        <Card
          key={index}
          style={{ borderRadius: "15px" }}
          className={unPaid ? "invoice-card" : ""}
          onClick={() => handleClick(unPaid, item?.url)}
        >
          <CardHeader
            style={{ backgroundColor: "transparent" }}
            className="py-2 d-flex flex-wrap justify-content-between"
          >
            <div className="text-date-invoice">
              {moment(item?.createdAt).format("DD MMMM YYYY LT")}
            </div>
            <div className={`text-paid-before ${expired ? `text-danger` : ``}`}>
              {expired
                ? t("Telah Kadaluarsa pada")
                : item.status === "pending"
                ? t("Invoices.payBefore")
                : t("Dibayar pada")}
              &nbsp;
              {item.status === "pending"
                ? moment(item.expiredAt).format("DD MMMM YYYY LT")
                : moment(item.paidAt).format("DD MMMM YYYY LT")}
            </div>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-wrap justify-content-start align-items-center">
              <div className="icon-bundle mr-3">
                <IconCrown />
              </div>
              <div className="d-flex flex-column">
                <span className="title-invoice">
                  {item?.modelId === 1
                    ? "Bundling Premium Skillana"
                    : item.modelId}
                </span>
                <span className="price-invoice">
                  {`${t("Invoices.totalPrice")} ${convertToRupiah(
                    item?.amount
                  )} `}
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default translate(HistoryInvoices);

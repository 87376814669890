import React, { useState } from "react";
import { connect } from "react-redux";
import { t, translate } from "react-switch-lang";
import { Button } from "reactstrap";
import UserHistory from "../../HistoryProfile/index.js";

const Riwayat = (props) => {
  const [editProfilePro, setEditProfilePro] = useState(false);

  const toggleEditProfilePro = () => {
    setEditProfilePro(!editProfilePro);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="content-sub-title mb-0">
          <b>{t("Profile.history")}</b>
        </h5>
        {editProfilePro === false ? (
          <Button
            style={{ backgroundColor: "#5AADF7" }}
            className="navtext btn-netis-color"
            onClick={toggleEditProfilePro}
          >
            <i className="fa fa-pencil" style={{ marginRight: 5 }}></i>
            Edit
          </Button>
        ) : (
          <Button className="btn-white" onClick={toggleEditProfilePro}>
            {t("General.cancel")}
          </Button>
        )}
      </div>
      <UserHistory editProfilePro={editProfilePro} />
    </>
  );
};

export default connect(({ user }) => ({ user }))(translate(Riwayat));

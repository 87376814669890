import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Col,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import NoData from "../../../../../assets/img/481.png";
import NoDataEn from "../../../../../assets/img/no_dataEn.png";
import langUtils from "../../../../../utils/SettingLanguage/index";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import request from "../../../../../utils/Request";
import { translate } from "react-switch-lang";
import ImagePdf from "../../../../../assets/img/pdf.png";
import { IsPdf } from "../../../../../utils/IsPdf";

toast.configure();

const DocumentProfile = (props) => {
  // eslint-disable-next-line
  const { t } = props;
  const [modalAddData, setModalAddData] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editDocument] = useState(false);
  const [modalEditData, setModalEditData] = useState(false);
  const [documentUpload, setDocumentUpload] = useState(null);
  const [documentUploadType, setDocumentUploadType] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [documentsTypes, setDocumentsTypes] = useState([]);
  const [modalDeleteData, setModalDeleteData] = useState(false);
  const [dataDelete, setDataDelete] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentExpire, setDocumentExpire] = useState("");
  const [editDocumentNumber, setEditDocumentNumber] = useState("");
  const [editDocumentImage, setEditDocumentImage] = useState(null);
  const [editDocumentExpire, setEditDocumentExpire] = useState(null);
  const [editDocumentType, setEditDocumentType] = useState({
    value: "",
    label: "",
  });
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });
  const [idEditData, setIdEditData] = useState(null);
  const [documentType, setDocumentType] = useState("");

  useEffect(() => {
    getData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const res1 = await request.get(`/master/document-types`);
      const res2 = await request.get("/users/document");
      let documentsTypes = [];
      let documentsType = {};
      const documents = res2.data.data;
      res1.data.data.map((data) => {
        documentsType = {
          value: data.id,
          label: t("Lists." + data.name),
        };
        return documentsTypes.push(documentsType);
      });
      setDocumentsTypes(documentsTypes);
      setDocuments(documents);
    } catch (err) {
      throw err;
    }
  };

  const modalDeleteDataa = (e) => {
    setModalDeleteData(!modalDeleteData);
    setDataDelete(e.target.value);
  };

  const deleteData = async () => {
    setLoadingButton(true);
    // setState({ loadingButton: true });
    try {
      await request.delete("/users/document/" + dataDelete);
      getData();
      setTimeout(() => {
        setModalDeleteData(!modalDeleteData);
        setLoadingButton(false);
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      setTimeout(() => {
        setLoadingButton(false);
        toast.error("Error", { autoClose: 3000 });
      }, 500);
    }
  };

  const modalEditDataa = (data) => {
    setModalEditData(!modalEditData);
    setEditDocumentExpire(data.expire);
    setEditDocumentType({
      value: data.type,
      label: t("Lists." + data.typeName),
    });
    setEditDocumentNumber(data.number);
    setEditDocumentImage(data.path);
    setIdEditData(data.id);
    setImage({
      preview: "",
      raw: "",
    });
  };
  const cancelModalEditDataa = () => {
    setModalEditData(!modalEditData);
    setImage({
      preview: "",
      raw: "",
    });
  };
  const modalAddDataa = () => {
    setDocumentExpire("");
    setDocumentNumber("");
    setModalAddData(!modalAddData);
    setImage({
      preview: "",
      raw: "",
    });
  };

  const onChangeDocumentType = (value) => {
    setDocumentType(value);
  };
  const onChangeEditDocumentType = (value) => {
    setEditDocumentType(value);
  };
  const onChangeDocument = (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      var preview = { ...image };
      preview["preview"] = URL.createObjectURL(e.target.files[0]);
      setDocumentUpload(e.target.files[0]);
      setDocumentUploadType(e.target.files[0].type);
      setImage(preview);
    }
  };
  const onChangeEditDocument = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      var preview = { ...image };
      preview["preview"] = URL.createObjectURL(e.target.files[0]);
      setEditDocumentImage(e.target.files[0]);
      setImage(preview);
    }
  };
  const handleSubmit = async (e) => {
    setLoadingButton(true);
    e.preventDefault();
    var formData = new FormData();
    if (image.preview === "") {
      toast.error(t("Profile.documentPhoto") + " " + t("General.cannotEmpty"));
      setLoadingButton(false);
    } else {
      formData.append("img", documentUpload, documentUpload.name);
      formData.append("type", documentType.value);
      formData.append("number", documentNumber);
      formData.append("expire", documentExpire);
      try {
        await request.post("/users/document", formData);
        getData();
        setTimeout(() => {
          setModalAddData(!modalAddData);
          setLoadingButton(false);
          toast.success(t("General.success"), { autoClose: 3000 });
        }, 500);
      } catch (err) {
        setTimeout(() => {
          setLoadingButton(false);
          if (
            err.response.data.errors ===
            "<p>The filetype you are attempting to upload is not allowed.</p>"
          ) {
            toast.error(t("Profile.typeFileNotValid"), {
              autoClose: 2000,
            });
          } else if (
            err.response.data.errors ===
            "<p>The file you are attempting to upload is larger than the permitted size.</p>"
          ) {
            toast.error(t("Profile.fileTooLarge"), { autoClose: 2000 });
          } else {
            toast.error(t("Profile.typeDocument") + t("General.cannotEmpty"), {
              autoClose: 3000,
            });
          }
        }, 500);
      }
    }
  };
  const handleEditSubmit = async (e, id) => {
    setLoadingButton(true);
    e.preventDefault();
    var formData = new FormData();
    if (image.preview === "") {
      formData.append("type", editDocumentType.value);
      formData.append("number", editDocumentNumber);
      formData.append("expire", editDocumentExpire);
    } else {
      formData.append("type", editDocumentType.value);
      formData.append("number", editDocumentNumber);
      formData.append("expire", editDocumentExpire);
      formData.append("img", editDocumentImage, editDocumentImage.name);
    }
    try {
      await request.post("/users/document/" + id, formData);
      setTimeout(() => {
        setModalEditData(!modalEditData);
        setImage({
          preview: "",
          raw: "",
        });
        setLoadingButton(false);
        getData();
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      setTimeout(() => {
        setLoadingButton(true);
        if (
          err.response.data.errors ===
          "<p>The filetype you are attempting to upload is not allowed.</p>"
        ) {
          toast.error(t("Profile.typeFileNotValid"), {
            autoClose: 2000,
          });
        } else if (
          err.response.data.errors ===
          "<p>The file you are attempting to upload is larger than the permitted size.</p>"
        ) {
          toast.error(t("Profile.fileTooLarge"), { autoClose: 2000 });
        } else {
          toast.error(JSON.stringify(err.response.data.errors), {
            autoClose: 3000,
          });
        }
      }, 500);
    }
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <div className="col-12 d-flex justify-content-between align-items-center mb-3">
          <h5 className="content-sub-title mb-0">
            <b>{t("Profile.identityDocument")}</b>
          </h5>
        </div>
      </Row>
      {documents.length > 0 ? (
        documents.map((data, idx) => {
          return (
            <div key={idx} className="content-body">
              <Row>
                <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                  <Col lg="8" sm="8" md="8">
                    <h5 className="content-sub-title mb-0">
                      {t("Lists." + data.typeName)}
                    </h5>
                  </Col>
                  <Col lg="4" sm="4" md="4" className="float-right">
                    {editDocument === false ? (
                      <Button
                        style={{
                          backgroundColor: "#f86c6b",
                          color: "white",
                          border: "none",
                        }}
                        size="sm"
                        className="mt-1 float-right"
                        value={data.id}
                        onClick={modalDeleteDataa}
                      >
                        <i className="fa fa-trash mr-2"></i>
                        {t("General.delete")}
                      </Button>
                    ) : null}

                    {editDocument === false ? (
                      <Button
                        style={{
                          backgroundColor: "#335877",
                          color: "white",
                          border: "none",
                        }}
                        size="sm"
                        className="mt-1 mr-2 float-right"
                        onClick={() => modalEditDataa(data)}
                      >
                        <i className="fa fa-pencil mr-2"></i>
                        {t("Edit")}
                      </Button>
                    ) : null}
                  </Col>
                </div>
              </Row>
              <Row>
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                  <div className="img-document">
                    {data.typeName === "Curriculum Vitae (CV)" ? null : IsPdf(
                        data.path
                      ) ? (
                      <>
                        <img
                          src={ImagePdf}
                          alt="pdf"
                          width="130"
                          height="130"
                        />
                        <br />
                        <Button
                          target="_blank"
                          rel="noopener noreferrer"
                          color="link"
                          href={data.path}
                        >
                          <i className="fa fa-file-pdf-o"></i>{" "}
                          {t("Profile.openAttachment")}
                        </Button>{" "}
                      </>
                    ) : (
                      <img
                        src={process.env.REACT_APP_DOMAIN + "" + data.path}
                        alt="Document"
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>
                {data.typeName === "Curriculum Vitae (CV)" ? (
                  <div style={{ marginRight: "22%" }}>
                    <img src={ImagePdf} alt="pdf" width="130" height="130" />
                    <br />
                    <Button
                      href={data.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="link"
                    >
                      <i className="fa fa-file-pdf-o"></i>{" "}
                      {t("Profile.openAttachment")}
                    </Button>{" "}
                  </div>
                ) : (
                  <div className="col-md-7 form-group mt-3">
                    <FormGroup>
                      <Label className="input-label">
                        {t("Profile.documentNumber")}
                      </Label>
                      <Input
                        type="number"
                        min={0}
                        disabled={editDocument === false ? true : false}
                        placeholder={t("Profile.documentNumber")}
                        value={data.number}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="input-label">
                        {t("Profile.validUntil")}
                      </Label>
                      <Input
                        type="text"
                        disabled={editDocument === false ? true : false}
                        placeholder={t("Profile.validUntil")}
                        value={
                          data.expire === null
                            ? t("Profile.lifetime")
                            : data.expire === "null"
                            ? t("Profile.lifetime")
                            : data.expire
                        }
                      />
                    </FormGroup>
                  </div>
                )}
              </Row>
              <Row>
                <div className="col-12 d-flex justify-content-end">
                  {editDocument === true ? (
                    <Fragment>
                      <Button
                        color="white"
                        className="btn-white mr-3"
                        // onClick={cancelEditDocument}
                      >
                        {t("General.cancel")}
                      </Button>
                      <Button color="netis-color" className="btn-netis-color">
                        {t("General.save")}
                      </Button>
                    </Fragment>
                  ) : null}
                </div>
              </Row>
            </div>
          );
        })
      ) : (
        <div className="content-body mb-0">
          <Row>
            <div className="col-12 d-flex justify-content-center mt-3 mb-3">
              {langUtils.getLanguage() === "ID" ? (
                <img src={NoData} alt="no-data" />
              ) : (
                <img src={NoDataEn} alt="no-data-en" />
              )}
            </div>
          </Row>
        </div>
      )}
      <Row>
        <div className="col-12 d-flex justify-content-center">
          <Button
            color="netis-color"
            className="button-add-doc"
            onClick={modalAddDataa}
          >
            <span className="fa fa-plus mr-2"></span>
            {t("General.add")} {t("General.document")}
          </Button>
        </div>
      </Row>
      <Modal
        isOpen={modalAddData}
        toggle={modalAddDataa}
        className={"modal-lg " + props.className}
      >
        <ModalBody>
          <h5 className="content-sub-title mb-4">
            {t("General.add")} {t("General.document")}
          </h5>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Row className="mb-4">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                {image.preview ? (
                  <Row>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <p>
                        {documentType.label === "Curriculum Vitae (CV)"
                          ? t("Profile.uploadFileFormat") +
                            " .pdf " +
                            t("General.maximum") +
                            " 5 Mb"
                          : t("Profile.uploadFileFormat") +
                            " .png, .jpg, .jpeg " +
                            t("General.maximum") +
                            " 5 Mb"}
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        {documentUploadType === "application/pdf" ? (
                          <img
                            src={ImagePdf}
                            alt="pdf"
                            width="130"
                            height="130"
                          />
                        ) : (
                          <img
                            src={image.preview}
                            alt="preview"
                            width="300"
                            height="300"
                          />
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        <Input
                          type="file"
                          id="document-img-upload"
                          name="document-img-upload"
                          onChange={onChangeDocument}
                        />
                      </FormGroup>
                    </div>
                  </Row>
                ) : (
                  <Row>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <p>
                        {documentType.label === "Curriculum Vitae (CV)"
                          ? t("Profile.uploadFileFormat") +
                            " .pdf " +
                            t("General.maximum") +
                            " 5 Mb"
                          : t("Profile.uploadFileFormat") +
                            " .png, .jpg, .jpeg " +
                            t("General.maximum") +
                            " 5 Mb"}
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <div className="input-personnel-document d-flex justify-content-center align-items-center">
                        <Label
                          htmlFor="document-img-upload"
                          className="input-label btn-circle"
                        >
                          <span className="fa fa-plus"></span>
                        </Label>
                        <Input
                          type="file"
                          id="document-img-upload"
                          name="document-img-upload"
                          className="d-none"
                          onChange={onChangeDocument}
                        />
                      </div>
                    </div>
                  </Row>
                )}
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="documentType" className="input-label">
                    {t("Profile.typeDocument")}
                  </Label>
                  <Select
                    value={documentType}
                    onChange={(value) => onChangeDocumentType(value)}
                    options={documentsTypes}
                    required
                  />
                </FormGroup>
                {documentType.label !== "Curriculum Vitae (CV)" ? (
                  <>
                    <FormGroup>
                      <Label htmlFor="documentNumber" className="input-label">
                        {t("Profile.documentNumber")}
                      </Label>
                      <Input
                        type="number"
                        min={0}
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                        id="documentNumber"
                        placeholder={t("Profile.documentNumber")}
                        required={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="documentExpire" className="input-label">
                        {t("Profile.validUntil")}
                      </Label>
                      <i
                        id="late"
                        className="fa fa-exclamation-circle text-info ml-2"
                      ></i>
                      <UncontrolledTooltip placement="top" target="late">
                        {t("Profile.emptyIfValid")}
                      </UncontrolledTooltip>
                      <Input
                        type="number"
                        name="documentExpire"
                        id="documentExpire"
                        min="1900"
                        max="2999"
                        value={documentExpire}
                        onChange={(e) => setDocumentExpire(e.target.value)}
                        // onChange={handleInputChange}
                        placeholder={t("Profile.validUntil")}
                        required={false}
                      />
                    </FormGroup>{" "}
                  </>
                ) : null}
              </div>
            </Row>
            <Row>
              <div className="col-12 d-flex justify-content-end">
                {loadingButton ? (
                  <Spinner color="dark" size="sm" />
                ) : (
                  <Fragment>
                    <Button
                      className="mr-2"
                      color="white"
                      onClick={modalAddDataa}
                    >
                      {t("General.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="netis-color"
                      style={{ width: "67px" }}
                    >
                      {t("General.save")}
                    </Button>
                  </Fragment>
                )}
              </div>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      {/* Modal Box Edit Data */}
      <Modal
        isOpen={modalEditData}
        toggle={modalEditDataa}
        className={"modal-lg " + props.className}
      >
        <ModalBody>
          <h5 className="content-sub-title mb-4">
            Edit {t("General.document")}
          </h5>
          <form onSubmit={(e) => handleEditSubmit(e, idEditData)}>
            <Row className="mb-4">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                {image.preview ? (
                  <Row>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <p>
                        {editDocumentType.label === "Curriculum Vitae (CV)"
                          ? t("Profile.uploadFileFormat") +
                            " .pdf " +
                            t("General.maximum") +
                            " 5 Mb"
                          : t("Profile.uploadFileFormat") +
                            " .png, .jpg, .jpeg " +
                            t("General.maximum") +
                            " 5 Mb"}
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        {documentUploadType === "application/pdf" ? (
                          <img
                            src={ImagePdf}
                            alt="pdf"
                            width="130"
                            height="130"
                          />
                        ) : (
                          <img
                            src={image.preview}
                            alt="dummy"
                            width="300"
                            height="300"
                          />
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        <Input
                          type="file"
                          id="edit-document-img-upload"
                          name="edit-document-img-upload"
                          onChange={onChangeEditDocument}
                        />
                      </FormGroup>
                    </div>
                  </Row>
                ) : (
                  <Row>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <p>
                        {editDocumentType.label === "Curriculum Vitae (CV)"
                          ? t("Profile.uploadFileFormat") +
                            " .pdf " +
                            t("General.maximum") +
                            " 5 Mb"
                          : t("Profile.uploadFileFormat") +
                            " .png, .jpg, .jpeg " +
                            t("General.maximum") +
                            " 5 Mb"}
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        {documentUploadType === "application/pdf" ? (
                          <img
                            src={ImagePdf}
                            alt="pdf"
                            width="130"
                            height="130"
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_DOMAIN +
                              "" +
                              editDocumentImage
                            }
                            alt="dummy"
                            width="300"
                            height="300"
                          />
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <FormGroup>
                        <Input
                          type="file"
                          id="edit-document-img-upload"
                          name="edit-document-img-upload"
                          onChange={onChangeEditDocument}
                        />
                      </FormGroup>
                    </div>
                  </Row>
                )}
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="documentType" className="input-label">
                    {t("Profile.typeDocument")}
                  </Label>
                  <Select
                    value={editDocumentType}
                    onChange={(value) => onChangeEditDocumentType(value)}
                    options={documentsTypes}
                  />
                </FormGroup>
                {editDocumentType.label !== "Curriculum Vitae (CV)" ? (
                  <>
                    <FormGroup>
                      <Label htmlFor="documentNumber" className="input-label">
                        {t("Profile.documentNumber")}
                      </Label>
                      <Input
                        type="number"
                        name="editDocumentNumber"
                        id="documentNumber"
                        value={editDocumentNumber}
                        // onChange={(value) => onChangeEditDocumentNumber(value)}
                        onChange={(e) => setEditDocumentNumber(e.target.value)}
                        // onChange={handleInputChange}
                        placeholder={t("Profile.documentNumber")}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="documentExpire" className="input-label">
                        {t("Profile.validUntil")}
                      </Label>
                      <i
                        id="late"
                        className="fa fa-exclamation-circle text-info ml-2"
                      ></i>
                      <UncontrolledTooltip placement="top" target="late">
                        {t("Profile.emptyIfValid")}
                      </UncontrolledTooltip>
                      <Input
                        type="number"
                        name="editDocumentExpire"
                        id="documentExpire"
                        min="1900"
                        max="2999"
                        value={editDocumentExpire}
                        // onChange={(value)=> setEditDocumentExpire(value)}
                        onChange={(e) => setEditDocumentExpire(e.target.value)}
                        // onChange={handleInputChange}
                        placeholder={t("Profile.validUntil")}
                      />
                    </FormGroup>
                  </>
                ) : null}
              </div>
            </Row>
            <Row>
              <div className="col-12 d-flex justify-content-end">
                {loadingButton ? (
                  <Spinner color="dark" size="sm" />
                ) : (
                  <Fragment>
                    <Button
                      className="mr-2"
                      color="white"
                      onClick={() => cancelModalEditDataa()}
                    >
                      {t("General.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="netis-color"
                      style={{ width: "67px" }}
                    >
                      {t("General.save")}
                    </Button>
                  </Fragment>
                )}
              </div>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      {/* Modal Box Delete Data */}
      <Modal
        isOpen={modalDeleteData}
        toggle={modalDeleteData}
        className="personnel-name"
      >
        <ModalBody>
          <h5 className="content-sub-title mb-5">
            {t("General.delete")} {t("General.document")}
          </h5>
          <Row className="mb-5">
            <div className="col-12 text-center">
              <h6>{t("Profile.notifForDelete")}</h6>
            </div>
          </Row>
          <Row>
            <div className="col-12 d-flex justify-content-end">
              {loadingButton ? (
                <Spinner color="dark" size="sm" />
              ) : (
                <Fragment>
                  <Button
                    className="mr-2"
                    color="white"
                    onClick={() => setModalDeleteData(!modalDeleteData)}
                  >
                    {t("General.cancel")}
                  </Button>
                  <Button
                    color="danger"
                    style={{ width: "67px" }}
                    onClick={deleteData}
                  >
                    {t("General.delete")}
                  </Button>
                </Fragment>
              )}
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionId: state.sessionId,
  };
};
export default connect(mapStateToProps)(translate(DocumentProfile));

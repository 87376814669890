import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "react-switch-lang";
import request from "../../../../utils/Request";
import requestGeneral from "../../../../utils/RequestGeneral";
import { LeftLargeRibbon, RibbonContainer } from "react-ribbons";
import {
  Row,
  Col,
  Container,
  CardDeck,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Alert,
  Button,
} from "reactstrap";
import JobPlacementPage from "../JobPlacement";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import InternalModal from "../../../../components/atoms/InternalModal";
import NoUserInternalModal from "../../../../components/atoms/NoUserInternalModal";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import { Link } from "react-router-dom";
import Colors from "../../../../utils/Colors";
import LoadingSkeleton from "../../../Templates/SkeletonLoading/Menu/Home";
import { toast } from "react-toastify";
import Internal from "./internal";
toast.configure();
const Home = (props) => {
  const { t } = props;
  const [expire, setExpire] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(true);
  const [requirement, setRequirement] = useState([]);
  const [modalSub, setModalSub] = useState(false);

  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
      .then((res) => {
        let reqs = res?.data?.data?.requirements;
        if (res?.data?.data?.companyPaid === "sub") {
          reqs.push("profile");
        }
        // console.log(reqs, "reqs");
        setRequirement(reqs);
        setCompany(res?.data?.data?.company_name);
        setJob(res?.data?.data?.name);
        setId(res?.data?.data?.id);
        requestGeneral
          .get(
            "internal/recruitment/internal/user/" +
            localStorage.getItem("referal") +
            "/" +
            res?.data?.data?.companyPaid
          )
          .then((res) => {
            if (!res?.data?.existSkillanaId && res?.data?.isSub) {
              setModal(true);
              setLoading(false);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.isSub) {
              setRequirement([]);
              setCompany(null);
              setJob(null);
              setId(null);
              localStorage.removeItem("referal");
              setModal(false);
              setModalSub(true);
              setLoading(false);
            } else {
              setModal(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setModal(false);
        request
          .get("/users/expire-assessment")
          .then((res) => {
            setExpire(true);
            setLoading(false);
          })
          .catch((err) => {
            setExpire(false);
            setLoading(false);
          });
      });
  }, []);
  function removeInternal() {
    localStorage.removeItem("referal");
    setModal(false);
  }
  function removeInternalSub() {
    localStorage.removeItem("referal");
    setModalSub(false);
  }
  function regisInternal() {
    setLoadingPopup(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/" + id)
      .then((res) => {
        setLoadingPopup(false);
        setModal(false);
        toast.success("Success");
      })
      .catch((err) => {
        setLoadingPopup(false);
        toast.error("Error");
      });
  }
  const menu = [
    {
      name: "profile",
      title: t("Dashboard.myprofile"),
      desc: t("Dashboard.descMyProfile"),
      image: "profile.png",
      link: "/profile",
      marginTop: "-19px",
      step: "step1-home",
    },
    {
      name: "assessment",
      title: t("Dashboard.myassessment"),
      desc: t("Dashboard.descMyAssessment"),
      image: "asesmenMbti.png",
      link: "/assessment",
      marginTop: "0px",
      step: "step2-home",
    },
    {
      name: "lamaran",
      title: t("Dashboard.myapplicant"),
      desc: t("Dashboard.descMyApplicant"),
      image: "lamaranku.png",
      link: "/aplikasi-lamaran",
      marginTop: "-19px",
      step: "step3-home",
    },
    // {
    //   name: "video-cv",
    //   title: t("Dashboard.myVideoCv"),
    //   desc: t("Dashboard.descMyVideoCv"),
    //   image: "video.png",
    //   link: "/interview-online",
    //   marginTop: "0px",
    //   step: "step4-home",
    // },
  ];
  function logoutInternal() {
    localStorage.removeItem("referal");
    window.location.reload();
  }

  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }
  return (
    <div className="animated fadeIn">
      <div className="content-body">
        {loading ? (
          <div className="pt-5 pb-5">
            <LoadingSkeleton />
          </div>
        ) : (
          <Container fluid className="container-fluid-home">
            <NoUserInternalModal
              isOpen={modalSub}
              onRight={removeInternalSub}
            />
            {localStorage.getItem("referal") ? (
              <InternalModal
                isOpen={modal}
                company={company}
                job={job}
                onLeft={removeInternal}
                onRight={regisInternal}
              />
            ) : null}
            <Row>
              <Col md="12" className="menu ">
                {localStorage.getItem("referal") ? (
                  <div className="text-center">
                    <Alert color="info" className="text-center mt-3 mb-4">
                      <h5 className="content-title mb-2">
                        {t("Internal.alert1")} {job} {t("Internal.alert2")}{" "}
                        {company}
                      </h5>
                      <br />
                      <Button
                        className="btn button-outline-red"
                        onClick={logoutInternal}
                      >
                        <i className="fa fa-sign-out mr-2"></i>{" "}
                        {t("Internal.logout")} {job}
                      </Button>
                    </Alert>
                  </div>
                ) : null}
                {!loading && localStorage.getItem("referal") && !modal ? (
                  <Internal requirement={requirement} />
                ) : (
                  <CardDeck className="all-menu">
                    <div style={{ display: "inherit" }}>
                      <Row className="d-flex justify-content-center">
                        {menu?.map((data, idx) => {
                          return (
                            <Col
                              lg={3}
                              md={6}
                              sm={6}
                              xs={6}
                              className={"p-0 mt-4 mb-3 " + data.step}
                              key={idx}
                            >
                              <Link
                                to={
                                  props.user.guideHome === 0 ? "#" : data.link
                                }
                                className="card menu-item h-100 pb-2 ml-2"
                                style={{ width: "90%" }}
                              >
                                <RibbonContainer className="custom-class">
                                  {!localStorage.getItem("referal") ? (
                                    data.name === "assessment" ? (
                                      expire ? (
                                        <LeftLargeRibbon
                                          backgroundColor="#cc0000"
                                          color="#fff"
                                        >
                                          <b>{t("Dashboard.expire")}</b>
                                        </LeftLargeRibbon>
                                      ) : null
                                    ) : null
                                  ) : null}

                                  <CardImg
                                    top
                                    width="86%"
                                    src={require("../../../../assets/img/" +
                                      data.image)}
                                    alt="Card image cap"
                                    className="p-3"
                                  />

                                  <CardBody>
                                    <CardTitle
                                      className="mb-2 title-menu-company menu-title"
                                      style={{
                                        color: Colors.primaryColor,
                                        marginTop: data.marginTop,
                                      }}
                                    >
                                      <h4
                                        className="font-weight-bold"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {data.title}
                                      </h4>
                                    </CardTitle>
                                    <CardText
                                      className="navtext d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {data.desc}
                                    </CardText>
                                  </CardBody>
                                </RibbonContainer>
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </CardDeck>
                )}
                {!localStorage.getItem("referal") ? (
                  <div className="pt-1 step-job-menu">
                    <Row className="list-job-placement">
                      <>
                        <h1
                          style={{ fontSize: "28px" }}
                          id="list-job-placement"
                        >
                          <b>{t("Dashboard.jobVacancy")}</b>
                        </h1>
                        <JobPlacementPage />
                      </>
                    </Row>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <FooterLandingPage />
    </div>
  );
};

export default connect(({ user }) => ({ user }))(translate(Home));

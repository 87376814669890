import React, { Fragment } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import AuthRoute from "../../config/AuthRoute";
//Route
//Auth
import AuthContainer from "../../containers/Pages/Auth/AuthContainer";
import LoginPage from "../../containers/Pages/Auth/Login";
import ForgotPassword from "../../containers/Pages/Auth/ForgotPassword";
import ResetPassword from "../../containers/Pages/Auth/ResetPassword";
import RegisterPage from "../../containers/Pages/Auth/Register";
import VerifRegisterPage from "../../containers/Pages/Auth/VerifRegister";
import InternalAssessment from "../../containers/Pages/Auth/InternalAssessment";
//NotFound Page
import NotFound from "../../containers/Pages/NotFound";
//Menu
import DefaultLayout from "../../containers/Pages/Menu/DefaultLayout";
import DefaultLayoutFullScreen from "../../containers/Pages/Menu/DefaultLayout/DefaultFullScreen";
import GeneralLayout from "../../containers/Pages/Menu/DefaultLayout/GeneralLayout";
import HomeLayout from "../../containers/Pages/Menu/DefaultLayout/HomeLayout";
import Profile from "../../containers/Pages/Menu/Profile";
import LiveChat from "../../containers/Pages/Menu/LiveChat";
import Home from "../../containers/Pages/Menu/Home";
import Interview from "../../containers/Pages/Menu/InterviewOnline";
import HistoryInvoices from "../../containers/Pages/Menu/HistoryInvoices";
// import ChatKonsultan from "../../containers/Pages/Menu/ChatKonsultan";
import Assessment from "../../containers/Pages/Menu/ValidateAssessment";
import AssessmentMBTI from "../../containers/Pages/Menu/Assessment/Mbti";
import AssessmentPapikostik from "../../containers/Pages/Menu/Assessment/Papikostik";
import AssessmentDisc from "../../containers/Pages/Menu/Assessment/Disc";
import AssessmentFingerprint from "../../containers/Pages/Menu/Assessment/Fingerprint";
import AssessmentMsdt from "../../containers/Pages/Menu/Assessment/Msdt";
import AssessmentAgility from "../../containers/Pages/Menu/Assessment/Agility";
import AssessmentBusinessInsight from "../../containers/Pages/Menu/Assessment/BusinessInsight";
// import AssessmentSpm from "../../containers/Pages/Menu/Assessment/Spm";
// import AuthApplicant from "../../containers/Pages/Menu/JobPlacement/AuthApplicant";
import AuthInterview from "../../containers/Pages/Menu/InterviewOnline/AuthInterview";
import DefaultBreadcumb from "../../containers/Pages/Menu/DefaultLayout/DefaultBreadcumb";
//LandingPage
import LandingPagePersonalityMbti from "../../containers/Pages/LandingPage/AssessmentMbti/PersonalityMbti.js";
import LandingPagePersonalityMbtiDetail from "../../containers/Pages/LandingPage/AssessmentMbti/PersonalityMbtiDetail";
import LandingPageAbout from "../../containers/Pages/LandingPage/About";
import LandingPageArtikel from "../../containers/Pages/LandingPage/Artikel";
import LandingPagePrivacyPolicy from "../../containers/Pages/LandingPage/PrivacyPolicy";
import LandingPageTermsCondition from "../../containers/Pages/LandingPage/TermsCondition";
import LandingPageFaq from "../../containers/Pages/LandingPage/Faq";
import LandingPageHome from "../../containers/Pages/LandingPage/Home";
import LandingPageContainer from "../../containers/Pages/LandingPage";
import LandingPageLayoutAuth from "../../containers/Pages/LandingPage/LayoutWithAuth";
import LandingPageTypeAsesmen from "../../containers/Templates/LandingPage/ContentTypeAsesmen";
import LandingPageVideoCV from "../../containers/Templates/LandingPage/ContentVideoCV";
import VideoWebCamLandingPage from "../../containers/Templates/LandingPage/VideoWebCam/auth";
//Job Placement
import JobPlacement from "../../containers/Pages/LandingPage/JobPlacement";
import JobDetail from "../../containers/Pages/Menu/JobPlacement/Detail";

//  Promotion Video CV
import PromotionVideoCV from "../../containers/Pages/LandingPage/PromotionVideoCV";

import AplikasiLamaran from "../../containers/Pages/Menu/AplikasiLamaran";
//Shareable MBTI
import ShareableMBTI from "../../containers/Templates/ShareableMBTI";
//Shareable Papikostick
import ShareablePapikostick from "../../containers/Templates/ShareablePapikostik";
//Shareable DISC
import ShareableDisc from "../../containers/Templates/ShareableDisc";
//Shareable Fingerprint
import ShareableFingerprint from "../../containers/Templates/ShareableFingerprint";
//Shareable Msdt
import ShareableMsdt from "../../containers/Templates/ShareableMsdt";
//Shareable Business Insight
import ShareableBusinessInsight from "../../containers/Templates/ShareableBusinessInsight";

import CompanyProfile from "../../containers/Pages/Menu/CompanyProfile";
// import Maintenance from "../../containers/Pages/Maintenance";

const Route = (props) => {
  return (
    <Fragment>
      <Router>
        <Switch>
          {/* LandingPage */}
          <AuthRoute path="/" exact>
            <LandingPageContainer
              component={LandingPageHome}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/type-personality" exact>
            <LandingPageContainer
              component={LandingPagePersonalityMbti}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/type-asesmen" exact>
            <LandingPageContainer
              component={LandingPageTypeAsesmen}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/type-personality/:id" exact>
            <LandingPageContainer
              component={LandingPagePersonalityMbtiDetail}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/about" exact>
            <LandingPageContainer
              component={LandingPageAbout}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/artikel" exact>
            <LandingPageContainer
              component={LandingPageArtikel}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/privacy-policy" exact>
            <LandingPageContainer
              component={LandingPagePrivacyPolicy}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/terms-condition" exact>
            <LandingPageContainer
              component={LandingPageTermsCondition}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/faq" exact>
            <LandingPageContainer
              component={LandingPageFaq}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/video-cv" exact>
            <LandingPageContainer
              component={LandingPageVideoCV}
            ></LandingPageContainer>
          </AuthRoute>
          <AuthRoute path="/video-webcam-cv" exact>
            <DefaultLayoutFullScreen component={VideoWebCamLandingPage} />
          </AuthRoute>

          {/* Job Placement */}
          <AuthRoute path="/jobplacement" exact>
            <LandingPageLayoutAuth
              component={JobPlacement}
            ></LandingPageLayoutAuth>
          </AuthRoute>

          {/* !! INI MEMANG DI COMMENT */}
          {/* <LandingPageContainer path="/jobplacement/:id" exact component={JobPlacementDetail}></LandingPageContainer> */}

          {/* Promotion Video CV */}
          <AuthRoute path="/promotion-video-cv" exact>
            <LandingPageLayoutAuth component={PromotionVideoCV} />
          </AuthRoute>

          {/* Auth */}
          <AuthRoute path="/login" type="guest" exact>
            <AuthContainer component={LoginPage} />
          </AuthRoute>
          <AuthRoute path="/register" type="guest" exact>
            <AuthContainer component={RegisterPage} />
          </AuthRoute>
          <AuthRoute path="/verify/:token" type="guest" exact>
            <AuthContainer component={VerifRegisterPage} />
          </AuthRoute>
          <AuthRoute path="/forgot" type="guest" exact>
            <AuthContainer component={ForgotPassword} />
          </AuthRoute>
          <AuthRoute path="/reset/:token" type="guest" exact>
            <AuthContainer component={ResetPassword} />
          </AuthRoute>
          <AuthRoute path="/assessment/:referalId" exact>
            <AuthContainer component={InternalAssessment} />
          </AuthRoute>
          {/* Menu */}
          <AuthRoute path="/home" type="private" exact>
            <HomeLayout component={Home} />
          </AuthRoute>
          <AuthRoute path="/live-chat" type="private" exact>
            <GeneralLayout component={LiveChat} />
          </AuthRoute>
          <AuthRoute path="/assessment" type="private" exact>
            <DefaultLayoutFullScreen component={Assessment} />
          </AuthRoute>
          <AuthRoute path="/interview-online" type="private" exact>
            <GeneralLayout component={Interview} />
          </AuthRoute>
          <AuthRoute path="/profile" type="private" exact>
            <DefaultLayout component={Profile} />
          </AuthRoute>
          <AuthRoute path="/assessment-mbti" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentMBTI} />
          </AuthRoute>
          <AuthRoute path="/assessment-papikostik" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentPapikostik} />
          </AuthRoute>
          <Redirect
            from="/assessment-disc-2"
            to={{
              pathname: "/assessment-disc",
              state: { redirectSpm: true },
            }}
          />
          <AuthRoute path="/assessment-disc" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentDisc} />
          </AuthRoute>
          <AuthRoute path="/assessment-msdt" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentMsdt} />
          </AuthRoute>

          {/* INI MEMANG DI COMMENT */}
          {/* <AuthRoute path="/assessment-spm" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentSpm} />
          </AuthRoute> */}

          <AuthRoute path="/assessment-fingerprint" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentFingerprint} />
          </AuthRoute>
          <AuthRoute path="/assessment-agility" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentAgility} />
          </AuthRoute>
          <AuthRoute path="/assessment-business-insight" type="private" exact>
            <DefaultLayoutFullScreen component={AssessmentBusinessInsight} />
          </AuthRoute>

          {/* INI MEMANG DI COMMENT */}
          {/* <AuthRoute path="/assessment-interview/:id" type="private" exact>
            <DefaultLayoutFullScreen component={AuthApplicant} />
          </AuthRoute> */}

          <AuthRoute path="/assessment-video" type="private" exact>
            <DefaultLayoutFullScreen component={AuthInterview} />
          </AuthRoute>

          <AuthRoute path="/jobs/:id/:slug" type="general" exact>
            <DefaultLayoutFullScreen component={JobDetail} />
          </AuthRoute>
          <AuthRoute path="/company-profile/:id/:slug" type="general" exact>
            <DefaultBreadcumb component={CompanyProfile} />
          </AuthRoute>
          <AuthRoute path="/aplikasi-lamaran" type="private" exact>
            <DefaultBreadcumb component={AplikasiLamaran} />
          </AuthRoute>
          <AuthRoute path="/invoices" type="private" exact>
            <GeneralLayout component={HistoryInvoices} />
          </AuthRoute>

          {/* Shareable Assesment Result */}
          <AuthRoute path="/share-assessment-mbti/:resultCodes" exact>
            <DefaultLayoutFullScreen component={ShareableMBTI} />
          </AuthRoute>
          <AuthRoute path="/share-assessment-papikostick/:resultCodes" exact>
            <DefaultLayoutFullScreen component={ShareablePapikostick} />
          </AuthRoute>
          <AuthRoute path="/share-assessment-disc/:resultCodes" exact>
            <DefaultLayoutFullScreen component={ShareableDisc} />
          </AuthRoute>
          <AuthRoute path="/share-assessment-fingerprint/:resultCodes" exact>
            <DefaultLayoutFullScreen component={ShareableFingerprint} />
          </AuthRoute>
          <AuthRoute path="/share-assessment-msdt/:resultCodes" exact>
            <DefaultLayoutFullScreen component={ShareableMsdt} />
          </AuthRoute>
          <AuthRoute
            path="/share-assessment-business-insight/:resultCodes"
            exact
          >
            <DefaultLayoutFullScreen component={ShareableBusinessInsight} />
          </AuthRoute>

          <NotFound component={NotFound} />
          {/* <Maintenance component={Maintenance} /> */}
        </Switch>
      </Router>
    </Fragment>
  );
};
export default Route;

import React, { useState, useEffect } from "react";
import { translate, t } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { convertToRupiah } from "../../../../../utils/ConvertToRupiah";
import request from "../../../../../utils/Request";

function ModalPayment({ payment, togglePayment }) {
  const [code, setCode] = useState("");
  const [voucher, setVoucher] = useState(null);
  const [disc, setDisc] = useState(null);
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const toggleConfirm = () => {
    setOpenConfirm((prev) => !prev);
  };

  useEffect(() => {
    if (!code) {
      setVoucher(null);
    }
  }, [code]);

  const handleCode = () => {
    let value = code?.toUpperCase() ?? "";
    setLoadingVoucher(true);
    request
      .post("voucher", { voucherCode: value })
      .then((res) => {
        setDisc(res.data.data);
        setVoucher("success");
      })
      .catch(() => {
        setDisc(null);
        setVoucher("error");
      })
      .finally(() => setLoadingVoucher(false));
  };

  const handleBuy = () => {
    setLoadingBuy(true);
    request
      .post("invoice", {
        model: "assessment-package",
        modelId: 1,
        voucherCode: disc?.code,
      })
      .then((res) => {
        if (res.data.url) {
          // toast.success(t(res.data?.message ?? "Success"));
          toast.success(
            t("Invoice sudah dibuat, silakan lanjutkan pembayaran di xendit", {
              autoClose: 20000,
            })
          );
          // window.location.replace(res.data.url);
          window.open(res.data.url, "_blank");
          toggleConfirm();
          togglePayment();
        }
      })
      .catch(() => {
        toast.error(t("Terjadi Kesalahan, silahkan coba lagi"));
        return;
      })
      .finally(() => setLoadingBuy(false));
  };

  return (
    <>
      <Modal isOpen={payment} size="lg">
        <ModalBody>
          <h3 style={{ fontWeight: 600 }}>Premium</h3>
          <Row className="mt-3">
            <Col xs="9" className="mb-2">
              {t("Subtotal Harga")}
            </Col>
            <Col xs="3" className="text-center mb-2 text-nowrap">
              {convertToRupiah(79000)}
            </Col>
            <Col xs="9" className="my-2">
              <Label htmlFor="code">
                <b>{t("Pakai Kode Voucher")}</b>
              </Label>
              <Input
                name="code"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              {voucher === "success" ? (
                <small className="text-success">
                  {t("Kode Voucher berhasil digunakan")}
                </small>
              ) : voucher === "error" ? (
                <small className="text-danger">
                  {t(
                    "Kode Voucher tidak valid atau sudah mencapai limit penggunaan"
                  )}
                </small>
              ) : null}
            </Col>
            <Col xs="3" className="text-center my-2">
              <Label className="text-white">&nbsp;</Label>
              <Button
                color="info"
                className="text-white w-100"
                disabled={!code || loadingVoucher}
                onClick={handleCode}
              >
                {loadingVoucher ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  t("Pakai")
                )}
              </Button>
            </Col>
            {disc && (
              <>
                <Col xs="9" className="my-2">
                  <img
                    src={require("../../../../../assets/img/premium/voucher.png")}
                    alt="voucher"
                    className="mr-2 mb-1"
                    width={20}
                  />
                  {disc?.name}
                </Col>
                <Col
                  xs="3"
                  className="text-center my-2 text-danger text-nowrap"
                >
                  <b>{"- " + convertToRupiah(disc?.amount ?? 0)}</b>
                </Col>
              </>
            )}
          </Row>
          <hr />
          <Row>
            <Col xs="9">{t("Total Pembayaran")}</Col>
            <Col xs="3" className="text-center text-nowrap">
              {convertToRupiah(79000 - (disc?.amount ?? 0))}
            </Col>
          </Row>
          <Row className="my-4">
            <Col xs="6" className="px-2">
              <Button
                outline
                color="info"
                disabled={loadingBuy}
                className="w-100"
                onClick={togglePayment}
              >
                {t("Batal")}
              </Button>
            </Col>
            <Col xs="6" className="px-2">
              <Button
                color="info"
                disabled={loadingBuy}
                onClick={handleBuy}
                className="w-100 text-white"
              >
                {loadingBuy ? <Spinner size="sm" color="light" /> : t("Bayar")}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <ConfirmModal isOpen={openConfirm} toggle={toggleConfirm} />
    </>
  );
}

const ConfirmModal = ({ isOpen, toggle }) => {
  const handleRefreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <h3 style={{ fontWeight: 600 }}>Premium</h3>
          <div className="d-flex justify-content-start mt-3">
            <h5>
              {t(
                "Jika kamu sudah melakukan pembayaran silakan muat ulang halaman ini"
              )}
            </h5>
          </div>
          <div className="d-flex mt-3">
            <Button
              color="info"
              className="w-100 text-white"
              onClick={handleRefreshPage}
            >
              {t("Muat Ulang")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default translate(ModalPayment);

import React from "react";
import { Spinner } from "reactstrap";
const LoadingAnimation = () => {
  return (
    <div className="text-center py-3 w-100">
      <Spinner size='lg' className='mb-2' />
      <h2>Loading</h2>
    </div>
  );
};

export default LoadingAnimation;

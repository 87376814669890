import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";

toast.configure();
function Sadana(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [answers, setAnswers] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [error, setError] = useState(false);
  const [notCompleteAnswer, setNotCompleteAnswer] = useState(false);
  const [errorInput, setErrorInput] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [currAnswer, setCurrAnswer] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
  });
  const [currAnswer2, setCurrAnswer2] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
  });
  const [notClick, setNotClick] = useState({
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
  });
  const [notClick2, setNotClick2] = useState({
    202: false,
    203: false,
    302: false,
    303: false,
  });

  let dataAnswer = ["A", "B", "C", "D", "E"];
  let dataAnswer2 = ["202", "203", "302", "303"];

  useEffect(() => {
    request
      .get("/assessment/test/agility/sadana")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
          let code = [];
          res.data.data[page].answers.map((data) => {
            code[data.code] = null;
            return true;
          });
          setAnswers(code);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (page > 0) {
      questions[page].answers.map((data) => {
        setAnswers((state) => ({ ...state, [data.code]: null }));
        return true;
      });
      setBtnDisable(true);
    } // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(answers).length > 0) {
      let complete = 1;
      // eslint-disable-next-line
      Object.values(answers).map((data) => {
        if (data === null) {
          complete = 0;
        }
      });
      if (complete === 1) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    } else {
      setBtnDisable(true);
    }
  }, [answers]);
  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);

  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }

  function isNotComplete() {
    if (notCompleteAnswer) {
      return (
        <AffirmationModal
          isOpen={notCompleteAnswer}
          title={"Oops !"}
          desc={t("Agility.notComplete")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setNotCompleteAnswer(true);
          }}
          confirmation={() => {
            setNotCompleteAnswer(false);
          }}
        />
      );
    }
  }
  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeSadana")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              sadana: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }

  function changeInput(e, code) {
    let values = e.target.value.toLowerCase();
    if (page === 0) {
      if (
        values === "a" ||
        values === "b" ||
        values === "c" ||
        values === "d" ||
        values === "e"
      ) {
        let exist = 0;
        // eslint-disable-next-line
        Object.values(answers).map((data) => {
          if (data === values) {
            exist = 1;
          }
        });
        if (exist === 1) {
          // setErrorInput(code);
          setBtnDisable(true);
        } else {
          // if (errorInput === code) {
          //   setErrorInput(null);
          // }
          if (values === null) {
            values = null;
            setBtnDisable(true);
          }
          setAnswers((state) => ({ ...state, [code]: values }));
        }
      } else {
        // setErrorInput(code);
        setBtnDisable(true);
      }
    } else {
      if (
        values === "302" ||
        values === "303" ||
        values === "202" ||
        values === "203"
      ) {
        let exist = 0;
        // eslint-disable-next-line
        Object.values(answers).map((data) => {
          if (data === values) {
            exist = 1;
          }
        });
        if (exist === 1) {
          setErrorInput(code);
          setBtnDisable(true);
        } else {
          // if (errorInput === code) {
          //   setErrorInput(null);
          // }
          if (values === "") {
            values = null;
            setBtnDisable(true);
          }
          setAnswers((state) => ({ ...state, [code]: values }));
        }
      } else {
        values = null;
        setAnswers((state) => ({ ...state, [code]: values }));
        // setErrorInput(code);
        setBtnDisable(true);
      }
    }
  }

  function nextPage() {
    let complete = 1;
    window.scroll({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line
    Object.values(answers).map((data) => {
      if (data === null) {
        complete = 0;
      }
    });
    if (complete === 0) {
      setNotCompleteAnswer(true);
    } else {
      setTime((state) => ({
        ...state,
        [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
          startTime,
          "seconds"
        ),
      }));
      if (page === 0) {
        setPage(page + 1);
        setPageTitle(pageTitle + 1);
      } else {
        setCompleted(true);
      }
    }
  }
  // console.log(time);
  return (
    <Container>
      {isError()}
      {isNotComplete()}
      {isComplete()}
      <Row>
        <Col xs="0" md="1"></Col>
        <Col xs="12" md="10" className=" mt-4 mb-4">
          <h3>
            <b>
              {props.title} ( {pageTitle} / 2 )
            </b>
          </h3>
          <div
            className=" p-5"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            <Form>
              <Row>
                {loading ? (
                  <Col sm="12" className="text-center">
                    <Spinner size="lg" />
                  </Col>
                ) : questions ? (
                  questions[page] ? (
                    <>
                      <Col sm="12" className="text-center mb-3">
                        <img
                          src={
                            process.env.REACT_APP_DOMAIN +
                            "/" +
                            questions[page].imageQuestion
                          }
                          alt="guide"
                          className="sadana-question-image"
                        />
                      </Col>
                      <Col sm="12" className="mb-5">
                        <p>{questions[page].textQuestion}</p>
                      </Col>
                      {questions[page].answers.map((data, idx) => {
                        return (
                          <div
                            className={
                              questions[page].answers.length === 5
                                ? "col-sm-5ths text-center mb-4 grid-container"
                                : "col-sm-3 text-center mb-4"
                            }
                            key={idx}
                          >
                            <p style={{ fontSize: "10px", minHeight: "30px" }}>
                              <b>{data.text}</b>
                            </p>
                            <img
                              src={
                                process.env.REACT_APP_DOMAIN + "/" + data.image
                              }
                              alt={data.text}
                              height={
                                questions[page].answers.length === 5
                                  ? "178px"
                                  : "140px"
                              }
                            />

                            {/* <Input
                              type="text"
                              id={data.code}
                              name={data.code}
                              autoComplete="off"
                              style={{
                                width: "50px",
                                padding: 0,
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                border: "2px solid #5AADF7",
                                textTransform: "capitalize",
                              }}
                              maxLength={
                                questions[page].answers.length === 5 ? 1 : 3
                              }
                              onChange={(e) => changeInput(e, data.code)}
                              disabled={
                                errorInput ? errorInput !== data.code : false
                              }
                            /> */}

                            <UncontrolledDropdown
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <DropdownToggle
                                className="answerDropDownToggle"
                                caret
                                disabled={
                                  page === 0
                                    ? currAnswer[idx] !== null
                                    : currAnswer2[idx] !== null
                                }
                              >
                                {page === 0
                                  ? currAnswer[idx]
                                  : currAnswer2[idx]}
                              </DropdownToggle>
                              <DropdownMenu>
                                {page === 0 ? (
                                  <>
                                    {dataAnswer.map((answItem, index) => (
                                      <DropdownItem
                                        className="answerDropDown"
                                        id={data.code}
                                        name={data.code}
                                        onClick={(e) => {
                                          let item = currAnswer;
                                          const answer = notClick;
                                          item[idx] = e.target.value;
                                          answer[e.target.value] = true;
                                          setCurrAnswer(item);
                                          setNotClick(answer);
                                          changeInput(e, data.code);
                                        }}
                                        disabled={notClick[answItem]}
                                        // disabled={
                                        //   errorInput ? errorInput !== data.code : false
                                        // }
                                        //   }}
                                        value={answItem}
                                        key={index}
                                      >
                                        {answItem}
                                      </DropdownItem>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {dataAnswer2.map((answerItem, index) => (
                                      <DropdownItem
                                        className="answerDropDown"
                                        id={data.code}
                                        name={data.code}
                                        onClick={(e) => {
                                          let item = currAnswer2;
                                          const answer = notClick;
                                          item[idx] = e.target.value;
                                          answer[e.target.value] = true;
                                          setCurrAnswer2(item);
                                          setNotClick2(answer);
                                          changeInput(e, data.code);
                                        }}
                                        // disabled={
                                        //   errorInput
                                        //   ? errorInput !== data.code
                                        //   : false
                                        // }
                                        disabled={notClick[answerItem]}
                                        value={answerItem}
                                        key={index}
                                      >
                                        {answerItem}
                                      </DropdownItem>
                                    ))}
                                  </>
                                )}
                              </DropdownMenu>
                              {page === 0 ? (
                                <i
                                  className="fa fa-times ml-1 icon-delete-answer"
                                  onClick={() => {
                                    setNotClick((state) => ({
                                      ...state,
                                      [currAnswer[idx]]: false,
                                    }));
                                    const answer = notClick;
                                    const init = currAnswer[idx];
                                    answer[init] = false;
                                    setCurrAnswer((state) => ({
                                      ...state,
                                      [idx]: null,
                                    }));
                                    setAnswers((state) => ({
                                      ...state,
                                      [data.code]: null,
                                    }));
                                    // console.log(currAnswer)
                                    // console.log(idx)
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-times ml-1 icon-delete-answer"
                                  onClick={() => {
                                    setNotClick((state) => ({
                                      ...state,
                                      [currAnswer2[idx]]: false,
                                    }));

                                    let answer = notClick2;
                                    let init = currAnswer2[idx];
                                    answer[init] = false;
                                    setCurrAnswer2((state) => ({
                                      ...state,
                                      [idx]: null,
                                    }));
                                    setAnswers((state) => ({
                                      ...state,
                                      [data.code]: null,
                                    }));
                                  }}
                                />
                              )}
                            </UncontrolledDropdown>

                            {errorInput === data.code ? (
                              <p style={{ color: "red" }}>
                                {t("Agility.errorInputSadana")}
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                      <Col sm="12" className="mt-4 text-center">
                        <button
                          type="reset"
                          className="btn btn-primary btn-lg"
                          onClick={nextPage}
                          disabled={btnDisable}
                        >
                          {questions[page].lastQuestion
                            ? "Submit"
                            : t("General.next")}{" "}
                          <i className="fa fa-arrow-right ml-2"></i>
                        </button>
                      </Col>
                    </>
                  ) : null
                ) : null}
              </Row>
            </Form>
          </div>
        </Col>
        <Col xs="0" md="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(Sadana);

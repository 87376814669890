import React from 'react';
import { useHistory } from 'react-router-dom';
import { translate, t } from 'react-switch-lang';
import { Button } from 'reactstrap';
import Illustration from '../../../../assets/assets_ari/no_invoice.png';

const NoInvoice = () => {
  const history = useHistory()

  return (
    <div className="text-center py-3 w-100">
      <img src={Illustration} alt="not found" width={300} height={300} />
      <h6 className='my-3' style={{ color: '#1B568D' }}>
        {t('Maaf, saat ini kamu belum memiliki riwayat Pembayaran')}
      </h6>
      <Button color='info' className='mt-2 text-white' onClick={() => history.push('/home')}>
        {t('Kembali ke Skillana')}
      </Button>
    </div>
  );
}

export default translate(NoInvoice);

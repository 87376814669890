import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { translate, t } from "react-switch-lang";
import { isMobile } from "react-device-detect";
import ResultPremium from "../ResultPremium";
import { useSelector } from "react-redux";
export default translate(function ResultAgility({
  paid,
  result,
  back,
  message,
}) {
  const [open, setOpen] = useState(false);
  const [openKey, setOpenKey] = useState(false);
  const [modal, setModal] = useState(false);
  const prevPath = useSelector((state) => state?.prevPath);

  const dataAgility = [
    {
      text: "Cognitive Simulation",
      star: result?.cognitive?.star ?? 0,
      name: "cognitive",
      interpretation: result?.cognitive?.interpretation ?? null,
      desc: t("Agility.cognitive"),
    },
    {
      text: "Experimentation",
      star: result?.experimentation?.star ?? 0,
      name: "experimentation",
      interpretation: result?.experimentation?.interpretation ?? null,
      desc: t("Agility.experimentation"),
    },
    {
      text: "Pattern Recognition",
      star: result?.pattern?.star ?? 0,
      name: "pattern",
      interpretation: result?.pattern?.interpretation ?? null,
      desc: t("Agility.pattern"),
    },
    {
      text: "Feedback Seeking",
      star: result?.feedback?.star ?? 0,
      name: "feedback",
      interpretation: result?.feedback?.interpretation ?? null,
      desc: t("Agility.feedback"),
    },
    {
      text: "Reflection",
      star: result?.reflection?.star ?? 0,
      name: "reflection",
      interpretation: result?.reflection?.interpretation ?? null,
      desc: t("Agility.reflection"),
    },
    {
      text: "Counterfactual Thinking",
      name: "counterfactual",
      type1: result?.counterfactual[0]?.type,
      type2: result?.counterfactual[1]?.type,
      interpretation1: result?.counterfactual[0]?.interpretation ?? null,
      interpretation2: result?.counterfactual[1]?.interpretation ?? null,
      desc: t("Agility.counterfactual"),
    },
  ];
  const toggle = () => setModal(!modal);
  function openDetail(key) {
    if (isMobile) {
      setOpenKey(key);
      setModal(true);
    } else {
      setOpen(true);
      setOpenKey(key);
    }
  }
  function closeDetail() {
    setOpenKey(false);
    setOpen(false);
  }
  const dataGraph = {
    labels: ["Upward", "Substractive", "Downward", "Additive"],
    datasets: [
      {
        data: [
          result?.counterfactual[1]?.type === "upward"
            ? result?.counterfactual[1]?.value
            : 0,
          result?.counterfactual[0]?.type === "substractive"
            ? result?.counterfactual[0]?.value
            : 0,
          result?.counterfactual[1]?.type === "downward"
            ? result?.counterfactual[1]?.value
            : 0,
          result?.counterfactual[0]?.type === "additive"
            ? result?.counterfactual[0]?.value
            : 0,
        ],
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "#2a8c43",
        pointBackgroundColor: "#2a8c43",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#2a8c43",
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: false,
        color: "#fff",
        font: {
          weight: "bold",
          size: 11,
        },
        borderColor: "#fff",
        borderWidth: 2,
        padding: {
          top: 6,
          bottom: 5,
          left: 8,
          right: 8,
        },
        borderRadius: 999,
      },
    },

    legend: {
      display: false,
    },
    title: {
      display: false,
      text: t("Assessment.agilityTitle"),
    },
    scale: {
      gridLines: {
        display: false,
        circular: true,
      },
      angleLines: {
        // display: false,
      },
      ticks: {
        display: false,
        max: 5,
        min: 0,
        stepSize: 1,
        beginAtZero: true,
        showLabelBackdrop: true,
      },
      pointLabels: {
        display: false,
        fontStyle: "bold",
        fontSize: 12,
      },
    },
  };
  const chartRef = useRef(null);
  return (
    <Container>
      <div
        className="justify-content-center pb-4"
        style={{ paddingTop: "2rem" }}
      >
        <Row className="mb-4">
          <Col sm="2">
            {back ? (
              <Link to={prevPath ? prevPath : "/assessment"}>
                <Button className="navtext btn btn-lg btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </Button>
              </Link>
            ) : null}
          </Col>
          <Col sm="8">
            <Row>
              <Col sm="2" />
              <Col sm="8">
                <Alert
                  color="success"
                  className="text-center mb-3"
                  style={{
                    width: "100%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <h5 className="content-title mb-2">
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.agilityTitle")}.
                  </h5>
                </Alert>
                <Col sm="2" />
              </Col>
            </Row>
          </Col>
          <Col sm="2" className="mb-3"></Col>
        </Row>

        {paid ? (
          <Row>
            <Col xs="1" md="0" lg="1" />
            <Col xs="10" md="12" lg="10">
              <div className="text-center">
                <Container>
                  <h3 className="mb-5" style={{ fontWeight: "bold" }}>
                    {t("Assessment.agilityTestResult")}
                  </h3>
                  <div className="mb-5">
                    <img
                      src={require("../../../../assets/img/agility/result.png")}
                      width="70%"
                      alt="agility done"
                    />
                  </div>
                  <Row>
                    {dataAgility.map((item, key) => {
                      return (
                        <>
                          <Col md="4" className="text-center pt-2 pb-2">
                            <div
                              className={
                                openKey === key
                                  ? "bg-open p-3"
                                  : "bg-no-open p-3"
                              }
                            >
                              <div
                                style={{
                                  border: "1px solid #F2C94C",
                                  boxSizing: "border-box",
                                  borderRadius: "12px",
                                  padding: "10px 5px",
                                  minHeight: "300px",
                                  backgroundColor: "#fff",
                                }}
                                className={
                                  openKey === key && !isMobile
                                    ? "cursor-pointer"
                                    : null
                                }
                                onClick={
                                  openKey === key && !isMobile
                                    ? closeDetail
                                    : null
                                }
                              >
                                <p className="text-right">
                                  <i
                                    id={"UncontrolledTooltip" + key}
                                    className="fa fa-question-circle mr-2"
                                    style={{
                                      position: "relative",
                                      top: "1px",
                                      color: "#1472BA",
                                      fontSize: "15px",
                                    }}
                                  />
                                </p>
                                <UncontrolledTooltip
                                  target={"UncontrolledTooltip" + key}
                                >
                                  {item?.desc}
                                </UncontrolledTooltip>
                                {openKey === key && !isMobile ? (
                                  <>
                                    <img
                                      src={require("../../../../assets/img/agility/" +
                                        item?.name +
                                        ".png")}
                                      className="img-icon-agility"
                                      alt={item?.name}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={require("../../../../assets/img/agility/" +
                                        item?.name +
                                        ".png")}
                                      height="90px"
                                      alt={item?.name}
                                    />

                                    <h6 className="mt-3 mb-2">{item?.text}</h6>
                                    {item?.name === "counterfactual" ? (
                                      <p
                                        style={{ textTransform: "uppercase" }}
                                        className="mb-4"
                                      >
                                        <b>
                                          {item?.type1} - {item?.type2}
                                        </b>
                                      </p>
                                    ) : (
                                      <div
                                        style={{
                                          fontSize: "15px",
                                        }}
                                        className="mb-2"
                                      >
                                        <StarRatingComponent
                                          name="start"
                                          starCount={5}
                                          value={item?.star ?? 0}
                                        />
                                      </div>
                                    )}
                                    <button
                                      className="btn btn-primary btn-skillana mb-3"
                                      onClick={() => openDetail(key)}
                                    >
                                      <b>{t("General.seeResult")}</b>
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                          {key === 2 ? (
                            <Col
                              sm="12"
                              className={
                                open && openKey < 3 && openKey >= 0
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <div
                                className="p-3 text-left mb-3"
                                style={{
                                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "12px",
                                  backgroundColor: "#F2F8FE",
                                }}
                              >
                                <h5
                                  className="mt-3 mr-2"
                                  style={{ float: "left" }}
                                >
                                  <b>{dataAgility[openKey]?.text}</b>
                                </h5>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <StarRatingComponent
                                    name="start"
                                    starCount={5}
                                    value={dataAgility[openKey]?.star ?? 0}
                                  />
                                </div>
                                <p>{dataAgility[openKey]?.interpretation}</p>
                              </div>
                            </Col>
                          ) : null}
                          {key === 5 ? (
                            <Col
                              sm="12"
                              className={
                                open && openKey < 6 && openKey > 2
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <div
                                className="p-3 text-left mb-3"
                                style={{
                                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "12px",
                                  backgroundColor: "#F2F8FE",
                                }}
                              >
                                <h5
                                  className="mt-3 mr-2"
                                  style={{ float: "left" }}
                                >
                                  <b>{dataAgility[openKey]?.text}</b>
                                </h5>
                                {openKey !== 5 ? (
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <StarRatingComponent
                                      name="start"
                                      starCount={5}
                                      value={dataAgility[openKey]?.star ?? 0}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        textTransform: "uppercase",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <b>
                                        ( {result?.type1} - {result?.type2} )
                                      </b>
                                    </p>
                                    <br />
                                  </>
                                )}
                                {openKey === 5 ? (
                                  <Row>
                                    <Col xs="2"></Col>
                                    <Col xs="8" className="text-center">
                                      <div className="mx-auto grafik-agility">
                                        <Radar
                                          data={dataGraph}
                                          options={options}
                                          width={50}
                                          height={25}
                                          ref={chartRef}
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="2"></Col>
                                  </Row>
                                ) : null}
                                <p>
                                  {openKey !== 5
                                    ? dataAgility[openKey]?.interpretation
                                    : dataAgility[openKey]?.interpretation1 +
                                      " " +
                                      dataAgility[openKey]?.interpretation2}
                                </p>
                              </div>
                            </Col>
                          ) : null}
                        </>
                      );
                    })}
                  </Row>
                </Container>
              </div>
              <br />
            </Col>
            <Col xs="1" md="0" lg="1" />
          </Row>
        ) : (
          <ResultPremium test="agility" />
        )}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <h5 className="mt-3 mr-2" style={{ float: "left" }}>
              <b>{dataAgility[openKey]?.text}</b>
            </h5>
            {openKey !== 5 ? (
              <div
                style={{
                  fontSize: "15px",
                  marginTop: "15px",
                }}
              >
                <StarRatingComponent
                  name="start"
                  starCount={5}
                  value={dataAgility[openKey]?.star ?? 0}
                />
              </div>
            ) : (
              <>
                <p
                  style={{
                    textTransform: "uppercase",
                    marginTop: "15px",
                  }}
                >
                  <b>
                    ( {dataAgility[openKey]?.type1} -{" "}
                    {dataAgility[openKey]?.type2} )
                  </b>
                </p>
                <br />
              </>
            )}
          </ModalHeader>
          <ModalBody>
            {openKey === 5 ? (
              <Row>
                <Col xs="0" sm="2"></Col>
                <Col xs="12" sm="8" className="text-center">
                  <div className="mx-auto grafik-agility">
                    <Radar
                      data={dataGraph}
                      options={options}
                      width={50}
                      height={25}
                      ref={chartRef}
                    />
                  </div>
                </Col>
                <Col xs="0" sm="2"></Col>
              </Row>
            ) : null}
            <p>
              {openKey !== 5
                ? dataAgility[openKey]?.interpretation
                : dataAgility[openKey]?.interpretation1 +
                  " " +
                  dataAgility[openKey]?.interpretation2}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
});

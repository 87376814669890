import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  Row,
  Alert,
  Col,
  Container,
  CardDeck,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
// import { LeftLargeRibbon, RibbonContainer } from "react-ribbons";
import { RibbonContainer } from "react-ribbons";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import { Link, useHistory, useLocation } from "react-router-dom";
import Colors from "../../../../utils/Colors";
import request from "../../../../utils/Request";
import LoadingSkeleton from "../../../Templates/SkeletonLoading/Menu/Assesment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import requestGeneral from "../../../../utils/RequestGeneral";
import Tour from "reactour";
import { translate } from "react-switch-lang";
import InternalModal from "../../../../components/atoms/InternalModal";
import NoUserInternalModal from "../../../../components/atoms/NoUserInternalModal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalPremium from "./Premium/ModalPremium";
import ModalPayment from "./Premium/ModalPayment";

toast.configure();

const Assessment = (props) => {
  const { t } = props;
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [expire, setExpire] = useState(false);
  const [mbtiExpire, setMbtiExpire] = useState(false);
  const [papiExpire, setPapiExpire] = useState(false);
  const [discExpire, setDiscExpire] = useState(false);
  const [msdtExpire, setMsdtExpire] = useState(false);
  const [spmExpire, setSpmExpire] = useState(false);
  const [agilityExpire, setAgilityExpire] = useState(false);
  const [businessExpire, setuBusinessExpire] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [modal, setModal] = useState(true);
  const [modalSub, setModalSub] = useState(false);
  const [requirement, setRequirement] = useState([]);
  const [openPremium, setOpenPremium] = useState(false);
  const [payment, setPayment] = useState(false);
  const togglePayment = () => setPayment(!payment);

  useEffect(() => {
    setOpenPremium(location?.state?.showModalPremium ?? false);
  }, [location]);

  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
      .then((res) => {
        setRequirement(res?.data?.data?.requirements);
        setCompany(res?.data?.data?.company_name);
        setJob(res?.data?.data?.name);
        setId(res?.data?.data?.id);
        requestGeneral
          .get(
            "internal/recruitment/internal/user/" +
              localStorage.getItem("referal") +
              "/" +
              res?.data?.data?.companyPaid
          )
          .then((res) => {
            if (!res?.data?.existSkillanaId && res?.data?.isSub) {
              setModal(true);
              setLoading(false);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.isSub) {
              setRequirement([]);
              setCompany(null);
              setJob(null);
              setId(null);
              localStorage.removeItem("referal");
              setModal(false);
              setModalSub(true);
              setLoading(false);
            } else {
              setModal(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setModal(false);
        expireAsessment();
      });
  }, []);

  function expireAsessment() {
    request
      .get("/users/expire-assessment")
      .then((res) => {
        setExpire(true);
        setLoading(false);
        setMbtiExpire(res.data.mbti === true ? "mbti" : false);
        setPapiExpire(res.data.papi === true ? "papikostick" : false);
        setDiscExpire(res.data.disc === true ? "disc" : false);
        setMsdtExpire(res.data.msdt === true ? "msdt" : false);
        setSpmExpire(res.data.spm === true ? "spm" : false);
        setAgilityExpire(res.data.agility === true ? "agility" : false);
        setuBusinessExpire(
          res.data.businessInsight === true ? "businessInsight" : false
        );
      })
      .catch((err) => {
        setExpire(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.user) {
      if (props.user.guideAssessment === 0) {
        setIsTourOpen(true);
      }
    }
  }, [props.user]);

  function removeInternal() {
    localStorage.removeItem("referal");
    setModal(false);
  }

  function logoutInternal() {
    localStorage.removeItem("referal");
    window.location.reload();
  }

  function removeInternalSub() {
    localStorage.removeItem("referal");
    setModalSub(false);
  }

  function regisInternal() {
    setLoadingPopup(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/" + id)
      .then((res) => {
        setLoadingPopup(false);
        setModal(false);
        toast.success("Success");
      })
      .catch((err) => {
        setLoadingPopup(false);
        toast.error("Error");
      });
  }

  const steps = [
    {
      selector: ".step-assessment-free",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.assessmentFree")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("next")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-assessment-premium",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.assessmentPremium")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    disableUserGuide();
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("General.okey")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  const menuList = [
    {
      name: "mbti",
      title: t("Assessment.aptitudeTitle"),
      desc: t("Assessment.aptitudeDesc"),
      image: "MBTI",
      link: "/assessment-mbti",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("mbti"))
            .length > 0
        : true,
      isFree: true,
    },
    {
      name: "disc",
      title: t("Assessment.personalityTitle"),
      desc: t("Assessment.personalityDesc"),
      image: "disc",
      link: "/assessment-disc",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("disc"))
            .length > 0
        : true,
      isFree: true,
    },
    {
      name: "msdt",
      title: t("Assessment.leadershipTitle"),
      desc: t("Assessment.leadershipDesc"),
      image: "MSDT",
      link: "/assessment-msdt",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("msdt"))
            .length > 0
        : true,
      isFree: false,
    },
    {
      name: "papikostick",
      title: t("Assessment.workStyleTitle"),
      desc: t("Assessment.workStyleDesc"),
      image: "papikostick",
      link: "/assessment-papikostik",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) =>
            item?.toLowerCase().includes("papikostick")
          ).length > 0
        : true,
      isFree: false,
    },
    // {
    //   name: "spm",
    //   title: "Tes IQ",
    //   desc:
    //     "Ketahui kemampuanmu secara intelektual, analisis dan menggunakan logika dalam memecahkan masalah.",
    //   image: "SPM",
    //   link: "/assessment-spm",
    // },

    {
      name: "businessInsight",
      title: t("Assessment.businessInsightTitle"),
      desc: t("Assessment.businessInsightDesc"),
      image: "businessInsight",
      link: "/assessment-business-insight",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) =>
            item?.toLowerCase().includes("bussiness-insight")
          ).length > 0
        : true,
      isFree: false,
    },
    {
      name: "agility",
      title: t("Assessment.agilityTitle"),
      desc: t("Assessment.agilityDesc"),
      image: "agility",
      link: "/assessment-agility",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("agility"))
            .length > 0
        : true,
      isFree: false,
    },
    {
      name: "fingerprint",
      title: t("Assessment.biometricTitle"),
      desc: t("Assessment.biometricDesc"),
      image: "biometrik",
      link: "/assessment-fingerprint",
      internal: localStorage.getItem("referal") ? false : true,
      isFree: true,
    },
    {
      name: "video",
      title: t("Dashboard.myVideoCv"),
      desc: t("Dashboard.descMyVideoCv"),
      image: "video",
      link: "/interview-online",
      internal: localStorage.getItem("referal") ? false : true,
      isFree: true,
      // marginTop: "0px",
      // step: "step4-home",
    },
  ];

  const handleOpenModalPremium = () => {
    if (
      history?.location &&
      history?.location?.state &&
      history?.location?.state?.showModalPremium
    ) {
      const state = { ...history.location.state };
      delete state.showModalPremium;
      history.replace({ ...history.location.state });
    }

    setOpenPremium((prev) => !prev);
  };

  const listFree = menuList.filter((i) => i.isFree);
  const listPaid = menuList.filter((i) => !i.isFree);
  const [assessmentUser, setAsessmentUser] = useState(null);

  useEffect(() => {
    request
      .get("users/assessments")
      .then((res) => {
        setAsessmentUser(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_assessment")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideAssessment: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideAssessment: 1,
        });
      });
  }

  function DoneTest() {
    return (
      <div className="done-card mx-3 py-2">
        <h6 className="text-success mb-0">
          <div className="done-wrap">
            <i className="fa fa-check-circle text-success mx-2" />
            {t("Tes Dikerjakan")}
          </div>
        </h6>
      </div>
    );
  }

  const checkDoneTest = (name, category) => {
    let tes;
    if (category === "premium")
      tes = assessmentUser?.premium?.find((f) => f.testName === name);
    if (category === "free") {
      tes = assessmentUser?.free?.find((f) => f.testName === name);

      // hidden for SPM
      if (name === "disc") {
        name = "spm";
        let tesSpm = assessmentUser?.free?.find((f) => f.testName === name);
        if (tesSpm?.expired || !tesSpm?.isDone) return false;
      }
    }
    if (tes.expired) return !tes.expired;
    else return tes?.isDone;
  };

  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  const internalCardWrapper = document.getElementsByClassName(
    "internal-card-wrapper"
  )[0];

  return (
    <div className="animated fadeIn">
      <Container className="mt-3">
        <Row className="mb-1">
          {loading ? null : localStorage.getItem("referal") ? (
            <Col sm="12" className="text-center">
              <Alert color="info" className="text-center mt-3 mb-4">
                <h5 className="content-title mb-2">
                  {t("Internal.alert1")} {job} {t("Internal.alert2")} {company}
                </h5>
                <br />
                <Button
                  className="btn button-outline-red"
                  onClick={logoutInternal}
                >
                  <i className="fa fa-sign-out mr-2"></i>
                  {t("Internal.logout")} {job}
                </Button>
              </Alert>
            </Col>
          ) : (
            <Col sm="4">
              <Link to="/home">
                <button className="navtext btn btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </button>
              </Link>
            </Col>
          )}
        </Row>
        {loading ? (
          <div className=" pb-5">
            <LoadingSkeleton />
          </div>
        ) : (
          <div>
            {!localStorage.getItem("referal") ? (
              <Tour
                steps={steps}
                accentColor={accentColor}
                showButtons={false}
                rounded={5}
                disableDotsNavigation={true}
                showNavigation={false}
                isOpen={isTourOpen}
                disableFocusLock={false}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                updateDelay={6}
                onRequestClose={() => {
                  disableUserGuide();
                  setIsTourOpen(false);
                }}
              />
            ) : null}
            <NoUserInternalModal
              isOpen={modalSub}
              onRight={removeInternalSub}
            />
            <Row className="mb-5">
              {localStorage.getItem("referal") ? (
                <InternalModal
                  isOpen={modal}
                  company={company}
                  job={job}
                  onLeft={removeInternal}
                  onRight={regisInternal}
                />
              ) : null}

              <Col md="12" className="menu assessment-page">
                <CardDeck>
                  <div style={{ display: "inherit", marginTop: "10px" }}>
                    <Row
                      className={
                        internalCardWrapper?.childElementCount > 4
                          ? "internal-card-wrapper justify-content-start"
                          : "internal-card-wrapper justify-content-center"
                      }
                    >
                      <div className="hr-free px-2 w-100 mb-4">
                        <h4 className="ml-3 my-4 font-weight-bold text-white">
                          {t("GRATIS")}
                        </h4>
                      </div>
                      <Col xs="12" className="mb-3 step-assessment-free">
                        <Row>
                          {listFree.map((data, idx) => {
                            if (data?.internal) {
                              // eslint-disable-line react-hooks/exhaustive-deps
                              return (
                                <Fragment key={idx}>
                                  <Col
                                    lg="3"
                                    md="4"
                                    xs="6"
                                    className="p-1 mb-3"
                                  >
                                    <Link
                                      to={
                                        !localStorage.getItem("referal")
                                          ? props.user.guideAssessment === 0
                                            ? "#"
                                            : data.link
                                          : data.link
                                      }
                                      className="card menu-item h-100 pb-2"
                                    >
                                      <RibbonContainer className="custom-class">
                                        <CardImg
                                          top
                                          width="86%"
                                          src={require("../../../../assets/img/" +
                                            data.image +
                                            ".png")}
                                          alt="Card image cap"
                                          className="p-3"
                                          onLoad={() => setLoadImg(true)}
                                        />
                                        {!localStorage.getItem("referal") ? (
                                          expire ? (
                                            mbtiExpire === data.name ||
                                            papiExpire === data.name ||
                                            discExpire === data.name ||
                                            spmExpire === data.name ||
                                            msdtExpire === data.name ||
                                            agilityExpire === data.name ||
                                            businessExpire === data.name ? (
                                              <div className="expired-card mx-3 py-2 px-3">
                                                <h6 className="text-danger mb-0">
                                                  <i className="fa fa-info-circle text-danger mx-2" />
                                                  {t("Tes Kadaluarsa")}
                                                </h6>
                                              </div>
                                            ) : checkDoneTest(
                                                data?.name,
                                                "free"
                                              ) ? (
                                              <DoneTest />
                                            ) : null
                                          ) : checkDoneTest(
                                              data?.name,
                                              "free"
                                            ) ? (
                                            <DoneTest />
                                          ) : null
                                        ) : null}
                                        {loadImg ? null : (
                                          <div
                                            style={{
                                              background: "transparent",
                                              height: "86%",
                                              width: "70%",
                                            }}
                                          />
                                        )}
                                        <CardBody>
                                          <CardTitle
                                            className="mb-2 title-menu-company"
                                            style={{
                                              color: Colors.primaryColor,
                                            }}
                                          >
                                            <h4 className="font-weight-bold">
                                              {data.title}
                                            </h4>
                                          </CardTitle>
                                          <CardText
                                            style={{ fontSize: "0.9em" }}
                                          >
                                            {data.desc}
                                          </CardText>
                                        </CardBody>
                                      </RibbonContainer>
                                    </Link>
                                  </Col>
                                </Fragment>
                              ); // eslint-disable-line react-hooks/exhaustive-deps
                            } else return null;
                          })}
                        </Row>
                      </Col>
                      <div className="hr-premium px-2 w-100 mb-4">
                        <h4 className="ml-3 my-4 font-weight-bold text-warning">
                          <FontAwesomeIcon icon="crown" className="mr-1" />
                          {t("PREMIUM")}
                        </h4>
                        {!props.user.isPremium && (
                          <Button
                            className="button-premium"
                            onClick={() => setOpenPremium(true)}
                          >
                            {t("Beli Sekarang")}
                          </Button>
                        )}
                      </div>
                      <Col xs="12" className="step-assessment-premium">
                        <Row>
                          {listPaid.map((data, idx) => {
                            if (data?.internal) {
                              // eslint-disable-line react-hooks/exhaustive-deps
                              return (
                                <Fragment key={idx}>
                                  <Col
                                    lg="3"
                                    md="4"
                                    xs="6"
                                    className="p-1 mb-3"
                                  >
                                    <Link
                                      to={
                                        !localStorage.getItem("referal")
                                          ? props.user.guideAssessment === 0
                                            ? "#"
                                            : data.link
                                          : data.link
                                      }
                                      className="card menu-item h-100 pb-2"
                                    >
                                      <RibbonContainer
                                        className="custom-class"
                                        style={{ position: "relative" }}
                                      >
                                        <CardImg
                                          top
                                          width="86%"
                                          src={require("../../../../assets/img/" +
                                            data.image +
                                            ".png")}
                                          alt="Card image cap"
                                          className="p-3"
                                          onLoad={() => setLoadImg(true)}
                                          style={{ position: "relative" }}
                                        />
                                        <div className="premium-label">
                                          <FontAwesomeIcon
                                            icon="crown"
                                            className="mr-1"
                                          />
                                          <span>PREMIUM</span>
                                        </div>
                                        {!localStorage.getItem("referal") ? (
                                          expire ? (
                                            mbtiExpire === data.name ||
                                            papiExpire === data.name ||
                                            discExpire === data.name ||
                                            spmExpire === data.name ||
                                            msdtExpire === data.name ||
                                            agilityExpire === data.name ||
                                            businessExpire === data.name ? (
                                              <div className="expired-card mx-3 py-2 px-3">
                                                <h6 className="text-danger mb-0">
                                                  <i className="fa fa-info-circle text-danger mx-2" />
                                                  {t("Tes Kadaluarsa")}
                                                </h6>
                                              </div>
                                            ) : checkDoneTest(
                                                data?.name,
                                                "premium"
                                              ) ? (
                                              <DoneTest />
                                            ) : null
                                          ) : checkDoneTest(
                                              data?.name,
                                              "premium"
                                            ) ? (
                                            <DoneTest />
                                          ) : null
                                        ) : null}
                                        {loadImg ? null : (
                                          <div
                                            style={{
                                              background: "transparent",
                                              height: "86%",
                                              width: "70%",
                                            }}
                                          />
                                        )}
                                        <CardBody>
                                          <CardTitle
                                            className="mb-2 title-menu-company"
                                            style={{
                                              color: Colors.primaryColor,
                                            }}
                                          >
                                            <h4 className="font-weight-bold">
                                              {data.title}
                                            </h4>
                                          </CardTitle>
                                          <CardText
                                            style={{ fontSize: "0.9em" }}
                                          >
                                            {data.desc}
                                          </CardText>
                                        </CardBody>
                                      </RibbonContainer>
                                    </Link>
                                  </Col>
                                </Fragment>
                              ); // eslint-disable-line react-hooks/exhaustive-deps
                            } else return null;
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </CardDeck>
              </Col>
            </Row>
          </div>
        )}
        <ModalPremium
          isOpen={openPremium}
          toggle={handleOpenModalPremium}
          listFree={listFree}
          onHome={true}
          togglePayment={togglePayment}
        />
        <ModalPayment payment={payment} togglePayment={togglePayment} />
      </Container>
      <FooterLandingPage />
    </div>
  );
};

const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(Assessment));

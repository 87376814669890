import React, { useEffect, useState } from "react";
import requestGeneral from "../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import { translate } from "react-switch-lang";
import Fisiognomi from "../Assessment/Fisiognomi";
import Palmistry from "../Assessment/Palmistry";
import Assessment from "../Assessment";

import GeneralLayout from "../../../../containers/Pages/Menu/DefaultLayout/GeneralLayout";
function ValidateAssessment(props) {
  const [existFisiognomi, setExistFisiognomi] = useState(true);
  const [existPalmistry, setExistPalmistry] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("fisiognomi")
            ).length > 0
          ) {
            setExistFisiognomi(false);
          }
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("palmistry")
            ).length > 0
          ) {
            setExistPalmistry(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {!existFisiognomi ? (
            <Fisiognomi onComplete={() => setExistFisiognomi(true)} />
          ) : !existPalmistry ? (
            <Palmistry onComplete={() => setExistPalmistry(true)} />
          ) : (
            <GeneralLayout>
              <Assessment />
            </GeneralLayout>
          )}
        </>
      )}
    </div>
  );
}
export default translate(ValidateAssessment);

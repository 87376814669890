import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { useState } from "react";
import { Formik, Form } from "formik";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import request from "../../../../../../utils/Request";
import { useEffect } from "react";
import StarRatingComponent from "react-star-rating-component";

const SkillHistory = (props) => {
  const { t } = props;
  const [isDataSkill, setIsDataSkill] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [skills, setSkills] = useState([]);
  const [deleteButton, setDeleteButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [valueLevelFormal, setValueLevelFormal] = useState({
    value: "",
    label: "",
  });
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });

  const onStarClick = (nextValue, prevValue, name) => {
    let addDataNew = { ...addDataSkill };
    addDataNew["level"] = nextValue;
    setAddDataSkill(addDataNew);
  };

  const [addDataSkill, setAddDataSkill] = useState({
    id: null,
    name: "",
    level: 1,
  });

  const getDataSkill = async () => {
    try {
      const res = await request.get("/users/resumes-skill");
      const skills = res.data.data;
      setSkills(skills);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.errors.message || "Error", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    getDataSkill();
  }, []);

  const modalAddDataSkill = () => {
    const { t } = props;
    setModalData(!modalData);
    setAddDataSkill({
      id: null,
      name: "",
      level: 1,
    });
    setImage({
      preview: "",
      raw: "",
    });
    setModalTitle(t("skill"));
    setIsDataSkill(true);
    setIsUpdate(false);
  };

  const modalEditDataSkill = (data) => {
    const { t } = props;
    setAddDataSkill({
      id: data.id,
      name: data.name,
      level: data.level,
    });
    let addDataNew = { ...addDataSkill };
    addDataNew = data;
    setModalData(!modalData);
    setAddDataSkill(addDataNew);
    setModalTitle(t("skill"));
    setIsDataSkill(true);
    setImage(image);
    setIsUpdate(true);
  };

  const cancelModal = (e) => {
    e.preventDefault();
    setModalData(false);
    setIsDataSkill(false);
    setIsUpdate(false);
    setImage({
      preview: "",
      raw: "",
    });
    setValueLevelFormal(valueLevelFormal);
  };

  const formValidate = (e, values) => {
    let errors = {};
    // e.preventDefault();
    if (isDataSkill === true) {
      // eslint-disable-next-line no-unused-vars
      let dataSkill = {
        name: addDataSkill.name,
        level: String(addDataSkill.level),
      };
    }

    // if (values.name === "" || values.name === null) {
    //   errors.description = "hanya boleh huruf untuk posisi";
    // }
    return errors;
  };

  const handleChange = (e) => {
    if (isDataSkill) {
      let addDataNew = { ...addDataSkill };
      addDataNew[e.target.name] = e.target.value;
      setAddDataSkill(addDataNew);
    }
  };

  const deleteData = async (e, id) => {
    setDeleteButton(true);
    try {
      await request.delete("/users/resumes-skill/" + id);
      setTimeout(() => {
        setModalData(!modalData);
        setAddDataSkill({
          id: null,
          name: "",
          level: "",
        });
        setImage({
          preview: "",
          raw: "",
        });
        setDeleteButton(false);
        setIsUpdate(false);
        setIsDataSkill(false);
        setLoadingButton(false);
        getDataSkill();
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      if (err.response) {
        setDeleteButton(false);
        toast.error("Error : " + JSON.stringify(err.response.data.errors), {
          autoClose: 2000,
        });
      }
    }
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let dataSkill = {};
    dataSkill = {
      name: addDataSkill.name,
      level: String(addDataSkill.level),
    };

    if (isUpdate === true) {
      try {
        await request.put("/users/resumes-skill/" + addDataSkill.id, dataSkill);
        toast.success(t("General.success"), { autoClose: 3000 });
        setModalData(false);
        getDataSkill();
      } catch (err) {
        if (err.response) {
          toast.error("Error", {
            autoClose: 2000,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }
    if (isUpdate === false) {
      try {
        await request.post("/users/resumes-skill", dataSkill);
        toast.success(t("General.success"), { autoClose: 3000 });
        setModalData(false);
        getDataSkill();
      } catch (err) {
        if (err.response) {
          toast.error("Error", {
            autoClose: 2000,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }
  };

  const itemSkills =
    skills.length > 0
      ? skills.map((data, idx) => {
          return (
            <tr key={idx}>
              <td className="text-center">{idx + 1}.</td>
              <td>{data.name}</td>
              <td className="text-center">
                <StarRatingComponent
                  name="level"
                  starCount={5}
                  value={data.level}
                />
              </td>
              <td className="text-center">
                {props.editProfilePro === true ? (
                  <Button
                    className="navtext"
                    color="secondary"
                    onClick={() => modalEditDataSkill(data)}
                    outline
                  >
                    <i className="fa fa-pencil mr-2"></i>
                    &nbsp;Edit
                  </Button>
                ) : null}
              </td>
            </tr>
          );
        })
      : null;

  return (
    <Fragment>
      <div className="content-body">
        <Row className="md-company-header mb-3">
          <Col className="d-flex justify-content-between align-items-center">
            <Label htmlFor="country" className="input-label">
              {t("Profile.skill")}
            </Label>
            {props.editProfilePro === true ? (
              <Button
                color="netis-color"
                onClick={() => modalAddDataSkill()}
                style={{ backgroundColor: "#5AADF7" }}
              >
                <i className="fa fa-plus mr-2"></i>
                {t("General.add")} Data
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center w-5">#</th>
                  <th className="w-35">{t("Profile.skillName")}</th>
                  <th className="text-center w-50">
                    {t("Profile.skillLevel")}
                  </th>
                  <th className="text-center w-40">{t("General.action")}</th>
                </tr>
              </thead>
              <tbody>{itemSkills}</tbody>
            </Table>
          </Col>
        </Row>
      </div>

      <Modal isOpen={modalData} className={"w-100 modal-lg " + props.className}>
        <ModalBody>
          <h5 className="content-sub-title mb-4">{modalTitle}</h5>
          <Formik
            initialValues={addDataSkill}
            validate={formValidate}
            onSubmit={formSubmit}
            render={({ isSubmitting, isDelete }) => (
              <Form>
                {isDataSkill === true ? (
                  <Fragment>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label htmlFor="name" className="input-label">
                          {t("Profile.skillName")}
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={addDataSkill.name}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="level" className="input-label">
                          {t("Profile.skillLevel")}
                        </Label>
                        <br />
                        <StarRatingComponent
                          name="level"
                          starCount={5}
                          onStarHover={onStarClick}
                          value={addDataSkill.level}
                          onStarClick={onStarClick}
                        />
                      </FormGroup>
                    </div>
                  </Fragment>
                ) : null}

                <Row>
                  <div className="col-8 d-flex ">
                    {isUpdate === false ? null : isDataSkill === true ? (
                      <Button
                        className="navtext"
                        color="red-custom"
                        onClick={(e) => deleteData(e, addDataSkill.id)}
                        disabled={deleteButton}
                      >
                        {deleteButton ? (
                          <span>
                            <Spinner size="sm" className="mr-2" /> Loading
                          </span>
                        ) : (
                          t("General.delete") + " Data"
                        )}
                      </Button>
                    ) : null}
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    {loadingButton ? (
                      <Spinner color="dark" size="sm" />
                    ) : (
                      <Fragment>
                        <Button
                          className="navtext mr-2"
                          color="white"
                          onClick={cancelModal}
                        >
                          {t("General.cancel")}
                        </Button>
                        <Button
                          className="navtext"
                          type="submit"
                          color="netis-color"
                          style={{ width: "67px" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <Spinner size="sm" className="mr-2" /> Loading
                            </span>
                          ) : (
                            t("General.save")
                          )}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                </Row>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default translate(SkillHistory);

import React from 'react';
import Illustration from '../../../assets/assets_ari/481.png';

const DataNotFound = () => {
    return (
        <div className="text-center py-3 w-100">
            <img src={Illustration} alt="not found" width={250} height={250} />
            <h6 className='mt-4 mb-2'>Data Not Found</h6>
        </div>
    );
}

export default DataNotFound;

import React, { useState, useRef } from "react";
import { translate, t } from "react-switch-lang";
import { Button, Progress, Container, Row, Col, Input } from "reactstrap";
import { useEffect } from "react";
import request from "../../../../utils/Request";
// import requestGeneral from "../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import moment from "../../../../utils/Moment";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import ConfirmationModal from "../../../../components/atoms/ConfirmationModal";
// import NoComplete from "../../../Pages/Menu/Assessment/NoCompleteProfile/index";
import { toast } from "react-toastify";
import BusinessInsightResult from "../../ResultAssessment/ResultAssessmentBusinessInsight";
toast.configure();
function arrayChunk(array, size = 10) {
  return Array(Math.ceil(array.length / size))
    .fill()
    .map((_, index) => index * size)
    .map((begin) => array.slice(begin, begin + size));
}

function Test() {
  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [affirmation, setAffirmation] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [answers, setAnswers] = useState({});
  const [page, setPage] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [result, setResult] = useState(null);
  // const [isNotComplete, setNotComplete] = useState(false);
  const [red, setRed] = useState(false);
  const refs = useRef([React.createRef(), React.createRef()]);

  moment.locale("id");
  useEffect(() => {
    request
      .get("/assessment/test/business-insight")
      .then((res) => {
        if (res.data?.data) {
          const source = res.data.data;
          setSource(source);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return Promise.reject(err);
      })
      .finally(() => setLoading(false));
    // requestGeneral
    //   .get(`/v2/users/validate-standart`)
    //   .then((res) => {
    //     request
    //       .get("/assessment/test/business-insight")
    //       .then((res) => {
    //         if (res.data?.data) {
    //           const source = res.data.data;
    //           setSource(source);
    //           setLoading(false);
    //         }
    //       })
    //       .catch((err) => {
    //         setLoading(false);
    //         return Promise.reject(err);
    //       });
    //   })
    //   .catch((err) => {
    //     if (err.response?.status === 422) {
    //       setNotComplete(true);
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       return Promise.reject(err);
    //     }
    //   });
  }, []);

  if (loading || analyzing) {
    return <LoadingAnimation />;
  }
  // if (isNotComplete) {
  //   return <NoComplete />;
  // }

  if (result) {
    return <BusinessInsightResult result={result} back={true} />;
  }

  const options = [1, 2, 3, 4, 5, 6, 7];

  const optionsReverse = [7, 6, 5, 4, 3, 2, 1];

  function onAnswered(code, answer) {
    setAnswers({ ...answers, [code]: answer });
    setRed(null);
  }
  // console.log(answers);
  const groupedQuestions = arrayChunk(source, 4);
  const pagesLength = groupedQuestions.length;
  const canGoToNext = source
    ? groupedQuestions[page]
        .map((q) => q.code)
        .every((code) => Object.keys(answers).indexOf(code) !== -1)
    : false;

  function nextPage() {
    if (page < pagesLength && canGoToNext) {
      setPage(page + 1);
      window.scroll({ top: 0, behavior: "smooth" });
    } else {
      let id;
      let idx = 0;
      groupedQuestions[page].some(function (qcode) {
        if (!answers[qcode.code]) {
          return (id = idx);
        }
        idx++;
        return false;
      });
      // console.log(id);
      // console.log(refs.current);
      setRed(id);
      refs.current[id].scrollIntoView();
    }
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function prevPage() {
    setRed(null);
    window.scrollTo(0, 0);
    if (page > 0) {
      setPage(page - 1);
    }
  }
  function submitAnswers() {
    window.scrollTo(0, 0);
    setConfirmation(false);
    setAnalyzing(true);
    request
      .post("assessment/test/business-insight", {
        answer: answers,
      })
      .then((res) => {
        setResult(res.data.data);
      })
      .catch((err) => {
        toast.error("Error", {
          autoClose: 3000,
        });
      })
      .finally(() => setAnalyzing(false));
  }

  function toggleAffirmation() {
    setAffirmation(!affirmation);
  }
  function toggleConfirmation() {
    setConfirmation(!confirmation);
  }
  // console.log(Math.round((Object.values(answers).length / 44) * 100));
  return (
    <div className="navtext">
      <Container>
        <h3 className="h1" style={{ marginTop: "2rem" }}>
          {t("Assessment.businessInsightTitle")}
        </h3>
        <hr />
        <Progress
          animated
          className="my-4"
          color={
            page === pagesLength - 1 && canGoToNext ? "success" : undefined
          }
          value={Math.round((Object.values(answers).length / 44) * 100)}
        ></Progress>
        <Row>
          {groupedQuestions[page].map((data, idx) => {
            return (
              <div
                key={idx}
                idx={idx}
                style={{
                  boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                }}
                className={
                  red === idx
                    ? "col-sm-12 p-4 mt-4 text-center shadow-red border-0"
                    : "col-sm-12 p-4 mt-4 text-center shadow border-0"
                }
                ref={(ref) => {
                  refs.current[idx] = ref;
                }}
              >
                <h5>
                  <b>{data.question}</b>
                </h5>
                <br />
                <br />
                {data.reverse
                  ? optionsReverse.map((dataOption, idxOption) => {
                      return (
                        <Input
                          key={idxOption}
                          type="radio"
                          className="opt-busines"
                          name={"option" + idx}
                          value={dataOption}
                          onChange={() => onAnswered(data.code, dataOption)}
                          checked={answers[data.code] === dataOption}
                        />
                      );
                    })
                  : options.map((dataOption, idxOption) => {
                      return (
                        <Input
                          key={idxOption}
                          type="radio"
                          className="opt-busines"
                          name={"option" + idx}
                          value={dataOption}
                          onChange={() => onAnswered(data.code, dataOption)}
                          checked={answers[data.code] === dataOption}
                        />
                      );
                    })}
                <br />
                <br />
                <Row>
                  <Col xs="4">
                    <p>{t("Assessment.veryDisaggree")}</p>
                  </Col>
                  <Col xs="4"></Col>
                  <Col xs="4">
                    <p>{t("Assessment.veryAggree")}</p>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Row>
        <div className="text-center mt-4 mb-3" style={{ width: "100%" }}>
          {page > 0 && (
            <Button
              className="navtext btn btn-lg btn-netis-color mr-5"
              onClick={prevPage}
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {t("General.back")}
            </Button>
          )}
          {page < pagesLength - 1 && (
            <Button
              className="navtext btn btn-lg btn-netis-color"
              onClick={nextPage}
            >
              {t("General.next")}
              <i className="fa fa-arrow-right ml-2"></i>
            </Button>
          )}
          {page === pagesLength - 1 && (
            <Button
              className="navtext btn btn-lg btn-success"
              onClick={toggleConfirmation}
              disabled={!canGoToNext}
            >
              <i className="fa fa-paper-plane mr-2"></i>
              <b>{t("General.submit")}</b>
            </Button>
          )}
        </div>
      </Container>

      {/* Modal Pop Up*/}
      <AffirmationModal
        className="navtext"
        isOpen={affirmation}
        title={t("General.warning") + " !"}
        desc={t("Assessment.warningDesc")}
        titleButton={t("General.okey")}
        toggleOpen={toggleAffirmation}
        confirmation={toggleAffirmation}
      />
      <ConfirmationModal
        className="navtext"
        isOpen={confirmation}
        title={t("General.areYouSure")}
        desc={t("Assessment.sureAssessment")}
        titleLeft={t("General.back")}
        titleRight={t("General.submit")}
        onPressLeft={toggleConfirmation}
        onPressRight={submitAnswers}
      />
    </div>
  );
}

export default translate(Test);

import React, { useState, Fragment, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  NavLink,
  Container,
  Modal,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import requestAuth from "../../../../utils/RequestAuth";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
import { toast } from "react-toastify";
function NavbarLandingPage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(true);
  const [scrollLogo, setScrollLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = props;
  const history = useHistory();
  const chooseLanguages = {
    ID: "ID",
    EN: "EN",
  };
  useEffect(() => {
    if (isScroll) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 0) {
          if (
            !document
              .getElementsByTagName("nav")[0]
              .classList.contains("onScroll")
          ) {
            document.getElementsByTagName("nav")[0].classList.add("onScroll");
            setScrollLogo(true);
          }
        } else {
          if (document.getElementsByTagName("nav")[0]) {
            if (
              document
                .getElementsByTagName("nav")[0]
                .classList.contains("onScroll")
            ) {
              document
                .getElementsByTagName("nav")[0]
                .classList.remove("onScroll");
              setScrollLogo(false);
            }
          }
        }
      });
    }
  }, [isScroll]);
  const changePageLogin = () => {
    setIsScroll(false);
    history.push("/login");
  };
  const onSelectFlag = (countryCode) => {
    setLoading(true);
    if (props.user?.id) {
      let lang = new FormData();
      lang.append("language", countryCode.toLowerCase());
      requestAuth
        .post("/language", lang)
        .then((res) => {
          handleSetLanguage(countryCode);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(t("failedChangeLanguage"));
          setLoading(false);
        });
    } else {
      langUtils.setLanguage(countryCode);
      setLoading(false);
    }
  };
  const handleSetLanguage = (key) => {
    langUtils.setLanguage(key);
  };
  const type =
    window.location.pathname === "/type-personality/intp" ||
    window.location.pathname === "/type-personality/intj" ||
    window.location.pathname === "/type-personality/entp" ||
    window.location.pathname === "/type-personality/entj" ||
    window.location.pathname === "/type-personality/infj" ||
    window.location.pathname === "/type-personality/infp" ||
    window.location.pathname === "/type-personality/enfj" ||
    window.location.pathname === "/type-personality/enfp" ||
    window.location.pathname === "/type-personality/istj" ||
    window.location.pathname === "/type-personality/isfj" ||
    window.location.pathname === "/type-personality/estj" ||
    window.location.pathname === "/type-personality/esfj" ||
    window.location.pathname === "/type-personality/istp" ||
    window.location.pathname === "/type-personality/isfp" ||
    window.location.pathname === "/type-personality/estp" ||
    window.location.pathname === "/type-personality/esfp";

  return (
    <Fragment>
      <Navbar expand="lg" fixed="top" light>
        <Container style={{ fontSize: "0.83rem" }}>
          <Link to="/">
            <NavbarBrand className="logoSkilllana mr-5">
              {scrollLogo ? (
                <img
                  src={require("../../../../assets/img/logo_blue.png")}
                  alt="logo"
                  style={{ height: 40 }}
                />
              ) : window.location.pathname === "/type-asesmen" ||
                window.location.pathname === "/jobplacement" ||
                window.location.pathname === "/about" ||
                window.location.pathname === "/video-cv" ||
                window.location.pathname === "/promotion-video-cv" ||
                window.location.pathname === "/artikel" ||
                window.location.pathname === "/privacy-policy" ||
                window.location.pathname === "/terms-condition" ||
                window.location.pathname === "/type-personality" ||
                type ||
                window.location.pathname === "/faq" ? (
                <img
                  src={require("../../../../assets/img/logo_blue.png")}
                  alt="logo"
                  style={{ height: 40 }}
                />
              ) : scrollLogo ? (
                <img
                  src={require("../../../../assets/img/logo_blue.png")}
                  alt="logo"
                  style={{ height: 40 }}
                />
              ) : (
                <img
                  src={require("../../../../assets/img/logo_white.png")}
                  alt="logo"
                  style={{ height: 40 }}
                />
              )}
            </NavbarBrand>
          </Link>

          {window.location.pathname === "/type-asesmen" ||
            window.location.pathname === "/jobplacement" ||
            window.location.pathname === "/about" ||
            window.location.pathname === "/video-cv" ||
            window.location.pathname === "/promotion-video-cv" ||
            window.location.pathname === "/artikel" ||
            window.location.pathname === "/privacy-policy" ||
            window.location.pathname === "/terms-condition" ||
            window.location.pathname === "/type-personality/" ||
            type ||
            window.location.pathname === "/faq" ? (
            <NavbarToggler onClick={toggle} className="burgerNonHome" />
          ) : (
            <NavbarToggler onClick={toggle} className="burgerHome" />
          )}
          <Collapse navbar>
            <Nav className="mr-auto align-items-md-center" navbar>
              {window.location.pathname === "/" ? (
                <>
                  <NavItem>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <NavLink className="navbarMenu active">
                        {t("LandingPage.home")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/type-asesmen" style={{ textDecoration: "none" }}>
                      <NavLink className="navbarMenu">
                        {t("LandingPage.assessment")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/jobplacement" style={{ textDecoration: "none" }}>
                      <NavLink className="navbarMenu">
                        {t("LandingPage.jobVacancy")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/about" style={{ textDecoration: "none" }}>
                      <NavLink className="navbarMenu">
                        {t("LandingPage.aboutUs")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <NavLink className="navbarMenu">
                        {t("LandingPage.article")}
                      </NavLink>
                    </Link>
                  </NavItem>
                </>
              ) : window.location.pathname === "/type-asesmen" ||
                window.location.pathname === "/type-personality" ||
                type ? (
                <>
                  <NavItem>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.home")}
                      </NavLink>
                    </Link>
                  </NavItem>

                  <NavItem>
                    <Link
                      to={"/type-asesmen"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className=" navtext active"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.assessment")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/jobplacement"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.jobVacancy")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/about"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.aboutUs")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.article")}
                      </NavLink>
                    </Link>
                  </NavItem>
                </>
              ) : window.location.pathname === "/about" ? (
                <>
                  <NavItem>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.home")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/type-asesmen"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.assessment")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/jobplacement"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.jobVacancy")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/about"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navtext active"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.aboutUs")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.article")}
                      </NavLink>
                    </Link>
                  </NavItem>
                </>
              ) : window.location.pathname === "/jobplacement" ? (
                <>
                  <NavItem>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu-lk"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.home")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/type-asesmen"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu-lk"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.assessment")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/jobplacement"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navtext active"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.jobVacancy")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/about"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu-lk"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.aboutUs")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.article")}
                      </NavLink>
                    </Link>
                  </NavItem>
                </>
              ) : (
                <>
                  <NavItem>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.home")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/type-asesmen"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.assessment")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={"/jobplacement"}
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.jobVacancy")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/about"} style={{ textDecoration: "none" }}>
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.aboutUs")}
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <NavLink
                        className="navbarMenu"
                        style={{ color: " #335877" }}
                      >
                        {t("LandingPage.article")}
                      </NavLink>
                    </Link>
                  </NavItem>
                </>
              )}
            </Nav>
            <UncontrolledDropdown>
              <DropdownToggle
                className="ml-auto"
                size="md"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent"
                }}
              >
                {" "}
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  chooseLanguages[langUtils.getLanguage()]
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onSelectFlag("ID")}>
                  Bahasa Indonesia
                </DropdownItem>
                <DropdownItem onClick={() => onSelectFlag("EN")}>
                  English (US)
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {props.user?.id ? (
              <Nav className="ml-2" navbar>
                {props.user.avatar === null ? (
                  <div
                    className="round-100 bg-user"
                    onClick={changePageLogin}
                    style={{ cursor: "pointer" }}
                  ></div>
                ) : (
                  <div
                    onClick={changePageLogin}
                    className="round-100"
                    style={{
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DOMAIN + "" + props.user.avatar
                        })`,
                    }}
                  ></div>
                )}
              </Nav>
            ) : (
              <Nav className="account ml-2">
                <Button
                  color="warning"
                  size="md"
                  onClick={changePageLogin}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    marginRight: "10px",
                  }}
                >
                  {t("LandingPage.account")}
                </Button>
              </Nav>
            )}


          </Collapse>
        </Container>
      </Navbar>
      <Modal isOpen={isOpen} toggle={toggle} className={"modal-drawer w-100"} >
        <div className="drawer container">
          <div className="drawer-header pb-3">
            <Link to="/">
              <NavbarBrand className="text-center">
                {scrollLogo ? (
                  <img
                    src={require("../../../../assets/img/logo_blue.png")}
                    alt="logo"
                    style={{ height: 35 }}
                  />
                ) : window.location.pathname === "/type-asesmen" ||
                  window.location.pathname === "/jobplacement" ||
                  window.location.pathname === "/about" ||
                  window.location.pathname === "/video-cv" ||
                  window.location.pathname === "/promotion-video-cv" ||
                  window.location.pathname === "/artikel" ||
                  window.location.pathname === "/privacy-policy" ||
                  window.location.pathname === "/terms-condition" ||
                  window.location.pathname === "/type-personality" ||
                  type ||
                  window.location.pathname === "/faq" ? (
                  <img
                    src={require("../../../../assets/img/logo_blue.png")}
                    alt="logo"
                    style={{ height: 35 }}
                  />
                ) : scrollLogo ? (
                  <img
                    src={require("../../../../assets/img/logo_blue.png")}
                    alt="logo"
                    style={{ height: 35 }}
                  />
                ) : (
                  <img
                    src={require("../../../../assets/img/logo_white.png")}
                    alt="logo"
                    style={{ height: 35 }}
                  />
                )}
              </NavbarBrand>
            </Link>
            <NavbarToggler onClick={toggle} className="close-drawer ml-auto" />
          </div>
          <div className="text-center d-flex flex-column justify-content-center">
            <ul>
              {window.location.pathname === "/" ? (
                <>
                  <li className="nav-item active">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.assessment")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.jobVacancy")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      className="navtext nav-link m-0 p-0"
                      target="_blank"
                    >
                      {t("LandingPage.article")}
                    </Link>
                  </li>
                </>
              ) : window.location.pathname === "/type-asesmen" ? (
                <>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.home")}
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.assessment")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.jobVacancy")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      className="navtext nav-link m-0 p-0"
                      target="_blank"
                    >
                      {t("LandingPage.article")}
                    </Link>
                  </li>
                </>
              ) : window.location.pathname === "/about" ? (
                <>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.assessment")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.jobVacancy")}
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      className="navtext nav-link m-0 p-0"
                      target="_blank"
                    >
                      {t("LandingPage.article")}
                    </Link>
                  </li>
                </>
              ) : window.location.pathname === "/jobplacement" ? (
                <>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.assessment")}
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.jobVacancy")}
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      className="navtext nav-link m-0 p-0"
                      target="_blank"
                    >
                      {t("LandingPage.article")}
                    </Link>
                  </li>
                </>
              ) : (
                /* <>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      Beranda
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      Tipe Asesmen
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      Lowongan Kerja
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      Tentang Kami
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      to="/artikel"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      Artikel
                    </Link>
                  </li>
                </> */
                <>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="navtext nav-link m-0 p-0 "
                      onClick={toggle}
                    >
                      {t("LandingPage.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/type-asesmen"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.assessment")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobplacement"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.jobVacancy")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="navtext nav-link m-0 p-0"
                      onClick={toggle}
                    >
                      {t("LandingPage.aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: process.env.REACT_APP_BLOG_URL }}
                      className="navtext nav-link m-0 p-0"
                      target="_blank"
                    >
                      {t("LandingPage.article")}
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <UncontrolledDropdown>
              <DropdownToggle
                size="md"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent"
                }}
              >
                {" "}
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  chooseLanguages[langUtils.getLanguage()]
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onSelectFlag("ID")}>
                  Bahasa Indonesia
                </DropdownItem>
                <DropdownItem onClick={() => onSelectFlag("EN")}>
                  English (US)
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {props.user?.id ? (
              <Nav className="" navbar>
                <Link to={"/home"}>
                  <NavLink>
                    {props.user.avatar === null ? (
                      <Row>
                        <Col>
                          <div className="round-100 bg-user float-right ml-5" />
                        </Col>
                        <Col>
                          <div className="navtext float-left mt-2">
                            {props.user.fullName}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <div
                            className="round-100 float-right"
                            style={{
                              marginRight: "-20px",
                              backgroundImage: `url(${process.env.REACT_APP_DOMAIN +
                                "" +
                                props.user.avatar
                                })`,
                            }}
                          />
                        </Col>
                        <Col>
                          <div className="navtext float-left mt-2">
                            {props.user.fullName}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </NavLink>
                </Link>
              </Nav>
            ) : (
              <button
                className="btn-landing btn-netis btn-navbar"
                onClick={changePageLogin}
              >
                {t("LandingPage.account")}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default connect(({ user }) => ({ user }))(translate(NavbarLandingPage));

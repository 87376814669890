import React from "react";
import { Button, Progress, Row, Col, Container } from "reactstrap";
import { useEffect, useState } from "react";
import request from "../../../utils/Request";
import AffirmationModal from "../../../components/atoms/AffirmationModal";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import SetModal from "../../../components/atoms/ModalTitle";
import Hint from "../../../components/atoms/Hint";
import moment from "../../../utils/Moment";
import CaptureLoading from "../../../components/atoms/CaptureLoading";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";
import { isMobile, isBrowser } from "react-device-detect";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Skeleton from "react-loading-skeleton";
import Tour from "reactour";
import { translate, t } from "react-switch-lang";
import { useSelector } from "react-redux";
function Spm() {
  const [loading, setLoading] = useState(true);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [notComplete, setNotComplete] = useState(false);
  const [count, setCount] = useState(0);
  const [countQuestion, setCountQuestion] = useState(1);
  const [questionsSetA, setQuestionsSetA] = useState([]);
  const [answersA, setAnswersA] = useState({});
  const [answersB, setAnswersB] = useState({});
  const [answersC, setAnswersC] = useState({});
  const [answersD, setAnswersD] = useState({});
  const [answersE, setAnswersE] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [questionsSetB, setQuestionsSetB] = useState([]);
  const [questionsSetC, setQuestionsSetC] = useState([]);
  const [questionsSetD, setQuestionsSetD] = useState([]);
  const [questionsSetE, setQuestionsSetE] = useState([]);
  const [questionsActive, setQuestionsActive] = useState([]);
  const [answerRandom, setAnswerRandom] = useState([]);
  const [triggerNextPage, setTriggerNextPage] = useState(false);
  const [triggerPrevPage, setTriggerPrevPage] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupText, setPopupText] = useState(null);
  const [questionsNow, setQuestionsNow] = useState(null);
  const [result, setResult] = useState(false);
  const [startAt, setStartAt] = useState(null);
  const [nextTour, setNextTour] = useState(true);
  const [userGuide, setUserGuide] = useState(true);
  const [isTourOpenSecond, setIsTourOpenSecond] = useState(false);
  const [isTourOpenThird, setIsTourOpenThird] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const prevPath = useSelector((state) => state?.prevPath);

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const setList = [
    {
      key: "setA",
      desc: "Set A",
    },
    {
      key: "setB",
      desc: "Set B",
    },
    {
      key: "setC",
      desc: "Set C",
    },
    {
      key: "setD",
      desc: "Set D",
    },
    {
      key: "setE",
      desc: "Set E",
    },
  ];
  useEffect(() => {
    request
      .get("/assessment/test/spm")
      .then((res) => {
        if (res.data?.data) {
          setQuestionsSetA(res.data.data.setA);
          setQuestionsSetB(res.data.data.setB);
          setQuestionsSetC(res.data.data.setC);
          setQuestionsSetD(res.data.data.setD);
          setQuestionsSetE(res.data.data.setE);
          setQuestionsActive(res.data.data.setA);
          setLoading(false);
          setIsModal(true);
          setQuestionsNow("setA");
          setStartAt(moment().format("YYYY-MM-DD HH:mm:ss"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let answerSet;
    if (questionsActive[count]?.setCode === "setA") {
      answerSet = answersA;
    } else if (questionsActive[count]?.setCode === "setB") {
      answerSet = answersB;
    } else if (questionsActive[count]?.setCode === "setC") {
      answerSet = answersC;
    } else if (questionsActive[count]?.setCode === "setD") {
      answerSet = answersD;
    } else if (questionsActive[count]?.setCode === "setE") {
      answerSet = answersE;
    }

    if (triggerNextPage) {
      if (!answerSet.hasOwnProperty(questionsActive[count]?.code)) {
        if (questionsActive[count]?.setCode === "setA" && count > 1) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setB" && count >= 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setC" && count >= 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setD" && count >= 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setE" && count >= 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        }
      } else {
        if (questionsActive[count]?.setCode === "setA" && count > 1) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setB" && count >= 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setC" && count >= 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setD" && count >= 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setE" && count >= 0) {
          setAnswerRandom(questionsActive[count]?.option);
        }
      }
    }
    setTriggerNextPage(false);
    // eslint-disable-next-line
  }, [triggerNextPage, count + 1]);

  useEffect(() => {
    let answerSet;
    if (questionsActive[count]?.setCode === "setB") {
      answerSet = answersB;
    } else if (questionsActive[count]?.setCode === "setC") {
      answerSet = answersC;
    } else if (questionsActive[count]?.setCode === "setD") {
      answerSet = answersD;
    } else if (questionsActive[count]?.setCode === "setE") {
      answerSet = answersE;
    }

    if (
      questionsActive[count]?.setCode === "setB" ||
      questionsActive[count]?.setCode === "setC" ||
      questionsActive[count]?.setCode === "setD" ||
      questionsActive[count]?.setCode === "setE"
    ) {
      if (!answerSet.hasOwnProperty(questionsActive[0]?.code)) {
        if (questionsActive[count]?.setCode === "setB" && count === 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setC" && count === 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setD" && count === 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        } else if (questionsActive[count]?.setCode === "setE" && count === 0) {
          setAnswerRandom(
            questionsActive[count]?.option.sort(() => Math.random() - 0.5)
          );
        }
      } else {
        if (questionsActive[count]?.setCode === "setB" && count === 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setC" && count === 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setD" && count === 0) {
          setAnswerRandom(questionsActive[count]?.option);
        } else if (questionsActive[count]?.setCode === "setE" && count === 0) {
          setAnswerRandom(questionsActive[count]?.option);
        }
      }
    }
    // eslint-disable-next-line
  }, [count, questionsActive]);

  useEffect(() => {
    if (!triggerPrevPage) {
      if (questionsActive[count]?.setCode === "setA" && count > 1) {
        setAnswerRandom(questionsActive[count]?.option);
      } else if (questionsActive[count]?.setCode === "setB" && count >= 0) {
        setAnswerRandom(questionsActive[count]?.option);
      } else if (questionsActive[count]?.setCode === "setC" && count >= 0) {
        setAnswerRandom(questionsActive[count]?.option);
      } else if (questionsActive[count]?.setCode === "setD" && count >= 0) {
        setAnswerRandom(questionsActive[count]?.option);
      } else if (questionsActive[count]?.setCode === "setE" && count >= 0) {
        setAnswerRandom(questionsActive[count]?.option);
      }
    }
    setTriggerPrevPage(false);
    // eslint-disable-next-line
  }, [triggerPrevPage, count - 1]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSkeleton(false);
    }, 1000);
    setCountQuestion(count + 1);
  }, [count]);
  function toggleConfirmation() {
    setConfirmation(!confirmation);
  }
  function onAnswered(setCode, code, answer) {
    if (setCode === "setA") {
      setNextTour(false);
      setAnswersA({ ...answersA, [code]: answer });
    } else if (setCode === "setB") {
      setAnswersB({ ...answersB, [code]: answer });
    } else if (setCode === "setC") {
      setAnswersC({ ...answersC, [code]: answer });
    } else if (setCode === "setD") {
      setAnswersD({ ...answersD, [code]: answer });
    } else if (setCode === "setE") {
      setAnswersE({ ...answersE, [code]: answer });
    }
  }
  function changeSetActive(setCode) {
    if (setCode === "setA") {
      setPopup(true);
      setPopupText("Set A");
      setQuestionsActive(questionsSetA);
      if (Object.keys(answersA).length >= 12) {
        setCount(11);
      } else {
        setCount(Object.keys(answersA).length);
      }
    } else if (setCode === "setB") {
      setPopup(true);
      setPopupText("Set B");
      setQuestionsActive(questionsSetB);
      if (Object.keys(answersB).length >= 12) {
        setCount(11);
      } else {
        setCount(Object.keys(answersB).length);
      }
    } else if (setCode === "setC") {
      setPopup(true);
      setPopupText("Set C");
      setQuestionsActive(questionsSetC);
      if (Object.keys(answersC).length >= 12) {
        setCount(11);
      } else {
        setCount(Object.keys(answersC).length);
      }
    } else if (setCode === "setD") {
      setPopup(true);
      setPopupText("Set D");
      setQuestionsActive(questionsSetD);
      if (Object.keys(answersD).length >= 12) {
        setCount(11);
      } else {
        setCount(Object.keys(answersD).length);
      }
    } else if (setCode === "setE") {
      setPopup(true);
      setPopupText("Set E");
      setQuestionsActive(questionsSetE);
      if (Object.keys(answersE).length >= 12) {
        setCount(11);
      } else {
        setCount(Object.keys(answersE).length);
      }
    }
  }
  function nextPage() {
    if (questionsActive[count]?.setCode === "setA") {
      if (!answersA[questionsActive[count].code]) {
        setNotComplete(true);
      } else {
        setLoadingSkeleton(true);
        if (questionsNow === "setA") {
          if (Object.keys(answersA).length >= 12 && count === 11) {
            setPopup(true);
            setQuestionsNow("setB");
            setPopupText("Set B");
            setCount(0);
            setQuestionsActive(questionsSetB);
            setTriggerNextPage(false);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        } else {
          if (count === 11) {
            setPopup(true);
            setPopupText("Set B");
            setCount(0);
            setQuestionsActive(questionsSetB);
            setTriggerNextPage(false);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        }
        if (userGuide && Object.keys(answersA).length === 1) {
          setIsTourOpenSecond(true);
        } else if (userGuide && Object.keys(answersA).length === 2) {
          setIsTourOpenThird(true);
        }
      }
    } else if (questionsActive[count]?.setCode === "setB") {
      if (!answersB[questionsActive[count].code]) {
        setNotComplete(true);
      } else {
        setLoadingSkeleton(true);
        if (questionsNow === "setB") {
          if (Object.keys(answersB).length >= 12 && count === 11) {
            setPopup(true);
            setQuestionsNow("setC");
            setPopupText("Set C");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetC);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        } else {
          if (count === 11) {
            setPopup(true);
            setPopupText("Set C");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetC);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        }
      }
    } else if (questionsActive[count]?.setCode === "setC") {
      if (!answersC[questionsActive[count].code]) {
        setNotComplete(true);
      } else {
        setLoadingSkeleton(true);
        if (questionsNow === "setC") {
          if (Object.keys(answersC).length >= 12 && count === 11) {
            setPopup(true);
            setQuestionsNow("setD");
            setPopupText("Set D");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetD);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        } else {
          if (count === 11) {
            setPopup(true);
            setPopupText("Set D");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetD);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        }
      }
    } else if (questionsActive[count]?.setCode === "setD") {
      if (!answersD[questionsActive[count].code]) {
        setNotComplete(true);
      } else {
        setLoadingSkeleton(true);
        if (questionsNow === "setD") {
          if (Object.keys(answersD).length >= 12 && count === 11) {
            setPopup(true);
            setQuestionsNow("setE");
            setPopupText("Set E");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetE);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        } else {
          if (count === 11) {
            setPopup(true);
            setPopupText("Set E");
            setCount(0);
            setTriggerNextPage(false);
            setQuestionsActive(questionsSetE);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        }
      }
    } else if (questionsActive[count]?.setCode === "setE") {
      if (!answersE[questionsActive[count].code]) {
        setNotComplete(true);
      } else {
        setLoadingSkeleton(true);
        if (questionsNow === "setE") {
          if (Object.keys(answersE).length >= 12 && count === 11) {
            if (count === 11) {
              toggleConfirmation();
              setTriggerNextPage(false);
            } else {
              setCount(count + 1);
              setTriggerNextPage(true);
            }
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        } else {
          if (count === 11) {
            toggleConfirmation();
            setTriggerNextPage(false);
          } else {
            setCount(count + 1);
            setTriggerNextPage(true);
          }
        }
      }
    }
    window.scroll({ top: 0, behavior: "smooth" });
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function prevPage() {
    setLoadingSkeleton(true);
    if (questionsActive[count]?.setCode === "setA") {
      setCount(count - 1);
      setTriggerPrevPage(true);
    } else if (questionsActive[count]?.setCode === "setB") {
      if (count === 0) {
        setPopup(true);
        setQuestionsNow("setA");
        setPopupText("Set A");
        setTriggerPrevPage(false);
        setQuestionsActive(questionsSetA);
        setCount(11);
      } else {
        setCount(count - 1);
        setTriggerPrevPage(true);
      }
    } else if (questionsActive[count]?.setCode === "setC") {
      if (count === 0) {
        setPopup(true);
        setQuestionsNow("setB");
        setPopupText("Set B");
        setTriggerPrevPage(false);
        setQuestionsActive(questionsSetB);
        setCount(11);
      } else {
        setCount(count - 1);
        setTriggerPrevPage(true);
      }
    } else if (questionsActive[count]?.setCode === "setD") {
      if (count === 0) {
        setPopup(true);
        setQuestionsNow("setC");
        setPopupText("Set C");
        setTriggerPrevPage(false);
        setQuestionsActive(questionsSetC);
        setCount(11);
      } else {
        setCount(count - 1);
        setTriggerPrevPage(true);
      }
    } else if (questionsActive[count]?.setCode === "setE") {
      if (count === 0) {
        setPopup(true);
        setQuestionsNow("setD");
        setPopupText("Set D");
        setTriggerPrevPage(false);
        setQuestionsActive(questionsSetD);
        setCount(11);
      } else {
        setCount(count - 1);
        setTriggerPrevPage(true);
      }
    }

    window.scroll({ top: 0, behavior: "smooth" });
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function notCompleteOption() {
    if (notComplete) {
      return (
        <AffirmationModal
          isOpen={notComplete}
          title="Oops !"
          desc={t("Spm.oopsDesc")}
          titleButton={t("Spm.btnOk")}
          toggleOpen={() => {
            setNotComplete(true);
          }}
          confirmation={() => {
            setNotComplete(false);
          }}
        />
      );
    }
  }
  function modalSet() {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 2000);
      return <SetModal title={popupText} visible={popup} />;
    }
  }
  function submitAnswers() {
    setLoadingPopup(true);
    window.scrollTo(0, 0);
    let endAt = moment().format("YYYY-MM-DD HH:mm:ss");
    request
      .post("/assessment/test/spm", {
        answersA: answersA,
        answersB: answersB,
        answersC: answersC,
        answersD: answersD,
        answersE: answersE,
        startAt: startAt,
        endAt: endAt,
      })
      .then((res) => {
        setLoadingPopup(false);
        if (!res?.data?.isHaveDisc) {
          window.location.replace(prevPath ? prevPath : "/assessment-disc");
        } else {
          setResult(true);
        }
      })
      .catch((err) => {
        setLoadingPopup(false);
        setNotComplete(true);
      });
  }
  function popupSuccess() {
    if (result) {
      return (
        <Hint
          title={t("Spm.done")}
          onClick={() => {
            window.location.replace("/assessment-disc");
          }}
          backBtn={false}
          img="checked.png"
          visible={result}
          desc={t("Spm.cognitiveTestDone")}
          actionTitle={t("Spm.btnOk")}
        />
      );
    }
  }
  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  const steps = [
    {
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("Spm.beforeTakeCognitiveTestGuide1")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("Spm.btnNext")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="question"]',
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("Spm.beforeTakeCognitiveTestGuide2")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("Spm.btnPrevious")}
                </Button>
              </div>
              <div className="col-6 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(2)}
                >
                  {t("Spm.btnNext")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="option"]',
      position: "top",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("Spm.beforeTakeCognitiveTestGuide3")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("Spm.btnPrevious")}
                </Button>
              </div>
              <div className="col-6 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(3)}
                >
                  {t("Spm.btnNext")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="answer-1"]',
      content: ({ goTo, inDOM }) => (
        <div>
          <p>
            {t("Spm.beforeTakeCognitiveTestGuide4")}{" "}
            <b>{t("Spm.beforeTakeCognitiveTestGuide4Sub1")}</b>
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(2)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("Spm.btnPrevious")}
                </Button>
              </div>
              {!nextTour ? (
                <div className="col-6 text-right p-0">
                  <Button
                    className="mt-2"
                    type="submit"
                    color="netis-color"
                    onClick={() => goTo(4)}
                  >
                    {t("Spm.btnNext")}{" "}
                    <i className="fa fa-arrow-right ml-2"></i>
                  </Button>
                </div>
              ) : (
                false
              )}
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="next"]',
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("Spm.beforeTakeCognitiveTestGuide5")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(3)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("Spm.btnPrevious")}
                </Button>
              </div>
              <div className="col-6 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("Spm.btnUnderstand")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    // ...
  ];
  const stepsFirstModal = [
    {
      content: () => (
        <div>
          <h4
            className="title-upgrade text-center"
            style={{ color: "#93aad6" }}
          >
            {t("Spm.hallo")}
          </h4>
          <p className="text-justify">{t("Spm.beforeTakeCognitiveTest")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  outline
                  size="sm"
                  color="danger"
                  onClick={() => {
                    setIsModal(false);
                    // setPopup(true);
                    // setPopupText("Set A");
                  }}
                >
                  {t("Spm.btnNo")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    setIsTourOpen(true);
                    setIsModal(false);
                  }}
                >
                  {t("Spm.btnSure")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  const stepsSecondModal = [
    {
      content: () => (
        <div>
          <p className="text-justify">
            {t("Spm.beforeTakeCognitiveTestGuide6")}
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    setIsTourOpenSecond(false);
                  }}
                >
                  {t("Spm.btnOk")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  const stepsThirdModal = [
    {
      content: () => (
        <div>
          <p className="text-justify">{t("Spm.directionExample")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    setIsTourOpenThird(false);
                    setPopupText("Set A");
                    setPopup(true);
                    setUserGuide(false);
                  }}
                >
                  {t("Spm.btnOk")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  function noAnswers() {
    return false;
  }

  return (
    <div className="navtext p-5">
      {notCompleteOption()}
      {modalSet()}
      {popupSuccess()}
      <Tour
        showCloseButton={false}
        steps={stepsFirstModal}
        accentColor={accentColor}
        showNumber={false}
        isOpen={isModal}
        disableDotsNavigation={true}
        rounded={5}
        showNavigation={false}
        showButtons={false}
        disableFocusLock={false}
        onBeforeClose={enableBody}
        className="p-4"
        onRequestClose={() => {
          setIsModal(false);
        }}
      />
      <Tour
        steps={steps}
        accentColor={accentColor}
        showButtons={false}
        rounded={5}
        isOpen={isTourOpen}
        disableDotsNavigation={true}
        disableFocusLock={false}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        onRequestClose={() => {
          setIsTourOpen(false);
        }}
      />
      <Tour
        showCloseButton={false}
        steps={stepsSecondModal}
        accentColor={accentColor}
        showNumber={false}
        isOpen={isTourOpenSecond}
        disableDotsNavigation={true}
        rounded={5}
        showNavigation={false}
        showButtons={false}
        disableFocusLock={false}
        onBeforeClose={enableBody}
        className="p-4"
        onRequestClose={() => {
          setIsModal(false);
        }}
      />
      <Tour
        showCloseButton={false}
        steps={stepsThirdModal}
        accentColor={accentColor}
        showNumber={false}
        isOpen={isTourOpenThird}
        disableDotsNavigation={true}
        rounded={5}
        showNavigation={false}
        showButtons={false}
        disableFocusLock={false}
        onBeforeClose={enableBody}
        className="p-4"
        onRequestClose={() => {
          setIsModal(false);
        }}
      />

      {loading ? (
        <LoadingAnimation />
      ) : (
        <Container>
          <Row>
            <Col sm="12">
              <Row>
                <Col sm="2"></Col>
                <Col sm="8" className="p-0">
                  <h3>{t("Spm.cognitiveTestTitle")} </h3>
                  <hr />
                  <p>{t("Spm.choosePict")}</p>
                </Col>
                <Col sm="2"></Col>
              </Row>
            </Col>
            <Col sm="2"></Col>
            <Col sm="8" className="text-center">
              {isMobile
                ? setList.map((data, key) => {
                    return (
                      <div
                        style={{ display: "inline-block" }}
                        key={key}
                        className="mb-3"
                      >
                        <Button
                          style={{ padding: "5px 4px", fontSize: "1em" }}
                          className={
                            questionsActive[count].setCode === data.key
                              ? "btn btn-info btn-outline-blue mr-1 btn-actived-blue"
                              : data.key === "setA" &&
                                Object.keys(answersA).length >= 12
                              ? "btn btn-info btn-outline-blue mr-1 btn-completed"
                              : data.key === "setB" &&
                                Object.keys(answersB).length >= 12
                              ? "btn btn-info btn-outline-blue mr-1 btn-completed"
                              : data.key === "setC" &&
                                Object.keys(answersC).length >= 12
                              ? "btn btn-info btn-outline-blue mr-1 btn-completed"
                              : data.key === "setD" &&
                                Object.keys(answersD).length >= 12
                              ? "btn btn-info btn-outline-blue mr-1 btn-completed"
                              : data.key === "setE" &&
                                Object.keys(answersE).length >= 12
                              ? "btn btn-info btn-outline-blue mr-1 btn-completed"
                              : "btn btn-info btn-outline-blue mr-1"
                          }
                          onClick={() => changeSetActive(data.key)}
                          disabled={
                            data.key === "setA" &&
                            Object.keys(answersA).length === 0
                              ? true
                              : data.key === "setB" &&
                                Object.keys(answersB).length === 0
                              ? true
                              : data.key === "setC" &&
                                Object.keys(answersC).length === 0
                              ? true
                              : data.key === "setD" &&
                                Object.keys(answersD).length === 0
                              ? true
                              : data.key === "setE" &&
                                Object.keys(answersE).length === 0
                              ? true
                              : false
                          }
                        >
                          {data.desc}
                        </Button>
                      </div>
                    );
                  })
                : null}
              <Progress
                animated
                value={
                  questionsActive[count]?.setCode === "setA"
                    ? (Object.keys(answersA)?.length / 12) * 100
                    : questionsActive[count]?.setCode === "setB"
                    ? (Object.keys(answersB)?.length / 12) * 100
                    : questionsActive[count]?.setCode === "setC"
                    ? (Object.keys(answersC)?.length / 12) * 100
                    : questionsActive[count]?.setCode === "setD"
                    ? (Object.keys(answersD)?.length / 12) * 100
                    : questionsActive[count]?.setCode === "setE"
                    ? (Object.keys(answersE)?.length / 12) * 100
                    : null
                }
              ></Progress>
              <br />
              <h3>
                {questionsActive[count]?.setCode.replace("set", "") +
                  "" +
                  countQuestion}
              </h3>
              <br />
              <img
                src={
                  process.env.REACT_APP_DOMAIN +
                  "" +
                  questionsActive[count]?.question
                }
                alt={"Soal " + count}
                className={
                  loadingSkeleton
                    ? "mt-3 mb-4 question-none"
                    : "mt-3 mb-4 question-show"
                }
                data-tut="question"
              />
              {loadingSkeleton ? <Skeleton height={174} width={250} /> : null}
              <Row className="mt-4" data-tut="option">
                {questionsActive[count]?.code
                  ? (questionsActive[count]?.setCode === "setA" &&
                      count === 0) ||
                    (questionsActive[count]?.setCode === "setA" && count === 1)
                    ? questionsActive[count].option.map((data, key) => {
                        let i = ++key;
                        let nameClassSet = "";
                        if (questionsActive[count]?.setCode === "setA") {
                          if (answersA[questionsActive[count].code] === i) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setB") {
                          if (answersB[questionsActive[count].code] === i) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setC") {
                          if (answersC[questionsActive[count].code] === i) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setD") {
                          if (answersD[questionsActive[count].code] === i) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setE") {
                          if (answersE[questionsActive[count].code] === i) {
                            nameClassSet = "option-spm-selected";
                          }
                        }
                        let colrow;
                        if (questionsActive[count]?.option?.length === 6) {
                          colrow = 4;
                        } else {
                          colrow = 3;
                        }
                        return (
                          <Col
                            md={colrow}
                            xs={6}
                            className="mb-4 text-center"
                            key={key}
                            style={{ color: "#18568b" }}
                            data-tut={
                              questionsActive[count]?.setCode === "setA" &&
                              count === 0 &&
                              key === 4
                                ? "answer-1"
                                : ""
                            }
                          >
                            {key}
                            <div
                              className={
                                !loadingSkeleton
                                  ? (questionsActive[count]?.setCode ===
                                      "setA" &&
                                      count === 0 &&
                                      key === 4) ||
                                    (questionsActive[count]?.setCode ===
                                      "setA" &&
                                      count === 1 &&
                                      key === 5)
                                    ? nameClassSet === ""
                                      ? "option-spm blink-shadow d-flex"
                                      : "option-spm d-flex " + nameClassSet
                                    : "option-spm d-flex " + nameClassSet
                                  : "d-none"
                              }
                              onClick={() => {
                                (questionsActive[count]?.setCode === "setA" &&
                                  count === 0 &&
                                  key !== 4) ||
                                (questionsActive[count]?.setCode === "setA" &&
                                  count === 1 &&
                                  key !== 5)
                                  ? noAnswers()
                                  : onAnswered(
                                      questionsActive[count]?.setCode,
                                      questionsActive[count].code,
                                      i
                                    );
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_DOMAIN +
                                  "" +
                                  data.answer
                                }
                                alt={"Option " + key}
                                className="img-option"
                              />
                            </div>
                            {loadingSkeleton ? (
                              <Skeleton height={120} width="100%" />
                            ) : null}
                          </Col>
                        );
                      })
                    : answerRandom.map((data, key) => {
                        let i = ++key;
                        let optionId =
                          questionsActive[count]?.option[i - 1]?.id;
                        let nameClassSet = "";
                        if (questionsActive[count]?.setCode === "setA") {
                          if (
                            answersA[questionsActive[count].code] === optionId
                          ) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setB") {
                          if (
                            answersB[questionsActive[count].code] === optionId
                          ) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setC") {
                          if (
                            answersC[questionsActive[count].code] === optionId
                          ) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setD") {
                          if (
                            answersD[questionsActive[count].code] === optionId
                          ) {
                            nameClassSet = "option-spm-selected";
                          }
                        } else if (questionsActive[count]?.setCode === "setE") {
                          if (
                            answersE[questionsActive[count].code] === optionId
                          ) {
                            nameClassSet = "option-spm-selected";
                          }
                        }
                        let colrow;
                        if (questionsActive[count]?.option?.length === 6) {
                          colrow = 4;
                        } else {
                          colrow = 3;
                        }
                        return (
                          <Col
                            md={colrow}
                            xs={6}
                            className="mb-4 text-center"
                            key={key}
                            style={{ color: "#18568b" }}
                            data-tut={
                              questionsActive[count]?.setCode === "setA" &&
                              count === 0 &&
                              key === 4
                                ? "answer-1"
                                : ""
                            }
                          >
                            {key}
                            <div
                              className={
                                !loadingSkeleton
                                  ? (questionsActive[count]?.setCode ===
                                      "setA" &&
                                      count === 0 &&
                                      key === 4) ||
                                    (questionsActive[count]?.setCode ===
                                      "setA" &&
                                      count === 1 &&
                                      key === 5)
                                    ? nameClassSet === ""
                                      ? "option-spm blink-shadow d-flex"
                                      : "option-spm d-flex " + nameClassSet
                                    : "option-spm d-flex " + nameClassSet
                                  : "d-none"
                              }
                              onClick={() => {
                                (questionsActive[count]?.setCode === "setA" &&
                                  count === 0 &&
                                  key !== 4) ||
                                (questionsActive[count]?.setCode === "setA" &&
                                  count === 1 &&
                                  key !== 5)
                                  ? noAnswers()
                                  : onAnswered(
                                      questionsActive[count]?.setCode,
                                      questionsActive[count].code,
                                      optionId
                                    );
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_DOMAIN +
                                  "" +
                                  data.answer
                                }
                                alt={"Option " + key}
                                className="img-option"
                              />
                            </div>
                            {loadingSkeleton ? (
                              <Skeleton height={120} width="100%" />
                            ) : null}
                          </Col>
                        );
                      })
                  : null}
              </Row>
              <div className="text-center mt-4" style={{ width: "100%" }}>
                {questionsActive[count]?.setCode === "setA" &&
                count <= 0 ? null : (
                  <Button
                    className="navtext btn btn-lg btn-netis-color"
                    onClick={prevPage}
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-arrow-left mr-2"></i>
                    {t("General.back")}
                  </Button>
                )}
                {questionsActive[count]?.setCode === "setE" && count === 11 ? (
                  <Button
                    className="navtext btn btn-sm btn-success"
                    onClick={nextPage}
                    style={{ padding: "7px 15px" }}
                    disabled={
                      Object.keys(answersE)?.length === 12 ? false : true
                    }
                  >
                    <b>
                      <i className="fa fa-paper-plane mr-2"></i>
                      {t("General.submit")}
                    </b>
                  </Button>
                ) : (
                  <Button
                    className="navtext btn btn-lg btn-netis-color"
                    onClick={isTourOpen ? "#" : nextPage}
                    data-tut="next"
                  >
                    {t("Spm.btnNext")}
                    <i className="fa fa-arrow-right ml-2"></i>
                  </Button>
                )}
              </div>
            </Col>
            <Col sm="2" className="text-center">
              {isBrowser
                ? setList.map((data, key) => {
                    return (
                      <div key={key}>
                        <Button
                          className={
                            questionsActive[count]?.setCode === data.key
                              ? "btn btn-info btn-outline-blue mb-3 btn-actived-blue"
                              : data.key === "setA" &&
                                Object.keys(answersA).length >= 12
                              ? "btn btn-info btn-outline-blue mb-3 btn-completed"
                              : data.key === "setB" &&
                                Object.keys(answersB).length >= 12
                              ? "btn btn-info btn-outline-blue mb-3 btn-completed"
                              : data.key === "setC" &&
                                Object.keys(answersC).length >= 12
                              ? "btn btn-info btn-outline-blue mb-3 btn-completed"
                              : data.key === "setD" &&
                                Object.keys(answersD).length >= 12
                              ? "btn btn-info btn-outline-blue mb-3 btn-completed"
                              : data.key === "setE" &&
                                Object.keys(answersE).length >= 12
                              ? "btn btn-info btn-outline-blue mb-3 btn-completed"
                              : "btn btn-info btn-outline-blue mb-3"
                          }
                          onClick={() => changeSetActive(data.key)}
                          disabled={
                            data.key === "setA" &&
                            Object.keys(answersA).length === 0
                              ? true
                              : data.key === "setB" &&
                                Object.keys(answersB).length === 0
                              ? true
                              : data.key === "setC" &&
                                Object.keys(answersC).length === 0
                              ? true
                              : data.key === "setD" &&
                                Object.keys(answersD).length === 0
                              ? true
                              : data.key === "setE" &&
                                Object.keys(answersE).length === 0
                              ? true
                              : false
                          }
                        >
                          {data.desc}
                        </Button>
                      </div>
                    );
                  })
                : null}
            </Col>
          </Row>
          <ConfirmationModal
            className="navtext"
            isOpen={confirmation}
            title={t("Spm.areYouSure")}
            desc={t("Spm.areYouSure2")}
            titleLeft={t("Spm.btnBack")}
            titleRight={t("Spm.btnSend")}
            onPressLeft={toggleConfirmation}
            onPressRight={submitAnswers}
          />
        </Container>
      )}
    </div>
  );
}

export default translate(Spm);

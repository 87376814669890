import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";
import request from "./utils/Request";
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE
  });
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router>
    <SWRConfig value={{ fetcher: (...args) => request.get(...args) }}>
      <App />
    </SWRConfig>
  </Router>,
  rootElement
);

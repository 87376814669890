import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { translate, t } from "react-switch-lang";

const NoUserInternalModal = ({ isOpen, onRight }) => {
  return (
    <Modal
      isOpen={isOpen}
      className="modal-md"
      backdropClassName="back-home"
      style={{ marginTop: "10vh" }}
    >
      <ModalBody>
        <div className="row justify-content-center mt-2">
          <div className="col-12 mb-4">
            <div className="text-center" style={{ borderRadius: "5px" }}>
              <i className="fa fa-3x fa-exclamation-triangle mb-2 text-danger" />
              <br />
              <h5 className="my-3" style={{ lineHeight: "1.4em" }}>
                {t('Maaf, kamu tidak terdaftar pada internal assesment.')}
              </h5>
            </div>
          </div>
          <div className="col-12 text-center">
            <Button className="button-netis-color px-3" onClick={onRight}>
              <b>{"Oke"}</b>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default translate(NoUserInternalModal);

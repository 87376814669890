import React from "react";
import { Nav, Container, Button } from "reactstrap";
import PropTypes from "prop-types";
import { AppNavbarBrand } from "@coreui/react";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../../../../config/Services/Actions/auth";
import { translate } from "react-switch-lang";
import logo from "../../../../../assets/img/logo_blue.png";
import icon from "../../../../../assets/img/logo_blue.png";
import { Link, useLocation } from "react-router-dom";
import { setPrevPath } from "../../../../../config/Services/Actions/ui";
const DefaultGeneralHeader = (props) => {
  // eslint-disable-next-line
  const { t } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <>
      <Container>
        <Link to="/" onClick={() => dispatch(setPrevPath({ url: null }))}>
          <AppNavbarBrand
            className="mr-auto"
            full={{ src: logo, alt: "Skillana Logo", width: 130 }}
            minimized={{ src: icon, alt: "Skillana Icon", width: 120 }}
            style={{ cursor: "pointer" }}
          />
        </Link>
        <Nav className="ml-auto" navbar>
          <Link
            to={{ pathname: "/login", state: { prevPath: location.pathname } }}
          >
            <Button className="btn-akun" style={{ border: "none" }}>
              {t("General.account")}
            </Button>
          </Link>
        </Nav>
      </Container>
    </>
  );
};

DefaultGeneralHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(({ user }) => ({ user }), { logout })(
  translate(DefaultGeneralHeader)
);

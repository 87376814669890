import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { t, translate } from "react-switch-lang";
import { Button, Col, Row, Spinner } from "reactstrap";
import { logout } from "../../../../../config/Services/Actions/auth";
import requestAuth from "../../../../../utils/RequestAuth";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import FormikInput from "../../../../../utils/FormikInput";

export const ChangePassword = (props) => {
  const formValues = {
    currentPass: "",
    confirmPass: "",
    newPass: "",
  };

  const formValidate = (values) => {
    let errors = {};

    const validator = new RegExp(/^(?=.*\d)(?=.*[a-z]).{8,100}$/);
    if (values.newPass !== values.confirmPass) {
      errors.confirmPass = t("Register.notMatchPassword");
    } else if (values.newPass.length < 8) {
      errors.newPass = t("Register.passwordMinimum");
    } else if (!validator.test(values.newPass)) {
      errors.newPass = t("Register.passwordMinimum");
    }
    return errors;
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    const dataObject = {
      current: values.currentPass,
      new: values.newPass,
    };
    await requestAuth
      .post("/changepassword", dataObject)
      .then((res) => {
        // if (res.response.data === 200) {
        try {
          props.logout();
        } catch (err) {
          window.location.reload();
        } finally {
          setSubmitting(false);
        }
        // }
      })
      .catch((err) => {
        toast.error(JSON.stringify(err.response.data.message), {
          autoClose: 3000,
        });
        setSubmitting(false);
      });
  };
  return (
    <>
      <h5 className="content-sub-title mb-4">{t("General.changePassword")}</h5>
      <Formik
        initialValues={formValues}
        validate={formValidate}
        onSubmit={formSubmit}
        render={({ isSubmitting }) => (
          <Form>
            <>
              <Row>
                <div className="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 ">
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="password"
                      label={t("General.oldPassword")}
                      name="currentPass"
                      id="currentPass"
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="password"
                      label={t("General.newPassword")}
                      name="newPass"
                      id="newPass"
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0">
                    <Field
                      type="password"
                      label={t("General.repassword")}
                      name="confirmPass"
                      id="confirmPass"
                      component={FormikInput}
                    />
                  </Col>
                  <Col sm="12" className="mb-3 p-0 d-flex justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        className="btn-main"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Spinner color="light" size="sm" />
                        ) : (
                          t("General.changePassword")
                        )}
                      </Button>
                    </div>
                  </Col>
                </div>
              </Row>
            </>
          </Form>
        )}
      />
    </>
  );
};

export default connect(({ user }) => ({ user }), { logout })(
  withRouter(translate(ChangePassword))
);

import React from "react";
import { Row, Col, Container } from "reactstrap";
import Color from "../../../../utils/Colors";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";

export default translate(function FooterLandingPage(props) {
  // const [isScroll, setIsScroll] = useState(true);
  // useEffect(() => {
  //     if (isScroll) {
  //         window.addEventListener("scroll", () => {
  //             if (window.scrollY > 0) {
  //                 if (!document.getElementsByTagName('nav')[0].classList.contains('onScroll')) {
  //                     document.getElementsByTagName('nav')[0].classList.add('onScroll')
  //                 }
  //             } else {
  //                 if (document.getElementsByTagName('nav')[0].classList.contains('onScroll')) {
  //                     document.getElementsByTagName('nav')[0].classList.remove('onScroll')
  //                 }
  //             }
  //         })
  //     }
  // }, [isScroll]);
  const { t } = props;
  const goToLink = (url) => {
    if (
      url === "/privacy-policy" ||
      url === "/terms-condition" ||
      url === "/faq"
    ) {
      window.location.replace(url);
    } else {
      window.open(url, "_blank");
    }
  };
  return (
    <div className="footer">
      <Container>
        <Row className="pb-3" style={{ borderBottom: "0.5px solid #fff" }}>
          <Col sm="4">
            <p>
              <img
                src={require("../../../../assets/img/logo_white.png")}
                alt="logo"
                style={{ height: 40 }}
              />
            </p>
            <p
              style={{ color: Color.white, fontSize: 13 }}
              className="text-justify"
            >
              {t("LandingPage.skillanaDesc")}
            </p>
            <div className="line mb-3"></div>
          </Col>
          <Col sm="1"></Col>
          <Col sm="3" style={{ marginBottom: "2rem" }}>
            <ul className="footer-list">
              <h5 style={{ color: Color.white }}>
                <b>{t("LandingPage.information")}</b>
              </h5>
              <Link
                className="navtext"
                to="/privacy-policy"
                onClick={() => goToLink("/privacy-policy")}
              >
                <li>{t("LandingPage.privacyPolicy")}</li>
              </Link>
              <Link
                className="navtext"
                to="/terms-condition"
                onClick={() => goToLink("/terms-condition")}
              >
                <li>{t("LandingPage.termsCondition")}</li>
              </Link>
              <Link
                className="navtext"
                to="/faq"
                onClick={() => goToLink("/faq")}
              >
                <li>{t("LandingPage.FAQ")}</li>
              </Link>
            </ul>
          </Col>

          <Col sm="4">
            <h5 style={{ color: Color.white }}>
              <b>PT Widya Indonesia Sejahtera</b>
            </h5>
            <Row>
              <Col xs="1">
                <i
                  className="fa fa-map-marker"
                  style={{ color: Color.white, fontSize: 13 }}
                ></i>
              </Col>
              <Col xs="11">
                <p style={{ color: Color.white, fontSize: 13 }}>
                  Gg. Pusponyidro No.12B, Muja Muju
                  <br />
                  Kec. Umbulharjo, Kota Yogyakarta
                  <br />
                  Daerah Istimewa Yogyakarta 55165
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <i
                  className="fa fa-whatsapp"
                  style={{ color: "white", fontSize: 15 }}
                ></i>
              </Col>
              <Col xs="11">
                <Link
                  to="#"
                  onClick={() => goToLink(`https://wa.me/${process.env.REACT_APP_WA_ADMIN}`)}
                >
                  <p style={{ color: Color.white, fontSize: 13 }}>
                    +62 813 2675 4339
                  </p>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <i
                  className="fa fa-phone"
                  style={{ color: Color.white, fontSize: 13 }}
                ></i>
              </Col>
              <Col xs="11">
                <p style={{ color: Color.white, fontSize: 13 }}>0274 4542237</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <i
                  className="fa fa-envelope"
                  style={{ color: Color.white, fontSize: 13 }}
                ></i>
              </Col>
              <Col xs="11">
                <Link
                  to="#"
                  onClick={() => goToLink("mailto:info@skillana.id")}
                >
                  <p style={{ color: Color.white, fontSize: 13 }}>
                    info@skillana.id
                  </p>
                </Link>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12">
                <img
                  src={require("../../../../assets/img/fb.svg")}
                  alt="fb"
                  style={{ width: "30px", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() =>
                    goToLink(
                      "https://www.facebook.com/Skillana-647183592562677/?modal=admin_todo_tour"
                    )
                  }
                />
                {/* <img src={require("../../../assets/img/twt.svg")} alt="twt" style={{ width: '30px' }} className="mr-2" /> */}
                <img
                  src={require("../../../../assets/img/ig.png")}
                  alt="ig"
                  style={{ width: "30px", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() =>
                    goToLink("https://www.instagram.com/skillana.id/")
                  }
                />
                <img
                  src={require("../../../../assets/img/linkedin.png")}
                  alt="linkedin"
                  style={{ width: "30px", cursor: "pointer", color: "red" }}
                  className="mr-2"
                  onClick={() =>
                    goToLink("https://www.linkedin.com/company/widyaskilloka/")
                  }
                />
                {/* <img src={require("../../../assets/img/linkedin.svg")} alt="youtube" style={{ width: '30px' }} className="mr-2" /> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12 text-center mt-3">
            <p style={{ color: Color.white, fontSize: 12, margin: 0 }}>
              @ 2021 PT Widya Indonesia Sejahtera. All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

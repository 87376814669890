import React, { useState, useEffect, Fragment } from "react";
import CaptureLoading from "../../../components/atoms/CaptureLoading";
import { Button, Container, Row, Col, Spinner } from "reactstrap";
import request from "../../../utils/Request";
// import requestGeneral from "../../../utils/RequestGeneral";
// import NoComplete from "../../Pages/Menu/Assessment/NoCompleteProfile";
import ModalPhoto from "./ModalTakePhoto";
import TakePhoto from "./TakePhoto";
import ResultAssessmentFingerprint from "../ResultAssessment/ResultAssessmentFingerprint";
import AffirmationModal from "../../../components/atoms/AffirmationModal";
import FingerprintLoading from "../SkeletonLoading/Menu/Assesment/FingerprintLoading";
import { isMobile, isBrowser } from "react-device-detect";
import Hint from "../../../components/atoms/Hint";
import { translate, t } from "react-switch-lang";
function Fingerprint() {
  const [loading, setLoading] = useState(true);
  // const [isNotCompleteData, setNotCompleteData] = useState(false);
  const [isDetect, setIsDetect] = useState(true);
  const [isNotSupport, setIsNotSupport] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [notComplete, setNotComplete] = useState(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [title, setTitle] = useState(null);
  const [result, setResult] = useState([]);
  const [success, setSuccess] = useState(false);
  const [statusModal, setStatusModal] = useState("detailPhoto");
  const [fingers, setFingers] = useState([]);
  const [dataFingers, setDataFingers] = useState([]);
  const listFingers = [
    t("Assessment.leftThumbs"),
    t("Assessment.leftIndexFinger"),
    t("Assessment.leftMiddleFinger"),
    t("Assessment.leftRingFinger"),
    t("Assessment.leftLittleFinger"),
    t("Assessment.rightThumbs"),
    t("Assessment.rightIndexFinger"),
    t("Assessment.rightMiddleFinger"),
    t("Assessment.rightRingFinger"),
    t("Assessment.rightLittleFinger"),
  ];
  const listFingersMobile = [
    t("Assessment.leftThumbs"),
    t("Assessment.rightThumbs"),
    t("Assessment.leftIndexFinger"),
    t("Assessment.rightIndexFinger"),
    t("Assessment.leftMiddleFinger"),
    t("Assessment.rightMiddleFinger"),
    t("Assessment.leftRingFinger"),
    t("Assessment.rightRingFinger"),
    t("Assessment.leftLittleFinger"),
    t("Assessment.rightLittleFinger"),
  ];
  useEffect(() => {
    let type;
    if (isMobile) {
      type = "mobile";
    } else {
      type = "web";
    }
    request
      .get("/assessment/test/fingerprint", { headers: { type: type } })
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setFingers(res.data.data);
        }
      })
      .catch((err) => { })
      .finally(() => setLoading(false))
    // requestGeneral
    //   .get(`/v2/users/validate-standart`)
    //   .then((res) => {
    //     request
    //       .get("/assessment/test/fingerprint", { headers: { type: type } })
    //       .then((res) => {
    //         if (res.data?.data) {
    //           setLoading(false);
    //           setFingers(res.data.data);
    //         }
    //       })
    //       .catch((err) => {});
    //   })
    //   .catch((err) => {
    //     if (err.response?.status === 422) {
    //       setNotCompleteData(true);
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       return Promise.reject(err);
    //     }
    //   });
  }, []);
  function detectDevice() {
    if (isDetect) {
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        // Request the camera.
        navigator.getUserMedia(
          // Constraints
          {
            video: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
            setLoading(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
            setLoading(false);
          }
        );
      } else if (navigator.mediaDevices.getUserMedia) {
        // Request the camera.
        navigator.mediaDevices.getUserMedia(
          // Constraints
          {
            video: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
            setLoading(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
            setLoading(false);
          }
        );
      } else {
        setIsDetect(false);
        setIsNotSupport(true);
        setLoading(false);
      }
    }
  }
  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  function ModalTakePhoto(data, title) {
    setTitle(title);
    setDataFingers(data);
    setStatusModal("getPhoto");
    setModal(true);
  }

  function ModalDetailPhoto(data, title) {
    setTitle(title);
    setDataFingers(data);
    setStatusModal("detailPhoto");
    setModal(true);
  }
  function errorSubmit() {
    if (isErrorSubmit) {
      return (
        <AffirmationModal
          isOpen={isErrorSubmit}
          title={t("General.failed") + " !"}
          desc={t("Assessment.notRecordFinger")}
          titleButton={t("General.tryAgain")}
          toggleOpen={() => {
            setIsErrorSubmit(true);
          }}
          confirmation={() => {
            setIsErrorSubmit(false);
          }}
        />
      );
    }
  }

  function notCompleteSubmit() {
    if (notComplete) {
      return (
        <AffirmationModal
          isOpen={notComplete}
          title="Oops !"
          desc={t("Assessment.recordIncompleteFinger")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setNotComplete(true);
          }}
          confirmation={() => {
            setNotComplete(false);
          }}
        />
      );
    }
  }

  async function submitFingerPrint() {
    window.scrollTo(0, 0);
    setLoadingPopup(true);
    request
      .post(`/assessment/test/result_fingerprint`)
      .then((res) => {
        setSuccess(true);
        setResult(res.data);
        setLoadingPopup(false);
      })
      .catch((err) => {
        setLoadingPopup(false);
        if (err?.response?.status === 422) {
          if (err?.response?.data?.type === "NO_COMPLETE") {
            setNotComplete(true);
          }
        } else {
          setIsErrorSubmit(true);
        }
      });
  }
  function showNotSupport() {
    if (isNotSupport) {
      return (
        <Hint
          onClick={() => {
            window.location.reload();
          }}
          visible={isNotSupport}
          title="Error !"
          img="notif_cam.png"
          desc={t("Assessment.accessCamera")}
          actionTitle={t("General.tryAgain")}
        />
      );
    }
  }
  return (
    <Fragment>
      {detectDevice()}
      {errorSubmit()}
      {notCompleteSubmit()}
      {showNotSupport()}
      {loading ? (
        <Container>
          <FingerprintLoading />
        </Container>
        // ) : isNotCompleteData ? (
        //   <NoComplete />
      ) : success ? (
        <Container>
          <ResultAssessmentFingerprint result={result} back={true} />
        </Container>
      ) : statusModal === "detailPhoto" ? (
        <>
          <Container>
            <Row>
              <Col sm="1"></Col>
              <Col
                sm="10 mt-4 mb-4 pt-4 pb-4"
                style={{
                  boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                }}
              >
                <div className="row">
                  {fingers &&
                    fingers.map((data, key) => {
                      return (
                        <div
                          className="col-sm-5ths text-center mb-4 grid-container"
                          key={key}
                        >
                          {data.image.raw ? (
                            <>
                              <div
                                className="box-finger"
                                onClick={() =>
                                  ModalDetailPhoto(
                                    data,
                                    isBrowser
                                      ? listFingers[key]
                                      : listFingersMobile[key]
                                  )
                                }
                              >
                                <img
                                  src={require("../../../assets/img/finger/" +
                                    data.finger +
                                    "_" +
                                    data.side +
                                    ".png")}
                                  alt={
                                    isBrowser
                                      ? listFingers[key]
                                      : listFingersMobile[key]
                                  }
                                  onLoad={() => setLoadImg(true)}
                                  className="img-icon-finger"
                                />
                                <div className="overlay-success-fingerprint">
                                  <img
                                    src={require("../../../assets/img/finger/check.png")}
                                    alt={t("General.success")}
                                    className="img-icon-check"
                                  />
                                </div>
                              </div>
                              {loadImg ? null : <Spinner />}
                            </>
                          ) : (
                            <div
                              className="box-finger"
                              onClick={() =>
                                ModalTakePhoto(
                                  data,
                                  isBrowser
                                    ? listFingers[key]
                                    : listFingersMobile[key]
                                )
                              }
                            >
                              <img
                                src={require("../../../assets/img/finger/" +
                                  data.finger +
                                  "_" +
                                  data.side +
                                  ".png")}
                                alt={
                                  isBrowser
                                    ? listFingers[key]
                                    : listFingersMobile[key]
                                }
                                className="img-icon-finger"
                              />
                            </div>
                          )}
                          <p className="mb-1">
                            {isBrowser
                              ? listFingers[key]
                              : listFingersMobile[key]}
                          </p>
                          {data.type ? (
                            <>
                              <button
                                className="success-photo-bg pt-2 pb-2"
                                onClick={() =>
                                  ModalDetailPhoto(
                                    data,
                                    isBrowser
                                      ? listFingers[key]
                                      : listFingersMobile[key]
                                  )
                                }
                              >
                                <b>
                                  <i className="fa fa-eye mr-2"></i>Detail
                                </b>
                              </button>
                            </>
                          ) : (
                            <button
                              className="take-photo-bg pt-2 pb-2"
                              onClick={() =>
                                ModalTakePhoto(
                                  data,
                                  isBrowser
                                    ? listFingers[key]
                                    : listFingersMobile[key]
                                )
                              }
                            >
                              <b>
                                <i className="fa fa-camera mr-2"></i>
                                {t("General.takePhoto")}
                              </b>
                            </button>
                          )}
                        </div>
                      );
                    })}
                  <br />
                  <div className="col-sm-12">
                    <div className="text-center">
                      <Button
                        className="btn btn-info btn-shadow"
                        style={{ backgroundColor: "#5AADF7", color: "#fff" }}
                        onClick={submitFingerPrint}
                      >
                        <b>
                          <i className="fa fa-paper-plane mr-2"></i>{" "}
                          {t("Assessment.lookResultAssessment")}
                        </b>
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="1"></Col>
            </Row>
            {modal ? (
              <ModalPhoto
                title={title}
                isOpen={modal}
                dataFingers={dataFingers}
                toggle={() => {
                  setModal(!modal);
                  setTitle(null);
                  setDataFingers([]);
                }}
                statusModal={statusModal}
                updateFingers={(fingersParams) => setFingers(fingersParams)}
                changeToTakePhoto={(status) => setStatusModal(status)}
              />
            ) : null}
          </Container>
        </>
      ) : (
        <TakePhoto
          title={title}
          dataFingers={dataFingers}
          toggle={() => {
            setModal(!modal);
            setTitle(null);
            setDataFingers([]);
          }}
          statusModal={statusModal}
          updateFingers={(fingersParams) => setFingers(fingersParams)}
          changeToTakePhoto={(status) => setStatusModal(status)}
        />
      )}
    </Fragment>
  );
}

export default translate(Fingerprint);

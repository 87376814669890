export const data = [
  {
    name: "Gratis",
    desc: "Akses Skillana secara gratis",
    price: null,
    btn: "Lanjutkan akses gratis",
    check: ["mbti", "disc", "fingerprint", "video"],
    isPremium: false,
  },
  {
    name: "Premium",
    desc: "Ketahui Hasil Tes Kamu lebih detail dengan membeli bundling premium",
    price: "Rp 99.000",
    btn: "Beli Sekarang",
    check: [
      "mbti",
      "papikostick",
      "disc",
      "msdt",
      "businessInsight",
      "agility",
      "fingerprint",
      "video",
    ],
    isPremium: true,
  },
];

export const assesmentData = [
  "mbti",
  "disc",
  "fingerprint",
  "video",
  "msdt",
  "papikostick",
  "businessInsight",
  "agility",
];

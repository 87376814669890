import { AppNavbarBrand } from "@coreui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import logo from "../../../../../assets/img/logo_blue.png";
import { logout } from "../../../../../config/Services/Actions/auth";
import requestAuth from "../../../../../utils/RequestAuth";
// import ShortGuideUser from "../../../../Templates/ShortGuideUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconAward } from "../../../../../assets/img/icon-award.svg";
import ConfirmationModal from "../../../../../components/atoms/ConfirmationModal";
import moment from "../../../../../utils/Moment";
import langUtils from "../../../../../utils/SettingLanguage/index";
import { setPrevPath } from "../../../../../config/Services/Actions/ui";

const DefaultHeaderHome = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // changeLanguage = (id) => (e) => {
  //   e.preventDefault()
  //   this.handleSetLanguage(id);
  //   // sessionStorage.setItem("language", id);
  //   this.setState({
  //     modalLang: !this.state.modalLang,
  //   });
  //   window.location.reload();
  // }
  // handleSetLanguage = (key) => {
  //   setLanguage(key);
  // };
  // modalChangeLang = () => {
  //   this.setState({
  //     modalLang: !this.state.modalLang,
  //   });
  // }

  const logout = async (e) => {
    e.preventDefault();
    try {
      props.logout();
    } catch (err) {
      window.location.reload();
    }
  };

  const onSelectFlag = (countryCode) => {
    setLoading(true);
    let lang = new FormData();
    lang.append("language", countryCode.toLowerCase());
    requestAuth
      .post("/language", lang)
      .then((res) => {
        handleSetLanguage(countryCode);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(t("failedChangeLanguage"));
        setLoading(false);
      });
  };
  const handleSetLanguage = (key) => {
    langUtils.setLanguage(key);
    moment.locale(key.toLowerCase());
  };
  // function Kapital(str) {
  //   return str.replace(/\w\S*/g, function (txt) {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // }

  // onSelectFlag = (countryCode) => {
  //   this.handleSetLanguage(countryCode);
  //   sessionStorage.setItem("language", countryCode);
  // }
  // const handleSetLanguage = (key) => {
  //   setLanguage(key);
  // };
  // eslint-disable-next-line
  const { t } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [hintNumber, setHintNumber] = useState(0);
  const open = () => {
    setIsOpen(!isOpen);
    setHintNumber(1);
  };
  function popUp() {
    if (isOpen) {
      if (hintNumber === 1) {
        return (
          <ConfirmationModal
            guide={1}
            isOpen={isOpen}
            title={t("UserGuide.helpWelcome")}
            desc={t("UserGuide.helpWelcome2")}
            images={true}
            titleLeft={t("UserGuide.helpNextTime")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(2);
            }}
            onPressLeft={() => setIsOpen(false)}
          />
        );
      }
      if (hintNumber === 2) {
        return (
          <ConfirmationModal
            guide={2}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreSkillana")}
            desc={t("UserGuide.helpReady")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(3);
            }}
            onPressLeft={() => setHintNumber(1)}
          />
        );
      }
      if (hintNumber === 3) {
        return (
          <ConfirmationModal
            guide={3}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreFirstStep")}
            desc={t("UserGuide.helpExploreFirstStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(4);
            }}
            onPressLeft={() => setHintNumber(2)}
          />
        );
      }
      if (hintNumber === 4) {
        return (
          <ConfirmationModal
            guide={4}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreSecondStep")}
            desc={t("UserGuide.helpExploreSecondStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(5);
            }}
            onPressLeft={() => setHintNumber(3)}
          />
        );
      }
      if (hintNumber === 5) {
        return (
          <ConfirmationModal
            guide={5}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreThirdStep")}
            desc={t("UserGuide.helpExploreThirdStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(6);
            }}
            onPressLeft={() => setHintNumber(4)}
          />
        );
      }
      if (hintNumber === 6) {
        return (
          <ConfirmationModal
            guide={6}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreLastStep")}
            desc={t("UserGuide.helpExploreLastStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpOk")}
            center={true}
            onPressRight={() => {
              setIsOpen(false);
            }}
            onPressLeft={() => setHintNumber(5)}
          />
        );
      }
    }
  }

  return (
    <React.Fragment>
      {popUp()}
      <Link to="/home" onClick={() => dispatch(setPrevPath({ url: null }))}>
        <AppNavbarBrand
          full={{ src: logo, alt: "Skillana Logo", width: 130 }}
          minimized={{ src: logo, alt: "Skillana Icon", width: 120 }}
          style={{ cursor: "pointer" }}
        />
      </Link>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            {" "}
            {loading ? <Spinner size="sm" /> : langUtils.getLanguage()}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>{t("General.chooseLanguage")}</DropdownItem>
            <DropdownItem onClick={() => onSelectFlag("ID")}>
              Bahasa Indonesia (ID)
            </DropdownItem>
            <DropdownItem onClick={() => onSelectFlag("EN")}>
              English (US)
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav direction="down" data-tut="logo-user">
          <DropdownToggle nav>
            {props?.user?.avatar === null ? (
              <div className="round-100 bg-user" />
            ) : (
              <div
                className="round-100"
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_DOMAIN + "" + props?.user?.avatar
                  })`,
                }}
              ></div>
            )}
          </DropdownToggle>
          {props.isTourOpen ? (
            props.isTourOpen === true ? null : (
              <DropdownMenu right>
                <DropdownItem
                  tag="div"
                  className="mt-2"
                  style={{ border: "0px" }}
                >
                  <div className="d-flex flex-nowrap">
                    <div className="profile-wrapper">
                      {props?.user?.avatar !== null ? (
                        <img
                          src={
                            process.env.REACT_APP_DOMAIN + props?.user?.avatar
                          }
                          alt="profile"
                        />
                      ) : (
                        <div
                          className="round-100 bg-user"
                          style={{
                            width: "66px",
                            height: "66px",
                            border: "0px",
                          }}
                        />
                      )}
                      {props?.user?.isPremium ? (
                        <span className="icon-label-premium">
                          <FontAwesomeIcon icon="crown" />
                        </span>
                      ) : null}
                    </div>
                    <div className="d-flex flex-wrap flex-column align-self-center ml-2">
                      <span className="text-name">{props?.user?.fullName}</span>
                      <div className="d-flex flex-row align-items-center">
                        <IconAward />
                        <span className="text-class-finish ml-1">5/5</span>
                        <span className="text-desc ml-1">
                          {t("General.testCompleted")}
                        </span>
                      </div>
                    </div>
                  </div>
                </DropdownItem>
                <Link to="home" style={{ textDecoration: "none" }}>
                  <DropdownItem className="mt-2 rounded btn-profil">
                    <div className="text-center font-poppins font-weight-semibold">
                      {t("General.dashboard")}
                    </div>
                  </DropdownItem>
                </Link>
                {props?.user?.isPremium ? null : (
                  <Link
                    to={{
                      pathname: "/assessment",
                      state: { showModalPremium: true },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <DropdownItem
                      className="mt-2 rounded"
                      style={{
                        background: "#03A100",
                        border: "0px",
                        color: "#fff",
                      }}
                    >
                      <div className="text-center font-poppins font-weight-semibold">
                        {t("General.upgradePremium")}
                      </div>
                    </DropdownItem>
                  </Link>
                )}
                <div className="d-flex flex-nowrap flex-row justify-content-start mt-2 mb-3">
                  <Button className="btn-help w-100 mr-2" onClick={open}>
                    <FontAwesomeIcon icon="question" /> {t("General.hint")}
                  </Button>
                  <Button className="btn-logout w-100" onClick={logout}>
                    <FontAwesomeIcon icon="power-off" /> {t("General.logout")}
                  </Button>
                </div>
              </DropdownMenu>
            )
          ) : (
            <DropdownMenu right className="mt-2 px-2">
              <DropdownItem
                tag="div"
                className="mt-2"
                style={{ border: "0px" }}
              >
                <div className="d-flex flex-nowrap">
                  <div className="profile-wrapper">
                    {props?.user?.avatar !== null ? (
                      <img
                        src={process.env.REACT_APP_DOMAIN + props?.user?.avatar}
                        alt="profile"
                      />
                    ) : (
                      <div
                        className="round-100 bg-user"
                        style={{ width: "66px", height: "66px", border: "0px" }}
                      />
                    )}
                    {props?.user?.isPremium ? (
                      <span className="icon-label-premium">
                        <FontAwesomeIcon icon="crown" />
                      </span>
                    ) : null}
                  </div>
                  <div className="d-flex flex-wrap flex-column align-self-center ml-2">
                    <span className="text-name">{props?.user?.fullName}</span>
                    <div className="d-flex flex-row align-items-center">
                      <IconAward />
                      <span className="text-class-finish ml-1">{`${
                        props?.user?.assessmentDone ?? "-"
                      }/8`}</span>
                      <span className="text-desc ml-1">
                        {t("General.testCompleted")}
                      </span>
                    </div>
                  </div>
                </div>
              </DropdownItem>
              <Link to="/home" style={{ textDecoration: "none" }}>
                <DropdownItem className="mt-2 rounded btn-profil">
                  <div className="text-center font-poppins font-weight-semibold">
                    {t("General.dashboard")}
                  </div>
                </DropdownItem>
              </Link>
              {props?.user?.isPremium ? null : (
                <Link
                  to={{
                    pathname: "/assessment",
                    state: { showModalPremium: true },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <DropdownItem
                    className="mt-2 rounded"
                    style={{
                      background: "#03A100",
                      border: "0px",
                      color: "#fff",
                    }}
                  >
                    <div className="text-center font-poppins font-weight-semibold">
                      {t("General.upgradePremium")}
                    </div>
                  </DropdownItem>
                </Link>
              )}
              <div className="d-flex flex-nowrap flex-row justify-content-start mt-2 mb-3">
                <Button className="btn-help w-100 mr-2" onClick={open}>
                  <FontAwesomeIcon icon="question" /> {t("General.hint")}
                </Button>
                <Button className="btn-logout w-100" onClick={logout}>
                  <FontAwesomeIcon icon="power-off" /> {t("General.logout")}
                </Button>
              </div>
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </Nav>
    </React.Fragment>
  );
};

DefaultHeaderHome.propTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(({ user }) => ({ user }), { logout })(
  withRouter(translate(DefaultHeaderHome))
);

import EN from "./Language/en.json";
import ID from "./Language/id.json";
import {
  setLanguage as rslSetLanguage,
  getLanguage as rslGetLanguage,
  setDefaultTranslations,
  setDefaultLanguage,
} from "react-switch-lang";

import moment from "../Moment/index";
export default {
  languageChangedListeners: [],

  init() {
    setDefaultTranslations({ EN, ID });
    setDefaultLanguage("ID");
    const currentLang =
      localStorage.getItem("language") ||
      window.navigator.userLanguage ||
      window.navigator.language ||
      "ID";
    if (currentLang) {
      // console.log(currentLang);
      this.setLanguage(currentLang);
    }
  },

  setLanguage(lang) {
    rslSetLanguage(lang);
    moment.locale(lang.toLowerCase());
    localStorage.setItem("language", lang);
    this.languageChangedListeners.forEach((listener) => {
      listener.call(this, this.getLanguage());
    });
  },

  getLanguage() {
    return rslGetLanguage();
  },

  onLanguageChanged(listener) {
    this.languageChangedListeners.push(listener);
  },
};

import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
import FormikInput from "../../../../../../utils/FormikInput";
import request from "../../../../../../utils/Request";
import requestGeneral from "../../../../../../utils/RequestGeneral";
import langUtils from "../../../../../../utils/SettingLanguage/index";

toast.configure();
let formValues;
let countriesList = [];
const ProfileProfesional = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [editProfilePro, setEditProfilePro] = useState(false);
  const [citiesIdentity, setCitiesIdentity] = useState([]);
  const [districtsIdentity, setDistrictsIdentity] = useState([]);
  const [villagesIdentity, setVillagesIdentity] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [state, setState] = useState({
    citizenship: {
      value: null,
      label: null,
    },
    countryIdentity: {
      value: null,
      label: null,
    },
    provinceIdentity: {
      value: null,
      label: null,
    },
    cityIdentity: {
      value: null,
      label: null,
    },
    districtIdentity: {
      value: null,
      label: null,
    },
    villageIdentity: {
      value: null,
      label: null,
    },
    postalCodeIdentity: "",
    addressIdentity: "",
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const res1 = await request.get("/location/countries");
        const res2 = await requestGeneral.get("/v2/users/profile/professional");
        const res3 = await request.get("/location/provinces?countryId=1");

        let province = {};
        let provincesLists = [];
        res3.data.data.map((data) => {
          province = {
            value: data.id,
            label: kapital(data.name),
          };
          return provincesLists.push(province);
        });
        setProvincesList(provincesLists);
        let country = {};
        res1.data.data.map((data) => {
          country = {
            value: data.id,
            label: data.name,
          };
          return countriesList.push(country);
        });
        const userData = res2.data.data;

        formValues = {
          description: userData.description,
          postalCodeIdentity:
            userData.identityAddress.postalCode === "0"
              ? null
              : userData.identityAddress.postalCode,
          addressIdentity: userData.identityAddress.address,
        };
        setState((prev) => ({
          ...prev,
          citizenship: {
            value: userData.citizenship.id,
            label: userData.citizenship.name,
          },
          countryIdentity: {
            value: 1,
            label: "Indonesia",
          },
          provinceIdentity: {
            value: userData.identityAddress.province.id,
            label: userData.identityAddress.province.name,
          },
          cityIdentity: {
            value: userData.identityAddress.city.id,
            label: userData.identityAddress.city.name
              ? userData.identityAddress.city.name
                  .replace("KABUPATEN ", "")
                  .replace("KOTA ", "")
              : null,
          },
          districtIdentity: {
            value: userData.identityAddress.district.id,
            label: userData.identityAddress.district.name,
          },
          villageIdentity: {
            value: userData.identityAddress.village.id,
            label: userData.identityAddress.village.name,
          },
        }));
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
    fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langUtils.getLanguage()]);

  const onChangeCitizenship = (value) => {
    setState({
      ...state,
      citizenship: value,
    });
  };

  function kapital(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const changePhrase = (name) => {
    if (langUtils.getLanguage() === "ID") {
      return kapital(
        name
          .replace("KABUPATEN ", `${t("Profile.lDistrict")} `)
          .replace("KOTA ", `${t("Profile.lCity")} `)
      );
    } else {
      const identity = name.split(" ")[0];
      const temp = name.replace("KABUPATEN ", "").replace("KOTA ", "");
      if (identity.toLowerCase() === "kota") {
        return kapital(temp.concat(" ", t("Profile.lCity")));
      } else {
        return kapital(temp.concat(" ", t("Profile.lDistrict")));
      }
    }
  };

  const getUserCity = async (id) => {
    if (id !== null || id !== 0) {
      try {
        const res = await request.get("/location/cities?provinceId=" + id);
        let citiesList = [];
        let city = {};
        res.data.data.map((data) => {
          city = {
            value: data.id,
            label: changePhrase(data.name),
          };
          return citiesList.push(city);
        });
        return citiesList;
      } catch (err) {}
    }
  };
  const getUserDistrict = async (id) => {
    if (id !== null || id !== 0) {
      try {
        const res = await request.get("/location/districts?cityId=" + id);
        let districtsList = [];
        let district = {};
        res.data.data.map((data) => {
          district = {
            value: data.id,
            label: kapital(data.name),
          };
          return districtsList.push(district);
        });
        return districtsList;
      } catch (err) {}
    }
  };
  const getUserVillage = async (id) => {
    if (id !== null || id !== 0) {
      try {
        const res = await request.get("/location/villages?districtId=" + id);
        let villagesList = [];
        let village = {};
        res.data.data.map((data) => {
          village = {
            value: data.id,
            label: kapital(data.name),
          };
          return villagesList.push(village);
        });
        return villagesList;
      } catch (err) {}
    }
  };

  const toggleEditProfilePro = () => {
    setEditProfilePro(!editProfilePro);
  };
  const formValidate = (values) => {
    let errors = {};
    if (
      values.postalCodeIdentity ||
      typeof values.postalCodeIdentity === "number"
    ) {
      if (!/^[0-9]+$/.test(values.postalCodeIdentity)) {
        errors.postalCodeIdentity = t("Hanya dapat diisi dengan angka");
      }
      if (values.postalCodeIdentity.toString().length !== 5) {
        errors.postalCodeIdentity = t("Profile.postalCodeNotValid");
      }
    }
    // if (values.addressIdentity === null || values.addressIdentity === "") {
    //   return toast.error("Alamat pada wajib diisi", {
    //     autoClose: 3000,
    //   });
    // }
    return errors;
  };
  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    // if (state.countryIdentity.value === null) {
    //   setSubmitting(false);
    //   return toast.error("Negara wajib diisi", {
    //     autoClose: 3000,
    //   });
    // } else if (state.provinceIdentity.value === null) {
    //   setSubmitting(false);
    //   return toast.error("Provinsi wajib diisi", {
    //     autoClose: 3000,
    //   });
    // } else if (state.cityIdentity.value === null) {
    //   setSubmitting(false);
    //   return toast.error("Kota/Kabupaten wajib diisi", {
    //     autoClose: 3000,
    //   });
    // } else if (state.districtIdentity.value === null) {
    //   setSubmitting(false);
    //   return toast.error("Kecamatan wajib diisi", {
    //     autoClose: 3000,
    //   });
    // } else if (state.villageIdentity.value === null) {
    //   setSubmitting(false);
    //   return toast.error("Desa wajib diisi", {
    //     autoClose: 3000,
    //   });
    // }else if (
    //   state.citizenship.value === null ||
    //   state.citizenship.value === ""
    // ) {
    //   setSubmitting(false);
    //   return toast.error("Kewarganegaraan wajib diisi", { autoClose: 3000 });
    // }
    if (state.provinceIdentity.value) {
      if (state.cityIdentity.value) {
        if (state.districtIdentity.value) {
          if (!state.villageIdentity.value) {
            setSubmitting(false);
            return toast.error(
              `${t("Profile.village")} ${t("General.required")}`
            );
          }
        } else {
          setSubmitting(false);
          return toast.error(
            `${t("Profile.district")} ${t("General.required")}`
          );
        }
      } else {
        setSubmitting(false);
        return toast.error(`${t("Profile.city")} ${t("General.required")}`);
      }
    }
    const dataObject = {
      citizenship: state.citizenship.value,
      identityCountry: 1,
      identityProvince: state.provinceIdentity.value,
      identityCity: state.cityIdentity.value,
      identityDistrict: state.districtIdentity.value,
      identityVillage: state.villageIdentity.value,
      identityPostal: values.postalCodeIdentity,
      identityAddress: values.addressIdentity,
      description: values.description,
      // lastEducation: state.lastEducation.value,
    };
    await requestGeneral
      .put("/v2/users/profile/professional", dataObject)
      .then((res) => {
        props.setUser({
          ...props.user,
          expectedSalary: state.expectedSalary,
        });
        setEditProfilePro(false);
        toast.success(t("General.success"), { autoClose: 3000 });
      })
      .catch((err) => {
        if (err.response.status === 422) {
          toast.error(
            JSON.stringify(err.response.data.errors) || "Error Input Data",
            { autoClose: 3000 }
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChangeProvinceIdentity = (value) => {
    let city = getUserCity(value.value);
    city.then(function (val) {
      setState({
        ...state,
        provinceIdentity: value,
        cityIdentity: { value: null, label: null },
        districtIdentity: { value: null, label: null },
        villageIdentity: { value: null, label: null },
      });
      setCitiesIdentity(val);
      setDistrictsIdentity(null);
      setVillagesIdentity(null);
    });
  };
  const onChangeCityIdentity = (value) => {
    let district = getUserDistrict(value.value);
    district.then(function (val) {
      setState({
        ...state,
        cityIdentity: value,
        districtIdentity: { value: null, label: null },
        villageIdentity: { value: null, label: null },
      });
      setDistrictsIdentity(val);
      setVillagesIdentity(null);
    });
  };
  const onChangeDistrictIdentity = (value) => {
    let village = getUserVillage(value.value);
    village.then(function (val) {
      setState({
        ...state,
        districtIdentity: value,
        villageIdentity: { value: null, label: null },
      });
      setVillagesIdentity(val);
    });
  };
  const onChangeVillageIdentity = (value) => {
    setState({
      ...state,
      villageIdentity: value,
    });
  };

  const cancel = () => {
    window.location.reload();
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <Fragment>
      <Formik
        initialValues={formValues}
        validate={formValidate}
        onSubmit={formSubmit}
        render={({ isSubmitting }) => (
          <Form style={{ display: "inherit" }}>
            <Row>
              <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                <h5 className="content-sub-title mb-0">
                  <b>{t("Profile.professional")}</b>
                </h5>
                {editProfilePro === false ? (
                  <Button
                    style={{ backgroundColor: "#5AADF7" }}
                    className="navtext btn-netis-color"
                    onClick={toggleEditProfilePro}
                  >
                    <i className="fa fa-pencil" style={{ marginRight: 5 }}></i>
                    Edit
                  </Button>
                ) : null}
              </div>
            </Row>
            <Row>
              <Col md="12" className="mb-3" style={{ marginTop: "-15px" }}>
                <FormGroup>
                  <Label htmlFor="country" className="input-label">
                    {t("Profile.citizenship")}
                  </Label>
                  <Select
                    value={state.citizenship}
                    onChange={(value) => onChangeCitizenship(value)}
                    options={countriesList}
                    isDisabled={editProfilePro === false ? true : false}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" className="mb-3" style={{ marginTop: "-15px" }}>
                <Field
                  type="textarea"
                  label={t("Profile.aboutMe")}
                  name="description"
                  id="description"
                  rows="5"
                  placeholder={t("Profile.tellMe")}
                  disabled={editProfilePro === false ? true : false}
                  value={formValues.description}
                  component={FormikInput}
                />
              </Col>
            </Row>
            {/* ALAMAT  */}
            <h6>{t("Profile.address")}</h6>
            <Row>
              <Col md="6" className="mb-3">
                <FormGroup>
                  <Label htmlFor="country" className="input-label">
                    {t("Profile.country")}
                  </Label>
                  <Select
                    value={state.countryIdentity}
                    // onChange={(value) => onChangeCountryIdentity(value)}
                    // options={state.countries}
                    isDisabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="6" className="mb-3">
                <FormGroup>
                  <Label htmlFor="province" className="input-label">
                    {t("Profile.province")}
                  </Label>
                  <Select
                    value={state.provinceIdentity}
                    onChange={(value) => onChangeProvinceIdentity(value)}
                    options={provincesList}
                    isDisabled={editProfilePro === false ? true : false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-3" style={{ marginTop: "-15px" }}>
                <FormGroup>
                  <Label htmlFor="city" className="input-label">
                    {t("Profile.city")}
                  </Label>
                  <Select
                    value={state.cityIdentity}
                    onChange={(value) => onChangeCityIdentity(value)}
                    options={citiesIdentity}
                    isDisabled={editProfilePro === false ? true : false}
                  />
                </FormGroup>
              </Col>
              <Col md="6" className="mb-3" style={{ marginTop: "-15px" }}>
                <FormGroup>
                  <Label htmlFor="district" className="input-label">
                    {t("Profile.district")}
                  </Label>
                  <Select
                    value={state.districtIdentity}
                    onChange={(value) => onChangeDistrictIdentity(value)}
                    options={districtsIdentity}
                    isDisabled={editProfilePro === false ? true : false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-3" style={{ marginTop: "-15px" }}>
                <FormGroup>
                  <Label htmlFor="village" className="input-label">
                    {t("Profile.village")}
                  </Label>
                  <Select
                    value={state.villageIdentity}
                    onChange={(value) => onChangeVillageIdentity(value)}
                    options={villagesIdentity}
                    isDisabled={editProfilePro === false ? true : false}
                  />
                </FormGroup>
              </Col>
              <Col md="6" className="mb-3" style={{ marginTop: "-15px" }}>
                <Field
                  type="text"
                  label={t("Profile.postalCode")}
                  name="postalCodeIdentity"
                  id="postalCodeIdentity"
                  placeholder={t("Profile.postalCode")}
                  disabled={editProfilePro === false ? true : false}
                  value={formValues.postalCodeIdentity}
                  component={FormikInput}
                />
              </Col>
            </Row>
            <Row>
              <div className="col-12" style={{ marginTop: "-15px" }}>
                <FormGroup>
                  <Label htmlFor="addressIdentity" className="input-label">
                    {t("Profile.address")}
                  </Label>
                  <Field
                    type="textarea"
                    name="addressIdentity"
                    id="addressIdentity"
                    rows="3"
                    disabled={editProfilePro === false ? true : false}
                    value={formValues.addressIdentity}
                    component={FormikInput}
                  />
                </FormGroup>
              </div>
            </Row>
            <Row>
              <div className="col-12 d-flex justify-content-end">
                {editProfilePro === true ? (
                  <Fragment>
                    <Button className="btn-white mr-3" onClick={cancel}>
                      {t("General.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#5AADF7", color: "white" }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          <Spinner size="sm" className="mr-2" /> Loading
                        </span>
                      ) : (
                        t("General.save")
                      )}
                    </Button>
                  </Fragment>
                ) : null}
              </div>
            </Row>
          </Form>
        )}
      />
    </Fragment>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};

export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(ProfileProfesional));

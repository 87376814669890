import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import UserDocument from "./DocumentProfile";
// import UserHistory from "./HistoryProfile.js";
// import Preferensi from "./Preferensi";
import InternalModal from "../../../../components/atoms/InternalModal";
// import UserSpm from "./AssessmentProfile/Spm";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import Tour from "reactour";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import request from "../../../../utils/Request";
import requestGeneral from "../../../../utils/RequestGeneral";

// SwiperCore.use([Scrollbar]);
// import SwiperCore, { Scrollbar } from 'swiper';
// import 'swiper/components/scrollbar/scrollbar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/swiper.scss";
import noPhoto from "../../../../assets/img/avatar.png";
import { ReactComponent as IconAward } from "../../../../assets/img/icon-award.svg";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import NoUserInternalModal from "../../../../components/atoms/NoUserInternalModal";
import HistoryInvoices from "../HistoryInvoices";
// import Certification from "./Certification";
import ChangePassword from "./ChangePassword";
import JobPreference from "./MainProfile/JobPreference";
import ProfileProfesional from "./MainProfile/ProfileProfesional";
import ProfileStandart from "./MainProfile/ProfileStandart";
// import ProfileStandart from "./MainProfile/ProfileStandart/V2/ProfileStandart2";
import Riwayat from "./MainProfile/Riwayat";

toast.configure();
const Profile = (props) => {
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(true);
  const [modalSub, setModalSub] = useState(false);
  const { t } = props;
  const prevPath = useSelector((state) => state?.prevPath);
  const history = useHistory();

  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
      .then((res) => {
        setCompany(res?.data?.data?.company_name);
        setJob(res?.data?.data?.name);
        setId(res?.data?.data?.id);
        requestGeneral
          .get(
            "internal/recruitment/internal/user/" +
              localStorage.getItem("referal") +
              "/" +
              res?.data?.data?.companyPaid
          )
          .then((res) => {
            if (!res?.data?.existSkillanaId && res?.data?.isSub) {
              setModal(true);
              setLoading(false);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.isSub) {
              setCompany(null);
              setJob(null);
              setId(null);
              localStorage.removeItem("referal");
              setModal(false);
              setModalSub(true);
              setLoading(false);
            } else {
              setModal(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setModal(false);
        setLoading(false);
      });
  }, []);
  function removeInternal() {
    localStorage.removeItem("referal");
    setModal(false);
  }
  function removeInternalSub() {
    localStorage.removeItem("referal");
    setModalSub(false);
  }
  function regisInternal() {
    setLoadingPopup(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/" + id)
      .then((res) => {
        setLoadingPopup(false);
        setModal(false);
        toast.success("Success");
      })
      .catch((err) => {
        setLoadingPopup(false);
        toast.error("Error");
      });
  }
  useEffect(() => {
    if (props.user) {
      if (props.user.guideProfile === 0) {
        setIsTourOpen(true);
      }
    }
  }, [props.user]);
  const steps = [
    {
      selector: ".step-profile-1",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>
            {t("UserGuide.profileComplement")} (
            <span style={{ color: "red" }}>*</span>){" "}
            {t("UserGuide.profileComplement2")}
          </p>
          <Row>
            <div className="col-12 text-center p-0">
              <Button
                className="mt-2"
                type="submit"
                color="netis-color"
                onClick={() => {
                  disableUserGuide();
                  enableBody();
                  setIsTourOpen(false);
                }}
              >
                {t("General.okey")}
              </Button>
            </div>
          </Row>
        </div>
      ),
    },
  ];
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_profile")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideProfile: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideProfile: 1,
        });
      });
  }
  function logoutInternal() {
    localStorage.removeItem("referal");
    window.location.reload();
  }

  const listTab = (tab) => {
    if (tab === "profil-standar") return <ProfileStandart />;
    else if (tab === "profil-professional") return <ProfileProfesional />;
    else if (tab === "preference-job") return <JobPreference />;
    else if (tab === "history") return <Riwayat />;
    else if (tab === "identity-document") return <UserDocument />;
    // else if (tab === "5") return <Certification />;
    else if (tab === "invoice") return <HistoryInvoices />;
    else if (tab === "change-password") return <ChangePassword />;
    else return <ProfileStandart />;
  };

  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState(
    props.location?.hash ? props.location?.hash?.substring(1) : "profil-standar"
  );
  const [tabContent, setTabContent] = useState(
    props.location?.hash ? (
      listTab(props.location?.hash?.substring(1))
    ) : (
      <ProfileStandart />
    )
  );
  const handleChangeTab = (tab) => {
    setTabContent(listTab(tab));
    setMenu(tab);
    history.replace({ hash: tab });
  };

  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  return (
    <div className="navtext animated fadeIn">
      {!localStorage.getItem("referal") ? (
        <Tour
          steps={steps}
          accentColor={accentColor}
          showButtons={false}
          rounded={5}
          isOpen={isTourOpen}
          disableFocusLock={false}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          onRequestClose={() => {
            disableUserGuide();
            setIsTourOpen(false);
          }}
          showNavigation={false}
        />
      ) : null}

      <Fragment>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Row className="mb-3">
              <NoUserInternalModal
                isOpen={modalSub}
                onRight={removeInternalSub}
              />
              {localStorage.getItem("referal") ? (
                <InternalModal
                  isOpen={modal}
                  company={company}
                  job={job}
                  onLeft={removeInternal}
                  onRight={regisInternal}
                />
              ) : null}
              {!localStorage.getItem("referal") ? (
                <>
                  <Col>
                    <Link to={prevPath ? prevPath : "/home"}>
                      <Button className="btn btn-main">
                        <i className="fa fa-arrow-left mr-2"></i>
                        {t("General.back")}
                      </Button>
                    </Link>
                  </Col>
                </>
              ) : (
                <Col sm="12" className="text-center">
                  <Alert color="info" className="text-center mt-3 mb-4">
                    <h5 className="content-title mb-2">
                      {t("Internal.alert1")} {job} {t("Internal.alert2")}{" "}
                      {company}
                    </h5>
                    <br />
                    <Button
                      className="btn button-outline-red"
                      onClick={logoutInternal}
                    >
                      <i className="fa fa-sign-out mr-2"></i>
                      {t("Internal.logout")} {job}
                    </Button>
                  </Alert>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12" sm="12" md="5" lg="4" xl="3" className="mb-3">
                <div className="d-flex flex-column flex-wrap justify-content-start">
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-column flex-wrap justify-content-center align-items-center card-profile">
                        {props?.user?.avatar !== null ? (
                          <img
                            src={
                              process.env.REACT_APP_DOMAIN + props?.user?.avatar
                            }
                            alt="profile"
                          />
                        ) : (
                          <img src={noPhoto} alt="profile" />
                        )}
                        {props?.user?.isPremium ? (
                          <div className="badge-premium mt-2">
                            <FontAwesomeIcon icon="crown" />
                            <span>Premium Club</span>
                          </div>
                        ) : null}

                        <span className="mt-2 font-poppins font-weight-semibold">
                          {props?.user?.fullName}
                        </span>
                        <span
                          className="mt-2 font-poppins"
                          style={{ color: "#1B568D" }}
                        >
                          {props?.user?.email}
                        </span>
                        <div className="d-flex flex-row align-items-center mt-2">
                          <IconAward />
                          <span className="text-class-finish ml-1">{`${
                            props?.user?.assessmentDone ?? "-"
                          }/8`}</span>
                          <span className="text-desc ml-1">
                            {t("General.testCompleted")}
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="d-flex flex-column flex-wrap justify-content-start profile-menu">
                    <ListGroup>
                      <ListGroupItem onClick={() => setOpen(!open)}>
                        <span className={open ? "active-parent" : ""}>
                          Edit Profil
                        </span>
                      </ListGroupItem>
                      <Collapse isOpen={open} id="1">
                        <ListGroup className="collapse-inside">
                          <ListGroupItem
                            className={
                              menu === "profil-standar" ? "active-nav" : ""
                            }
                            // onClick={() => setMenu("0")}
                            onClick={() => handleChangeTab("profil-standar")}
                          >
                            <span
                              className={
                                menu === "profil-standar" ? "active" : ""
                              }
                            >
                              {t("Profile.standard")}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              menu === "profil-professional" ? "active-nav" : ""
                            }
                            // onClick={() => setMenu("1")}
                            onClick={() =>
                              handleChangeTab("profil-professional")
                            }
                          >
                            <span
                              className={
                                menu === "profil-professional" ? "active" : ""
                              }
                            >
                              {t("Profile.professional")}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              menu === "preference-job" ? "active-nav" : ""
                            }
                            // onClick={() => setMenu("2")}
                            onClick={() => handleChangeTab("preference-job")}
                          >
                            <span
                              className={
                                menu === "preference-job" ? "active" : ""
                              }
                            >
                              {t("Profile.preferensiJob")}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            className={menu === "history" ? "active-nav" : ""}
                            // onClick={() => setMenu("3")}
                            onClick={() => handleChangeTab("history")}
                          >
                            <span
                              className={menu === "history" ? "active" : ""}
                            >
                              {t("Profile.history")}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              menu === "identity-document" ? "active-nav" : ""
                            }
                            // onClick={() => setMenu("4")}
                            onClick={() => handleChangeTab("identity-document")}
                          >
                            <span
                              className={
                                menu === "identity-document" ? "active" : ""
                              }
                            >
                              {t("Profile.identityDocument")}
                            </span>
                          </ListGroupItem>
                        </ListGroup>
                      </Collapse>
                      {/* <ListGroupItem
                        className={menu === "5" ? "active-nav" : ""}
                        onClick={() => setMenu("5")}
                      >
                        <span className={menu === "5" ? "active" : ""}>
                          Download Sertifikat
                        </span>
                      </ListGroupItem> */}
                      <ListGroupItem
                        className={menu === "invoice" ? "active-nav" : ""}
                        // onClick={() => setMenu("6")}
                        onClick={() => handleChangeTab("invoice")}
                      >
                        <span className={menu === "invoice" ? "active" : ""}>
                          {t("Profile.listTransaction")}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        className={
                          menu === "change-password" ? "active-nav" : ""
                        }
                        // onClick={() => setMenu("7")}
                        onClick={() => handleChangeTab("change-password")}
                      >
                        <span
                          className={menu === "change-password" ? "active" : ""}
                        >
                          {t("General.changePassword")}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                </div>
              </Col>
              <Col xs="12" sm="12" md="7" lg="8" xl="9">
                <div className="d-flex flex-wrap">
                  <Card className="w-100">
                    <CardBody>{tabContent}</CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Fragment>
    </div>
  );
};

const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(Profile));

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t, translate } from "react-switch-lang";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import request from "../../../../../utils/Request";
import { assesmentData } from "./PremiumPrice";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import DataNotFound from "../../../../../components/atoms/DataNotFound";
import { convertToRupiah } from "../../../../../utils/ConvertToRupiah";

function ModalPremium({ isOpen, toggle, onHome, togglePayment, listFree }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    request
      .get("package/lists")
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (name) => {
    if (name === "Premium") {
      toggle();
      togglePayment();
    } else {
      if (onHome) {
        toggle();
      } else {
        history.push("/assessment");
      }
    }
  };

  return (
    <Modal isOpen={isOpen} size="lg" className="modal-premium">
      <ModalBody>
        <div className="d-flex justify-content-end mb-4">
          <Button outline color="info" onClick={toggle}>
            <i className="fa fa-times mr-1" />
            {t("Tutup")}
          </Button>
        </div>
        <Row>
          {loading ? (
            <LoadingAnimation />
          ) : error ? (
            <DataNotFound />
          ) : (
            data?.map((item, idx) => (
              <Col key={idx} md="6">
                <Card
                  className={
                    item.name === "Premium" ? "card-premium" : "card-free"
                  }
                >
                  <CardBody>
                    <div className="w-100 mb-3" style={{ minHeight: "70px" }}>
                      <h5 className="font-weight-bold">
                        {t(item?.name ?? "")}
                      </h5>
                      <h6>{t(item?.description ?? "")}</h6>
                    </div>
                    <ListGroup>
                      {item?.name === "Premium" && (
                        <ListGroupItem
                          className="border-0"
                          style={{ paddingTop: "4px", paddingBottom: "4px" }}
                        >
                          <i
                            className={`fa fa-lg fa-check-circle mr-2 check-include`}
                          />
                          {t("Akses 2 Bulan")}
                        </ListGroupItem>
                      )}
                      {assesmentData.map((ases, index) => (
                        <ListGroupItem
                          key={index}
                          className="border-0"
                          style={{ paddingTop: "4px", paddingBottom: "4px" }}
                        >
                          <i
                            className={`fa fa-lg fa-check-circle mr-2 ${
                              listFree?.some((f) => f.name === ases)
                                ? `check-include`
                                : `check-non-include`
                            }`}
                          />
                          {t(ases)}
                        </ListGroupItem>
                      ))}
                      {item?.name !== "Premium" && (
                        <ListGroupItem
                          className="border-0 text-white"
                          style={{ paddingTop: "4px", paddingBottom: "4px" }}
                        >
                          &nbsp;
                        </ListGroupItem>
                      )}
                    </ListGroup>
                    <div
                      style={{ minHeight: "80px" }}
                      className="my-2 d-flex justify-content-center"
                    >
                      {item?.price > 0 && (
                        <h1 className="font-weight-bold">
                          {convertToRupiah(item?.price ?? 0)}
                        </h1>
                      )}
                    </div>
                    <div className="mb-1 w-100">
                      <Button
                        className="btn-card w-100 py-2 scale-div-small text-capitalize"
                        style={{ fontWeight: 600 }}
                        onClick={() => handleClick(item.name)}
                      >
                        {item?.name === "Premium"
                          ? t("Beli Sekarang")
                          : t("Lanjutkan akses gratis")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default translate(ModalPremium);

import React, { useState, useEffect, Fragment } from "react";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import { translate } from "react-switch-lang";
import requestGeneral from "../../../../utils/RequestGeneral";
import { useRouteMatch } from "react-router-dom";
import NotFound from "../../NotFound";
import { Redirect } from "react-router-dom";
const InternalAssessment = () => {
  const [loading, setLoading] = useState(true);
  const [exist, setExist] = useState(false);
  const matchRoute = useRouteMatch();
  // console.log("a");
  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + matchRoute?.params?.referalId)
      .then((res) => {
        localStorage.setItem("referal", matchRoute?.params?.referalId);
        setLoading(false);
        setExist(true);
      })
      .catch((err) => {
        setExist(false);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function onSuccess() {
    if (exist) {
      return <Redirect to={"/home"} />;
    }
  }
  return (
    <Fragment>
      {onSuccess()}
      {loading ? <LoadingAnimation /> : !exist ? <NotFound /> : null}
    </Fragment>
  );
};
export default translate(InternalAssessment);

// export default App;
import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./config/Services/Store";
import Router from "./config/Router";
import langUtils from "./utils/SettingLanguage/index";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fab, fas, far);

export default function App() {
  langUtils.init();
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}
